import { Button } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
export default function HeadingSec() {
    return (
        <HeadingWrap className="container" >

            <div className="row mb-5" >
                <div className="col-sm-5">

                <h3 className="mt-3">Does diabetes cause <br/> heart disease?</h3>
            {/* <h1> YES !</h1> */}

                </div>
                <div className="col-sm-7 ">

<div className="s p-sm-5 p-3">
                    <h5>The Twinepidemic</h5>
                  <h6>
                  The high glucose (sugar) levels in the blood of people with diabetes can eventually damage blood vessels as well as the nerves that control them.

                  </h6>

                  <Button  type="primary">
                    <Link style={{color:"white"}} to="/the-twinepidemic">Know More</Link>  </Button>
           </div>   
           
            </div>

            </div>

            
            
        </HeadingWrap>
    )
}


const HeadingWrap = styled.div`


@media(max-width:760px){


transform:translateY(-5rem);
h1{

    font-size:5rem !important;
}
h3{

font-size:1rem;


}

h5{
font-size:2rem;
    
}
}


h3{

    font-size:2rem;
    color:var(--brandColor);
    line-height:1.5;
    letter-spacing:0.5px;
    
}

h1{

        font-size:9rem;
        font-weight:900;
        color:var(--acentColor);


}
.s{

    border:1px solid var(--brandColor);
    h5{

        font-size:2.2rem;
        color:var(--acentColor);

    }
    h6{

font-size:1.2rem;
line-height:1.6;
color:var(--acentColor);
font-weight:400;

    }
}




`