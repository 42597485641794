import React from 'react'
import styled from 'styled-components'
export default function Heading({title, width}) {
    return (
        <HeadingWrap width={width}>
            
    <h2>{title}</h2>
    <hr/>

        </HeadingWrap>
    )
}


const HeadingWrap = styled.div`
h2{

    color:var(--acentColor);
    text-align:center;
}
hr{

    height:0.2rem;
    background-color:var(--acentColor2);
    width:${props=>props.width? props.width: "12%"};

}

`