import React from 'react'
import { Row, Col,  List  } from 'antd';


import Blog from './blog'
import { BsTag } from "react-icons/bs";



export default function Blogs({blogs}) {
 
    return (
        <div className="container mb-5">
  <Row >
    <Col span={20}  >

 <Blog blogs = {blogs}/> 

    </Col>
        <Col span={4} >
            {/* <div className="card pt-3 shadow ">
 <h5 className="mb-0 text-secondary px-3">Catagories</h5>
 <hr/>
 <List
    itemLayout="horizontal"
    dataSource={blogs}
    renderItem={item => (
      <List.Item className="px-3">
        <List.Item.Meta
          avatar={<BsTag/>}
          title={<a href="https://ant.design">{item.catagory}</a>}
         
        />
      </List.Item>
    )}
  />
      </div>       */}
        </Col>

   </Row>
        </div>
    )
}


