import React from 'react'
import InterWeave from 'interweave'
import moment from 'moment'
export default function Dailybrief({data}) {
    return (

        data.map((item, i)=>{


            return   <div key={i} style={{
           
            borderRadius:"0.7rem"}} className="p-sm-4 p-2 mb-3 container shadow">
            
            <small>by  {item.author} &nbsp; on &nbsp; {moment(item.createdAt).format('MMM Do YYYY')}</small>
            <h4 style={{color:"var(--acentColor)",  fontFamily:"var(--headingFont)"}}>{item.title}</h4>

          <p style={{fontFamily:"var(--textFont)"}}><InterWeave content={item.description}/></p>
</div>
        
        })
  
    )
    
}

