import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { message } from 'antd';
import keyUri from '../../uri'
export const initialState = {

    loading:false,
    hasErrors:false,
    dailyBriefings:[],
    currentDailyBreifing:null,
    total:0

}

export const dybSlice = createSlice({

    name:"daily_briefing",
    initialState,
    reducers:{

        getDYB:state =>{

            state.loading = true;

        },
        getDYBSuccess:(state, {payload}) =>{

            state.loading = false;
            state.dailyBriefings = payload.daily
            state.total = payload.total
            
        },

        getCurrentDYB:(state, {payload}) =>{

            state.loading = false;
            state.currentDailyBreifing = payload.daily
            
        },
        getUpdate : state =>{

            state.loading = false
        },

        
        getDYBError:state =>{

            state.loading = false;
            
        }
    }
})

export const {getDYB, getUpdate, getCurrentDYB, getDYBError, getDYBSuccess} = dybSlice.actions;

export const dycSelector = state => state.dyb

export default dybSlice.reducer


const config = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
  };


export const fetchAllDYB = (skip=0, limit=10, searchword='') => async dispatch =>{

    dispatch(getDYB())


try {

    const {data} = await axios.get(keyUri +`/api/daily-breifing?skip=${skip}&limit=${limit}&search=${searchword}`);

data && dispatch(getDYBSuccess(data))

} catch (error) {
    
    dispatch(getDYBError())


}


}

export const fetchCurrentDYB = (id) => async dispatch =>{

    dispatch(getDYB())
    
    try {
    
        const {data} = await axios.get( keyUri +`/api/daily-breifing/${id}`);

    dispatch(getCurrentDYB(data))
    
    } catch (error) {

    dispatch(getDYBError())

    }
    
    
    }


export const createDYB = (daily) => async dispatch =>{

    
    const key = 'create';
    dispatch(getDYB());

    message.loading({ content: 'loading...', key })

    try {
        
        const {data} = await axios.post(keyUri + '/api/daily-breifing', daily, config)

        setTimeout(() => {

            message.success({ content: data.message, key, duration: 2 });
          }, 500) 

          dispatch(fetchAllDYB())

    } catch ({response}) {

        dispatch(getDYBError());
        response.data && message.error({ content: response.data.message, key, duration: 2 });
    }


}

export const deleteDYB = (id) => async dispatch =>{

    const key = 'delete';

    dispatch(getDYB());
    message.loading({ content: 'Deleting...', key })
    try {
        
             const {data} = await axios.delete(keyUri + `/api/daily-breifing/${id}`)
        
             console.log(data);
             dispatch(fetchAllDYB())

        setTimeout(() => {

            message.success({ content: data.message, key, duration: 2 });
          }, 200)

         

    } catch (error) {

        dispatch(getDYBError());
    }


}


export const updateDYB = (id, blogupdate) => async dispatch =>{

    const key = 'update';

    dispatch(getDYB());
    message.loading({ content: 'updating...', key })
    try {
        
             const {data} = await axios.put(keyUri + `/api/daily-breifing/${id}`, blogupdate, config)
         dispatch(getUpdate()) 
           
        setTimeout(() => {

            message.success({ content: data.message, key, duration: 2 });
          }, 200)

         

    } catch (error) {

        dispatch(getDYBError());
    }


}

  