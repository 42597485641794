import React from 'react'
import { Table,Space } from 'antd';
import {deleteEvent} from '../../../Api/eventSlice'
import {useDispatch} from 'react-redux'
import moment from 'moment'
import DeleteConfirm from '../../../Shared/deleteConfirm'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import Fade from 'react-reveal/Fade'
import {Link} from 'react-router-dom'
import Interweave from 'interweave';
export default function Datatable({events}) {

        const dispatch = useDispatch()


        const confirm = (e, id) => {
          
            dispatch(deleteEvent(id._id, id.title))
           
          }
          
          const cancel = (e) =>{
            return null
          }



    const columns = [
        {
          title: 'title',
          key: 'title',
          render: event =>{
           ;  

            return event && <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">
                
                  {  event.events[0] && event.events[0].title}</h6>

          }
          
        },
        {
          title: 'Donated date',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render:(date)=>{

          return  moment(date).format("MMM Do YY")

          }
        },
        {
            title: 'Donor Name',
            dataIndex: 'donar_name',
            key: 'donar_name',
          },
          {
            title: 'Donor Email',
            dataIndex: 'donar_email',
            key: 'donar_email',
          },
          {
            title: 'Donor amount',
            dataIndex: 'donar_amt',
            key: 'donar_amt',
            render: (amt) =><h6>${amt}</h6>
          },

    
        // {
        //   title: 'Action',
        //   key: 'action',
        //   render: (id) => {


        //   return  <Space size="middle">
              
        //       <h5 className="text-secondary">
        //        <Link to={`/admin/event/update/${id._id}`}>
        //         <FaRegEdit/> 
        //          </Link> 
                
                
        //         </h5>
        //     <h5 className="text-danger">
        //         <DeleteConfirm confirm={(e)=>confirm(e, id)} title="blog" cancel={cancel} >
        //             <FaRegTrashAlt style={{cursor:"pointer"}} />
        //         </DeleteConfirm>
        //     </h5>

        //     </Space>
        // },
        // },
      ];


    return (
        <Fade>
            <Table pagination={false}  rowKey={record => record._id} columns={columns}  

    //         expandable={{
    //             expandedRowRender: record =><div className="px-5">
      
    //  {/* <Interweave content={record.description} /> */}
      
    //             </div>,
    //             rowExpandable: record => record.name !== 'Not Expandable',
    //           }}

              dataSource={events}
            expandRowByClick={false}
            />
        </Fade>
    )
}
