import React, {useEffect} from 'react'
import {fetchEvents, eventsSelector} from '../../Api/eventSlice'
import {useDispatch, useSelector} from 'react-redux'
import EventSec from './events'
import Loader from '../../Shared/spin'
import Header from '../../Shared/staticlayout/header'
import headerImage from '../../../images/bannerimages/3d-rendering-seminar-meeting-banquet-hall-room (1).jpg'

export default function Events() {

    const dispatch = useDispatch()
    const {events, loading} = useSelector(eventsSelector)


    useEffect(()=>{

      dispatch(fetchEvents()) 

    }, [dispatch])

    return (
        <div>
            <Header headerImg={headerImage} title="SPECIAL EVENTS"/>
           {loading ? <Loader/> : <EventSec events={events}/>} 
        </div>
    )
}
