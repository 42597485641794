import React from 'react'
import {Row, Col} from 'antd'
import styled from 'styled-components'
export default function Header({headerImg, title, imgpos="center"}) {
    return (
        <NewsWrap className="bg-dark container-fluid"
         style={{backgroundImage:`url('${headerImg}')`,
         backgroundPosition:imgpos
        
        }}
         
         >

            <div className="container">
<Row align="bottom">

    <Col span={4}>
{/* <div className="content text-light">
<h4 className="text-white ">Lets Put a Heading</h4>
<p>We are working to make managing libraries easier. Select a library to view its contents.We are working to make managing libraries easier. Select a library to view its contents</p>
<button className="btn btn-light">Read More</button>
</div> */}
       
    </Col>
    <Col span={20}>
        <h3 className="text-white text-right">{title}</h3>
    </Col>

</Row>

            </div>
            
        </NewsWrap>
    )
}


const NewsWrap = styled.div`

background-image:url('https://cdn.pixabay.com/photo/2016/08/16/09/53/international-conference-1597531_960_720.jpg');
background-size:cover;

transform:translateY(-5rem);
position: relative;

&::before{

width:100%;
height:100%;
content:'';
position:absolute;
top:50%;
left:50%;
transform:translate(-50%, -50%);
background-color:rgba(0,20,70,0.7);

}

.container{
height:40vh;
}

.ant-row{

    height:inherit;



        padding:5rem 0;

        h3{

            font-size:2.5rem;
            text-transform:uppercase;
            font-family:var(--headingFont);
            font-weight:900;
        }
        h4{

            font-size:1.8rem;
            font-family:var(--headingFont);
            font-weight:600;
        }
    
}

@media(max-width:960px){

    .ant-row{

height:inherit;



    padding:2rem 0;

    h3{

        font-size:1.3rem;
        text-transform:uppercase;
        font-family:var(--headingFont);
        font-weight:900;
    }
    h4{

        font-size:1.1rem;
        font-family:var(--headingFont);
        font-weight:600;
    }

}


}

`