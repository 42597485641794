import React, {useState } from 'react'
import DPaymentBtn from '../sap_danate/dpayment'
import { Modal, Button,InputNumber, Input} from 'antd';
import styled from 'styled-components'
export default function DonateNow() {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [amt, setAmt] = useState(2);
    const [mail, setUserMail] = useState(null);

    const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        setIsModalVisible(false);
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };

    return (
        <DonateNowWrap className="container-fluid " style={{backgroundColor:"var(--acentColor)"}}>

            <div className="container text-center py-5">
                <div>
                    <h1 className="text-light">YOUR HELP CAN SAVE LIVES</h1>
                    <h5 className="text-light">We still have work to do to protect the hearts you love. And your support is critical to <br/>
                    
                    funding innovative research that helps health care professionals identify, 
                   treat & prevent the Twinepidemic.
                    
                    </h5>
                 
              
                    <Button type="primary" onClick={showModal } className="px-4 my-3   d-block mx-auto " style={{fontSize:"1rem", height:"40px"}}>DONATE NOW</Button>
                </div>
            </div>

     <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        
     <Input
        placeholder="Enter Your email"
      autoFocus
      rules={[
        {
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
        {
          required: true,
          message: 'Please input your E-mail!',
        },
      ]}
      onChange={(e)=>setUserMail(e.target.value)}
      style={{display:"block",
      color:"green",
      fontSize:"1.5rem", width:"100%", margin:"1rem auto"}}
    />

    <InputNumber
      defaultValue={amt}
      min={2}
  
      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={value => value.replace(/\$\s?|(,*)/g, '')}
      onChange={(value)=>setAmt(value)}
      style={{display:"block",
      color:"green",
      
      fontSize:"1.5rem", width:"100%", margin:"1rem auto"}}
    />
        
    <DPaymentBtn amt={amt} email={mail} />



      </Modal>
            
        </DonateNowWrap>
    )
}


const DonateNowWrap = styled.div`

@media(max-width:780px){

  h1{

    font-size:1.3rem;
  }

  h5{

    font-size:0.9rem;
    line-height:1.6;
  }
}



`