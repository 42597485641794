import React from 'react'
import StaticLayout from '../../Shared/staticlayout'
import imge from '../../../images/introimages/diabetes-set-healthy-food-blue-wall (1) (1) (1).jpg'
import Diabetesimge from '../../../images/bannerimages/doctor-examining-senior-patient-blood-sugar.jpg'

import AcPanel from './accordian'
import Header from '../../Shared/staticlayout/header'
export default function Diabetes() {

  
let introData = {

    introtitle:"What is Twinepidemic ",
subheading:`Understanding the Connection Between Heart Disease and Diabetes `,
    text:`If you have diabetes, your risk of developing cardiovascular disease is more than double that of the general population, according to the American Heart Association.
For people with type 2 diabetes, heart disease is the most common cause of death. There are a number of things you can do to lower your risk of heart disease. Understanding the connection between diabetes and heart disease is the first step toward prevention.`,
    imge: imge

}




    return (
        <div className="mb-5">
      <Header title="THE TWINEPIDEMIC " headerImg={Diabetesimge}/>
           <StaticLayout
          
            data = {introData}
   
            >



<AcPanel/>
                </StaticLayout>

        </div>
    )
}
