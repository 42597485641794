import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Form, Input, Button } from 'antd';
// import Select from '../../../Shared/select'
import { updateJournal, fetchCurrentJournal, journalSelector} from '../../../Api/journalSlice'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import UploadImage from '../../../Shared/UploadImageCD'
import ReactQuill from 'react-quill';
import Loader from '../../../Shared/spin'
import Fade from 'react-reveal/Fade'
import credential from '../../../../key'



const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  
 

export default function CreateJournal() {


const { id } = useParams()
const {currentJournal, loading} = useSelector(journalSelector)
console.log(currentJournal);
  const [description, setDescription] = useState('');
  const [form] = Form.useForm();
    const dispatch = useDispatch();
    // const {blogs, loading} = useSelector(blogSelector)
    const [imge, setImge] = useState(null);
    const [imgerr, setImageErr] = useState();
  

useEffect(()=>{

dispatch(fetchCurrentJournal(id))


}, [dispatch])


useEffect(()=>{

  currentJournal && setDescription(currentJournal.description)
  currentJournal && setImge(currentJournal.image)
    //  currentBlog && setTitle(currentBlog.title)
    //  currentBlog && setCatagory(currentBlog.catagory)
     form.setFieldsValue({
        author_name: currentJournal &&  currentJournal.author_name ,
        title: currentJournal &&  currentJournal.title ,
      extra_link: currentJournal &&  currentJournal.extra_link,


    });


}, [currentJournal])

const onFinish = (values) => {

values.image = imge
values.description = description

dispatch(updateJournal(id, values))
console.log(id)

};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const handleClick = (e) => {

  e.preventDefault();

 window.cloudinary.openUploadWidget({ cloud_name:credential.cloud_name, upload_preset:credential.upload_preset},
  function(error, result) {


      if(result){

          setImge(result[0].url) 
          setImageErr(undefined);
      } else{

          setImge('')
          alert(error) 

      }
      
  });
}
     
    return (
      loading ? <Loader/> :
      <Fade>
      <CreateJournalWrap className="container ml-3">
            <h5 >Edit Journal</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
             <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

<Form.Item
        label="Author Name"
        name="author_name"
        rules={[{ required: true, message: 'Please input author name!' }]}
      >

        <Input />
      </Form.Item> 
      
      
      <Form.Item
        label="Title"
        name="title"
        rules={[{ required: true, message: 'Please input research title!' }]}
       >
      < Input />
      </Form.Item>

      <Form.Item
        label="Enter read More link"
        name="extra_link"
        >
<Input />

      </Form.Item>
 
      <Form.Item  wrapperCol={{ span: 20, offset: 4 }}>

<UploadImage click={(e)=>handleClick(e)} imge={imge} err={imgerr}/>

 </Form.Item>

<Form.Item wrapperCol={{ span: 20, offset: 4 }} >

     <ReactQuill theme="snow" value={description} onChange={setDescription}/>
     
</Form.Item>


<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
   
   

    </Form>
        </CreateJournalWrap>
        </Fade>
    )
}


const CreateJournalWrap = styled.div`

`