import React from 'react'
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import Apanel1 from './AcPanel'
import Apanel2 from './AcPanel2'

const { Panel } = Collapse;


export default function Accordian() {
    return (
        <div>
             <Collapse
    bordered={false}
    defaultActiveKey={['1']}
    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    className="site-collapse-custom-collapse"
  >
    <Panel header="What Are the Risk Factors for Heart Disease?" key="1" className="site-collapse-custom-panel">
    <Apanel1/>
    </Panel>

    <Panel header="What Can I Do to Lower my Risk of Heart Disease?" key="2" className="site-collapse-custom-panel">
    <Apanel2/>
    </Panel>



  </Collapse>
        </div>
    )
}
