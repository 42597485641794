import React from 'react'
import { Table ,Space} from 'antd';
import Fade from 'react-reveal/Fade'
import Interweave from 'interweave';
import DeleteConfirm from '../../../Shared/deleteConfirm'
import {deleteVolunteer} from '../../../Api/volunteerSlice'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';


export default function Datatable({data}) {

        const dispatch = useDispatch()
       

      const confirm = (e, id) => {
           dispatch(deleteVolunteer(id._id))
           
          }
          
       const cancel = (e) =>{
           return null
          }



    const columns = [
        {
          title: 'Firstname',
          dataIndex: 'firstname',
          key: 'firstname',
          render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
        },
        
        {
            title: 'Lastname',
            dataIndex: 'lastname',
            key: 'lastname',
            render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
          },
        
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
          },


          {
            title: 'Phone number',
            dataIndex: 'contact_number',
            key: 'contact_number',
            render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
          },

          {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
          },

          {
            title: 'country',
            dataIndex: 'country',
            key: 'country',
            render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
          },
        {
          title: 'Action',
          key: 'action',
          render: (id) => {


          return  <Space size="middle">
              
              {/* <h5 className="text-secondary">
              <Link to={`/admin/Volunteer/update/${id._id}`}>
                <FaRegEdit/>
                </Link>
                
                </h5> */}
            <h5 className="text-danger">
                <DeleteConfirm title="Volunteer" confirm={(e)=>confirm(e, id)} cancel={cancel} >
                    <FaRegTrashAlt style={{cursor:"pointer"}} />
                </DeleteConfirm>
            </h5>

            </Space>
        },
        },
      ];


    

    return (
        <Fade>
            <Table pagination={false}  rowKey={record => record._id} columns={columns}
            
            expandable={{
                expandedRowRender: record =><div className="px-5">
      
     <Interweave content={record.description} />
      
                </div>,
                rowExpandable: record => record.description !== 'Not Expandable',
              }}

            dataSource={data}
            expandRowByClick={false}
            
            />
        </Fade>
    )
}
