import React from 'react'
import styled from 'styled-components'
export default function Desc() {
    return (
        <DescWrap className="my-3 py-3" >
            <div className="row">
            <div className="col-sm-9">
               {/* <div className="d-flex align-items-center">
                   <h1 className="px-3">C</h1>
                   <p>PR – or Cardiopulmonary Resuscitation – is an emergency lifesaving procedure performed when the heart stops beating. Immediate CPR can double or triple chances of survival after cardiac arrest. </p>

               </div>
           <p> The American Heart Association invites you to share our vision: a world where no one dies from cardiac arrest. Every year, 475,000 people die from cardiac arrest in the United States. Big number. Bigger opportunity. With your help, we can bring that number down to zero. Join us today, starting with this video: Learn more about the AHA's vision of a world where no one dies of cardiac arrest.</p> */}

            </div>
            {/* <div className="col-sm-3 border-1 border-left">
                <p>Immediate CPR can double or triple chances of survival after cardiac arrest</p>
            </div> */}
            </div>
            <div className="">
           <h4>Why Is CPR Important ?</h4>
           <p>Keeping the blood flow active – even partially – extends the opportunity for a successful resuscitation once trained medical staff arrive on site</p>
       
           <h4>Chain of Survival</h4>
           <p>CPR is a critical step in the AHA’s Chain of Survival. The term Chain of Survival provides a useful metaphor for the elements of the ECC systems concept.</p>
           </div>
        </DescWrap>
    )
}


const DescWrap = styled.div`

h1{

    font-size:6rem;
    margin-bottom:8px;
    color:var(--acentColor2);
}

h4{

    color:var(--brandColor);
    
}

p{
 color:var(--acentColor);
 font-size:1rem;
 line-height:1.6;
 font-weight:400;
    
}
@media(max-width:960px)
{
    h4{
        font-size:1.3rem;
    }
}
`

