import React, {useEffect,useState} from 'react'
import DataTable from './datatable'
import {fetchAllDonor, eventsSelector} from '../../../Api/eventSlice'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../../Shared/spin'
import Fade from 'react-reveal/Fade'
import {Pagination} from 'antd'

export default function Blog() {
    const [curentpage, setCurrentPage] = useState(1)
    const dispatch = useDispatch()
    const {donars,total, loading} = useSelector(eventsSelector)

    useEffect(()=>{

       dispatch(fetchAllDonor())


    }, [dispatch])

    const  pagefunc = (skip, limit) =>{

        let p = (skip-1)*10
        dispatch(fetchAllDonor(p, limit))
      setCurrentPage(skip)
      
        }
    return (
        <div>
<div className="d-flex  mb-4 align-items-center justify-content-between">
    <h5>Events</h5>
    {/* <Link to="/admin/event/create">
      <Button type="ghost" icon={<PlusOutlined style={{transform:'translateY(-3px)'}}/>}>
            Create Event </Button></Link> */}
</div>
            
            {
                loading ? <Loader/> : <Fade><DataTable events={donars}/></Fade>
            }
                         <div style={{transform:"translateY(-5rem)"}} className=" pb-4 d-flex justify-content-end align-items-center">
{!loading && <Pagination onChange={pagefunc} defaultCurrent={curentpage} total={parseInt(total)} />}

</div>
       
        </div>
    )
}
