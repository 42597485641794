import React from 'react'
import {GiCaduceus} from 'react-icons/gi'
import { Carousel } from 'antd';
import styled from 'styled-components'
import author from '../../../images/author1.png'
export default function Tesimony() {

  

     let authordata = [{

        title:"About The Author",
        authimg:author,
        description:"Dr. Vijay is a Medical Sherpa who takes a holistic view of heart failure, using a variety of treatment options along with motivating, inspiring, and coaching to encourage patients to modify their lifestyles. His mission has been in the prevention of risk factors of heart disease and diabetes at primordial, primary, secondary, and tertiary care levels. Prevention of Heart Failure has been his main goal. In addition to spending countless hours caring for patients, he has dedicated himself to improving medical education, research, and the overall quality of care. His special brand of care is making a positive and measurable difference in keeping patients healthy, and importantly, out of the hospital. He volunteers his services to serve the underserved in attempts to mitigate diabetes, heart disease, and kidney disease around the world through his nonprofit organization, Twinepidemic, Inc"
      },
    
      {

        title:"About The Author",
        authimg:author,
        description:"Dr. Vijay is a Medical Sherpa who takes a holistic view of heart failure, using a variety of treatment options along with motivating, inspiring, and coaching to encourage patients to modify their lifestyles. His mission has been in the prevention of risk factors of heart disease and diabetes at primordial, primary, secondary, and tertiary care levels. Prevention of Heart Failure has been his main goal. In addition to spending countless hours caring for patients, he has dedicated himself to improving medical education, research, and the overall quality of care. His special brand of care is making a positive and measurable difference in keeping patients healthy, and importantly, out of the hospital. He volunteers his services to serve the underserved in attempts to mitigate diabetes, heart disease, and kidney disease around the world through his nonprofit organization, Twinepidemic, Inc"
      }]


    return (
        <AuthWrap className="py-sm-4 " style={{backgroundColor:"var(--brandColor)", transform:"translateY(-7rem)"}} className="container-fluid">
            
  <Carousel dots={false}>

      {

authordata.map((item, i)=>{

    return <div key={i}>

 



      <hr/>
<GiCaduceus/>

<img src={item.authimg} alt="author"/>

<div className="text-center container desc pb-5 pt-2 text-light m-auto">

<h5 className="text-light">{item.title}</h5>
<p >{item.description}</p>

</div>

   

    </div>

})
      }
  
 
  </Carousel>
        </AuthWrap>
    )
}


const AuthWrap = styled.div`

hr{

    width:10%;
    border:none;
    height:2px;
    background-color:white;

}

img{

    display:block;
    margin:1rem auto;
    width:212px;
    
}

svg{

    color:white;
    font-size:4rem;
    text-align:center;
    display:block;
    margin:auto;

}

.desc{

    width:75%;
    p{

    font-size:1rem;

}

}


@media(max-width:980px){


    .desc{

width:100%;
p{
 font-size:0.8rem;
}

}
  
}

`