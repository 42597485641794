import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { message } from 'antd';
import keyUri from '../../uri'


export const initialState = {

    loading:false,
    hasErrors:false,
    research:[],
    currentResearch:null,
    total:0


}

export const researchSlice = createSlice({

    name:"research",
    initialState,
    reducers:{

        getResearch:state =>{

            state.loading = true;

        },
        getResearchSuccess:(state, {payload}) =>{

            state.loading = false;
            state.research = payload.research
            state.total = payload.total
            
        },

        getCurrentResearch:(state, {payload}) =>{

            state.loading = false;
            state.currentResearch = payload
            
        },
        getUpdate : state =>{

            state.loading = false
        },

        
        getResearchError:state =>{

            state.loading = false;
            
        }
    }
})

export const {getResearch, getUpdate, getCurrentResearch, getResearchError, getResearchSuccess} = researchSlice.actions;

export const researchSelector = state => state.research

export default researchSlice.reducer


const config = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
  };


export const fetchResearch = (skip=0, limit=10, searchword='') => async dispatch =>{

    dispatch(getResearch())


try {

    const {data} = await axios.get(keyUri + `/api/research?skip=${skip}&limit=${limit}&search=${searchword}`);

    data && dispatch(getResearchSuccess(data))

} catch (error) {
    
    dispatch(getResearchError())


}

}

export const fetchCurrentResearch = (id) => async dispatch =>{

    dispatch(getResearch())
    
    try {
    
        const {data} = await axios.get(keyUri + `/api/research/${id}`);
        console.log(data)

    dispatch(getCurrentResearch(data))
    
    } catch (error) {

    dispatch(getResearchError())

    } 
    }


export const createResearch = (research) => async dispatch =>{


    const key = 'create';
    dispatch(getResearch());

    message.loading({ content: 'loading...', key })

    try {
        
        const {data} = await axios.post(keyUri + '/api/research', research, config)
         console.log(data);
       
            data && message.success({ content: data.msg, key, duration: 2 });
       

    } catch ({response}) {

        dispatch(getResearchError());
        response.data && message.error({ content: response.data.message, key, duration: 2 });
    }


}

export const deleteResearch = (id) => async dispatch =>{

    const key = 'delete';

    dispatch(getResearch());
    message.loading({ content: 'Deleting...', key })
    try {
        
             const {data} = await axios.delete( keyUri +`/api/research/${id}`)
        
             dispatch(fetchResearch())

        

         data && message.success({ content: data.msg, key, duration: 2 });
        

         

    } catch (error) {

        dispatch(getResearchError());
    }


}


export const updateResearch = (id, researchupdate) => async dispatch =>{

    const key = 'update';

    dispatch(getResearch());
    message.loading({ content: 'updating...', key })
    try {
        
             const {data} = await axios.put(keyUri + `/api/research/${id}`, researchupdate, config)
         dispatch(getUpdate()) 
           
        

        data &&  message.success({ content: data.msg, key, duration: 2 });
        

    } catch (error) {

        dispatch(getResearchError());
    }


}

  