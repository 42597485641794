import React from 'react'
import img1 from '../../../images/healthyReciepe/girl-granola-honey-blue-white-natural.jpg'
import img2 from '../../../images/healthyReciepe/ingredients-healthy-foods-selection-set-up.jpg'
import img3 from '../../../images/healthyReciepe/pieces-chicken-fillet-with-mushrooms-stewed-tomato-sauce-with-boiled-broccoli-rice-proper-nutrition-healthy-lifestyle-dietetic-menu-top-view.jpg'
import img4 from '../../../images/healthyReciepe/concept-healthy-food-sports-lifestyle-vegetarian-lunch-healthy-breakfast-proper-nutrition-top-view-flat-lay.jpg'
import img5 from '../../../images/healthyReciepe/dietary-menu-healthy-vegan-salad-vegetables-broccoli-mushrooms-spinach-quinoa-bowl-flat-lay-top-view.jpg'
import { Button, Row, Col } from 'antd'
import limit from '../../Shared/limitDes'

export default function HealthCard() {

    let HealthyData = [

        {
            title:`Doctor's Kidney Diets
            `,
            desc:`In the United States alone, 26 million adults have 
            chronic kidney disease (CKD), and experts project that
             over half the country may develop CKD due to rising rates of 
             disorders such as diabetes. While nephrologists
             can monitor kidney function and treat patients with medicationsthey can’t
              always offer the nutritional guidance that every kidney patient requires`,
            imge:img1,
            link:`https://www.amazon.com/Doctors-Kidney-Diets-Nutritional-Progression/dp/0757003737 `,

        },
        {
            title:`Delicious Heart-Healthy Recipes `,
            desc:`According to the Centers for Disease Control and Prevention,
             heart disease is the leading cause of death in the United States,
              which means that focusing on your
             heart health is essential to living a longer, healthier life`,
            imge:img2,
            link:`https://www.womansday.com/food-recipes/food-drinks/g2176/hearty-healthy-recipes/`,

        },
        {
            title:`Heart-Healthy Mediterranean Recipes`,
            desc:`Registered dietitian Wendy Jo Peterson prescribes meals 
            like these better-for-you Mediterranean recipes to her clients.
             To round out any Mediterranean meal,
             pair it with lean proteins, beans and extra vegetables.`,
            imge:img3,
            link:`https://www.tasteofhome.com/collection/heart-healthy-mediterranean-recipes/ `,

        },
        {
            title:`Diabetes meal plan recipes `,
            desc:`Prepare a hot fire in a charcoal grill
             or heat a gas grill or broiler. Away from the heat source,
              lightly coat the rack or broiler
             pan with cooking spray. Position the cooking
              rack 4 to 6 inches from the heat source.`,
            imge:img4,
            link:`https://www.mayoclinic.org/healthy-lifestyle/recipes/eggplant-with-toasted-spices/rcp-20049861 `,

        },
        {
            title:`American Diabetes Association Recipes`,
            desc:`There are many different kinds of diabetes recipes
             available to you such as gestational diabetes recipes that
              come in handy when someone is pregnant and needs to keep 
              her calorie intake up because she is eating for two as
             well as is battling diabetes.`,
            imge:img5,
            link:`https://diabeteshealthysolutions.com/american-diabetes-association-recipes/ `,

        }
    ]

    return (
        <div className="container mb-5">
            <Row gutter={35}>


                {


HealthyData.map((item, i)=>{

  return < Col className="shadow-sm m-3 p-0" xs={24} md={7} key={i}>
                    
<img src={item.imge} width="100%" alt="imge" />

<div className="content p-3">
<h6>{item.title}</h6>

<p> { limit(item.desc)}</p>

<Button type="primary">
    <a href={item.link} target="_tab">Read More</a></Button>

</div>
                    
                    </Col>

})
                    
                }
            </Row>
        </div>
    )
}
