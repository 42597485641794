import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../counter/counterSlice';
import eventReducer from './eventSlice';
import newsReducer from './newsSlice';
import blogReducer from './blogSlice';
import qtnSlice from './expertSlice';
import layoutSlice from './layoutSlice';
import authenticateSlice from './authSlice';
import userSlice from './userSlice';
import dybSlice from './dailybriefing';
import researchReducer from './researchSlice'
import journalReducer from './journalSlice'
import volunteerReducer from './volunteerSlice'
import userStoryReducer from './userStorySlice'

export default configureStore({
  reducer: {
    // counter: counterReducer,
    events:eventReducer,
    news:newsReducer,
    blogs:blogReducer,
    questions:qtnSlice,
    layoutState: layoutSlice,
    authentic_user: authenticateSlice,
     users: userSlice,
     dyb:dybSlice,
     research:researchReducer,
     journal:journalReducer,
     volunteer:volunteerReducer,
     userstory:userStoryReducer

  },
});
