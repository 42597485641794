import React, {useEffect} from 'react'
import { useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {fetchOneEvent, eventsSelector} from '../../Api/eventSlice'
import CurrentEvent from './currentEvent'
import { isPublicP } from '../../Api/layoutSlice'

export default function Donate() {
    let { id } = useParams();

    const dispacth = useDispatch()
    const currentEvent = useSelector(eventsSelector)

    useEffect(()=>{

    dispacth(fetchOneEvent(id))
    dispacth(isPublicP())

    }, [dispacth])



    return (
        <div>
         <CurrentEvent {...currentEvent}/>   
        </div>
    )
}
