import React from 'react'
import styled from 'styled-components'
import hederImg from '../../../images/good-mood-grey-haired-man-sitting-with-cup-hands-smiling (1).jpg'
export default function Header() {
    return (
        <HeaderWrap className="bg-danger" style={{backgroundImage:`url('${hederImg}')`}}>
            
            <div className="headingText p-sm-5 p-3 shadow">

<h2>We Serve <br/> the Underserved</h2>
<h6>The team at Twinepidemic aspires to create sustainable communities by preventing the epidemic of diabetes and heart disease. </h6>

            </div>


        </HeaderWrap>
    )
}

const HeaderWrap = styled.div`

background-size:cover;
width:100%;
height:90vh;
transform:translateY(-7rem);
position:relative;


.headingText{
width:28%;
position:absolute;
top:50%;
left:30%;
transform:translate(-50%, -50%);
background-color:white;
border-radius:1rem;
border-bottom:6px solid var(--brandColor);

h2{

    font-size:3rem;
    color:var(--brandColor);
}

h6{
    color:#000000;
    font-size:1.2rem;
    color:var(--acentColor);
    font-weight:500;
    line-height:1.6;
}
}


@media(max-width:960px){ 

    background-position:center;

.headingText{

    width:80%;
    position:absolute;
top:60%;
left:50%;

h2{

    font-size:1.2rem;
}
h6{
      font-size:1rem;
  
}
}

}

`