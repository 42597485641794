import React from 'react'
import Header from '../../Shared/staticlayout/header'
import imge from '../../../images/bannerimages/2633 (1) (1).jpg'
import Community from './communityCard'


export default function index() {
    return (
        <div>
         <Header headerImg={imge} title="COMMUNITY HEALTH SCREENING"/>  
      <Community/>
        </div>
    )
}
