import React from 'react'
import styled from 'styled-components'
export default function HvC() {
    return (
        <SHvCWrapper className="HvC container my-5">

            <h4 style={{color:"var(--brandColor)"}}>Heart Attack vs Cardiac Arrest</h4>
            
            <div className="row chvc gx-5">

            <div className="col-sm-6 p-sm-5 p-3 border border-5 border-left " style={{backgroundColor:"var(--brandColor)"}}>
<h4>What is Cardiac Arrest?</h4>
<p>Occurs when the heart malfunctions and stops beating unexpectedly.Cardiac arrest is an “ELECTRICAL” problem.</p>

            </div>
            <div className="col-sm-6 p-sm-5 p-3" style={{backgroundColor:"var(--acentColor)"}}>

            <h4>What is a Heart Attack?</h4>
<p>What is a Heart Attack? Occurs when blood flow to the heart is blocked.A heart attack is a “CIRCULATION” problem</p>
</div>

            </div>
            
        </SHvCWrapper>
    )
}


const SHvCWrapper  = styled.div`

.chvc{
text-align:center;
p{

    font-size:1.2rem;
}
h4{
    color:white;
}

    color:white;
}
@media(max-width:960px)
{
 
.chvc{
text-align:center;
p{

    font-size:1rem;
}
h4{
    color:white;
    font-size:1.3rem;
}

    color:white;
}
}

`