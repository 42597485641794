import React from 'react'
import Heading from '../../Shared/heading'
import { Carousel } from 'antd';
import { FaKey, FaApple, FaBuffer } from "react-icons/fa";
import styled from 'styled-components'
import goalImg from '../../../images/goal-01.png'
import projectImg from '../../../images/project-01.png'
import messionImg from '../../../images/mession.png'

export default function Learn() {

   
   const   slidedata = [{

    title:"Our Goal",
    icon:goalImg,
    desc:`Develop a strategy for Diabetes and CVD reduction starting with SA population around the world. Work as a forum through consultation, intervention, and communication.`
      },
      {

        title:"Our Projects",
        icon:projectImg,
        desc:`Mobilize support from stakeholders for prevention of the twin epidemic be an Advocacy group for nutritional change, community action & a common voice to media.`
          },
          {

            title:"Our Mission",
            icon:messionImg,
            desc:`Twinepidemic aspires to create sustainable communities and collaborate nonprofit organizations to procure equipment and supplies to create an enduring model of non-communicable disease prevention`
              }]

    return (
        <SliderWrap className="container " >
            <Heading title="Our Principles"  />

            <Carousel dots={false} autoplay style={{borderRadius:"4px"}} className="shadow p-5 my-5" >
   {
       slidedata.map((item, i)=>{

        return <div  key={i}>

            <div className="content text-center">
              <img src={item.icon} className="d-block mx-auto mb-2 " width="100px"/>
                <h4>{item.title}</h4>
                <p>{item.desc}</p>
            </div>
        </div>
       })
   }
  </Carousel>,
        </SliderWrap>
    )
}


const SliderWrap = styled.div`
h4{
    color:var(--acentColor2);
}

p{

    font-size:18px;
    color:var(--acentColor);
}

svg{

    color:lightblue;
    font-size:4.5rem;
}

.ant-carousel .slick-dots li{

background-color: var(--acentColor);


}
.ant-carousel .slick-dots li.slick-active button{

background:var(--brandColor) !important;


}


@media(max-width:960px){ 

    background-position:center;



}
`