import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { message } from 'antd';
import keyUri from '../../uri'

export const initialState = {

    loading:false,
    hasErrors:false,
    journal:[],
    currentJournal:null,
    total:0

}

export const journalSlice = createSlice({

    name:"journal",
    initialState,
    reducers:{

        getJournal:state =>{

            state.loading = true;

        },
        getJournalSuccess:(state, {payload}) =>{

            state.loading = false;
            state.journal = payload.journal
            state.total = payload.total
            
        },

        getCurrentJournal:(state, {payload}) =>{

            state.loading = false;
            state.currentJournal = payload
            
        },
        getUpdate : state =>{

            state.loading = false
        },

        
        getJournalError:state =>{

            state.loading = false;
            
        }
    }
})

export const {getJournal, getUpdate, getJournalSuccess, getCurrentJournal, getJournalError} = journalSlice.actions;

export const journalSelector = state => state.journal

export default journalSlice.reducer


const config = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
  };


export const fetchJournal = (skip=0, limit=10, searchword='') => async dispatch =>{

    dispatch(getJournal())

try {

    const {data} = await axios.get(keyUri + `/api/journal?skip=${skip}&limit=${limit}&search=${searchword}`);
 console.log(data)
 data && dispatch(getJournalSuccess(data))

} catch (error) {
    
    dispatch(getJournalError())
}

}


export const fetchCurrentJournal = (id) => async dispatch =>{
 console.log(id)
    dispatch(getJournal())
    
    try {
    
        const {data} = await axios.get(keyUri + `/api/journal/${id}`);
        console.log(data);

    dispatch(getCurrentJournal(data))
    
    } catch (error) {

    dispatch(getJournalError())

    }
    
    
    }



export const createJournal = (journal) => async dispatch =>{

    
    const key = 'create';
    dispatch(getJournal());

    message.loading({ content: 'loading...', key })

    try {
        
        const {data} = await axios.post(keyUri + '/api/journal', journal, config)
       console.log(data);
        

          data &&  message.success({ content: data.msg, key, duration: 2 });
      

    } catch ({response}) {

        dispatch(getJournalError());
        response.data && message.error({ content: response.data.message, key, duration: 2 });
    }

}

export const deleteJournal = (id) => async dispatch =>{

    const key = 'delete';

    dispatch(getJournal());
    message.loading({ content: 'Deleting...', key })
    try {
        
             const {data} = await axios.delete( keyUri +`/api/journal/${id}`)
        
             dispatch(fetchJournal())

           data && message.success({ content: data.msg, key, duration: 2 });
           

    } catch (error) {

        dispatch(getJournalError());
    }
}


export const updateJournal = (id, values) => async dispatch =>{
  console.log(id)
    const key = 'update';

    dispatch(getJournal());
    message.loading({ content: 'updating...', key })
    try {
        
             const {data} = await axios.put(keyUri + `/api/journal/${id}`, values, config)
         dispatch(getUpdate()) 
           
         data &&  message.success({ content: data.msg, key, duration: 2 });
           

    } catch (error) {

        dispatch(getJournal());
    }


}

  