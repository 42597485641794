import React, {useState} from 'react'
import { Collapse, Row, Col } from 'antd';
import styled from 'styled-components'
import { CaretRightOutlined } from '@ant-design/icons';
import TimeFormate from '../../Shared/timeformate';
import Share from '../../Shared/sharebtns';
import Fade from 'react-reveal/Fade'
import Interweave from 'interweave'
const { Panel } = Collapse;



export default function Expert({questions}) {


const [ckey, setCkey] = useState(["0"])
// let location = useLocation();

// console.log(location);

const qtnheeader = ( qtn, time, totalAnw, i) =>{

  return <div >

<h5>{qtn}&nbsp;?&nbsp;&nbsp;&nbsp;<small style={{fontSize:"0.8rem"}}>{TimeFormate(time)}</small></h5>
<div className="d-flex align-items-center ">
<button className="px-3 py-0 btn shadow-sm" style={{color:"#f5f5f5",borderRadius:"10px", backgroundColor:"var(--acentColor)"}}>
   {ckey.includes(i.toString())? `close answer` : `view Answer (${totalAnw})`}
</button>

<Share />
</div>
  </div>


}

function callback(key) {
  setCkey(key);
}


const isAnswered = questions && questions.filter((item, i)=>{

  return item.answers.length > 0

})


    return (
        <QtnWrap className="container ">
     <Fade>
  <Collapse
  onChange={callback}
    bordered={false}
    defaultActiveKey={['0']}
    expandIcon={({ isActive }) => <CaretRightOutlined  rotate={isActive ? 90 : 0} />}
    className="site-collapse-custom-collapse"
  >
      { questions &&
 isAnswered.map((qtn, i)=>{
return <Panel showArrow	={false}  header={qtnheeader(qtn.question, qtn.createdAt, qtn.answers.length, i) } key={i} className="site-collapse-custom-panel">
      
{

  (qtn.answers.length < 1) && <h6 className="text-secondary ml-5">Not yet Answer</h6>
  
}

      <div className="pl-5">

          {
            qtn.answers.map((ans, i)=>{
             
             
            return <div key={i} className="my-3">
              
              <Row>

<Col span={2} className="pa">
            <div className="ans">{i+1}</div>
</Col>


  <Col span={22}>

        <div className="bg-light p-2"><Interweave content={ans.answer}/></div>
        <div className="d-flex align-items-center justify-content-start">
            
            <small className="text-capitalize">answerd by <b style={{color:"var(--acentColor2)"}}>{ans.answerby}</b></small>&nbsp;&nbsp;&nbsp;&nbsp;
           <small>{TimeFormate(ans.createdAt)}</small>
        </div>
        <hr/>
  </Col>
</Row>
                
           
                </div>


            })  
          }
      </div>

</Panel>  

})

      }
    
      </Collapse>   
      </Fade>
        </QtnWrap>
    )
}


const QtnWrap = styled.div`
.ant-collapse-item{

  border-bottom: 1px dashed #c5c5c5a3 !important;
    padding-bottom: 1rem;


}
p{

    font-size:1rem;
    line-height:1.8;
}

hr{

    height:0.16rem;
    background-color:var(--acentColor);
    border:none;
    opacity:0.5;
}
.ans{

  
    background-color: #139e95;
    width: 42%;
    border-radius: 50%;
    font-weight: bold;
    color: white;
    margin: auto;
    padding: 0.4rem;
    text-align: center;


}

.qtn{

        background-color: var(--acentColor2);
        padding:1.1rem 0.9rem;
 text-align: center;
 
 border-radius: 50%;
    font-weight: bold;
    color: white !important;
}

.ant-collapse-content-box {
    margin-top: 1rem;
}
.ant-collapse-header{

    font-size:1.3rem;
    margin-left:1rem;
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  background: white;
  border-radius: 2px;
  margin-bottom: 24px;
  border: 0px;
  overflow: hidden;
}

.ant-collapse-borderless{
  background: white;


}


`