import React from 'react'
import styled from 'styled-components'
export default function Text() {
    return (
        <TextWrap className="container mt-5">
            <h2>Message to Volunteers
</h2>
<p>The active Board members, as well as the volunteers, have gained knowledge, passion, and compassion while participating in scholarly, need-based and meditative activities that have inspired them at a higher level. 
By personally engaging in Twin-epidemic activities, you will be touched in many ways that will rekindle your inner peace and happiness.</p>            
        </TextWrap>
    )
}


const TextWrap = styled.div`
text-align:center;
h2{

    color:var(--brandColor);


}

p{

    color:var(--acentColor);
    font-size:1rem;
}

`