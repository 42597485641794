import React, { useState} from 'react'
import styled from 'styled-components'
import { Form, Input,InputNumber, Button, DatePicker  } from 'antd';
// import Select from '../../../Shared/select'
import { createEventData} from '../../../Api/eventSlice'
import {useDispatch} from 'react-redux'
import moment from 'moment';
import UploadImage from '../../../Shared/UploadImageCD'
import ReactQuill from 'react-quill';
import credential from '../../../../key'


const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };


export default function CreateEvent() {

  const [description, setDescription] = useState('');
  const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [imge, setImge] = useState(null);
    const [imgerr, setImageErr] = useState();
     const [amt, setAmt] = useState(100);
   

const onChange =(value) => {
        setAmt(value)
      }

const onFinish = (fieldValue) => {


const values = {

    ...fieldValue,
    'event_date': fieldValue['event_date'].format('YYYY-MM-DD'),
}

values.image = imge
values.description = description
console.log(values);

form.setFieldsValue({
    image: imge &&  imge ,
   
  });

dispatch(createEventData(values))

setImge(null)
setDescription('')
form.resetFields();

};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const handleClick = (e) => {

  e.preventDefault();

 window.cloudinary.openUploadWidget({cloud_name:credential.cloud_name, upload_preset:credential.upload_preset},
  function(error, result) {


      if(result){

          setImge(result[0].url) 
          setImageErr(undefined);
      } else{

          setImge('')
          alert(error) 

      }    
  });
}

const config = {
    rules: [
      {
        type: 'object',
        required: true,
        message: ' Select date!',
      },
    ],
  };

  const disabledDate =(current)  =>{
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }
      
    return (
        <CreateBlogWrap className="container ml-3">
            <h5 >Create Event</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
             <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

    <Form.Item
        label="Enter Event Title"
        name="title"
        rules={[{ required: true, message: ' Event Title! ' }]}
      >

        <Input />
      </Form.Item> 
          
      <Form.Item
        label="Select Catagory"
        name="catagory"
        rules={[{ required: true, message: 'Enter category!' }]} >
             <Input />
     </Form.Item>

    <Form.Item
        label="Select Event Date"
        name="event_date"
        {...config} >
          <DatePicker   disabledDate={disabledDate}/>
     </Form.Item>

     <Form.Item 
     name="target_amt"
     label="Target Amount"
     initialValue={amt}
     rules={[{required: true, type: 'number', min: 1  }]}
 
     >
          <InputNumber 

               formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
               parser={value => value.replace(/\$\s?|(,*)/g, '')}
               onChange={onChange}
          />
 </Form.Item>


  <Form.Item
 
  label="Select Image"
  >

<UploadImage click={(e)=>handleClick(e)} imge={imge} err={imgerr}/>

 </Form.Item>

<Form.Item  wrapperCol={{ span: 20, offset: 4 }} >

     <ReactQuill theme="snow" value={description} onChange={setDescription}/>
     
</Form.Item>

<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
   
   

    </Form>
        </CreateBlogWrap>
    )
}

const CreateBlogWrap = styled.div`

`