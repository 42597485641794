import React from 'react'
import StaticLayout from '../../Shared/staticlayout'
import imge from '../../../images/women-are-back-pain-used-hand-support-waist (1).jpg'
import Kidneyimge from '../../../images/bannerimages/woman-having-pain-injured-back-healthcare-back-pain-concept (1).jpg'

import AcPanel from './accordian'
import Header from '../../Shared/staticlayout/header'
export default function Heart() {

  
let introData = {

    introtitle:"ARE YOU THE 33% ?",
    text:"33% of adults in the U.S. are at risk for kidney disease, but most of them don’t know it. And having kidney disease also puts you at an increased risk of developing life-threatening complications from COVID-19",
    imge: imge

}




    return (
        <div >
      
      <Header headerImg={Kidneyimge} title="KIDNEY DISEASE"/>
           <StaticLayout
            data = {introData}
   
            >



<AcPanel/>
                </StaticLayout>

        </div>
    )
}
