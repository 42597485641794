import React from 'react'
import Header from '../../Shared/staticlayout/header'
import imge from '../../../images/healthyReciepe/concept-healthy-food-sports-lifestyle-vegetarian-lunch-healthy-breakfast-proper-nutrition-top-view-flat-lay.jpg'
import HCard from './healthCard'
export default function Healthy() {
    return (
        <div>
            <Header headerImg={imge} title="HEALTHY RECIPE" />
            <HCard/>
        </div>
    )
}
