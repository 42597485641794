import React from 'react'
import styled from 'styled-components'
export default function Video() {
    return (
        <VideoSec className="container-fluid py-5 px-0 " style={{backgroundColor:"#dadada"}}>

<div className="container">
<iframe   className="d-block m-auto rounded shadow" style={{border:"none", }}
src="https://player.vimeo.com/video/462858312">
</iframe>
</div>
        
        </VideoSec>
    )
}


const VideoSec = styled.div`

iframe{

width:80%;
height:512px;
}

@media(max-width:960px){

 iframe{

width:100%;
height:252px;

}   
}

`