import React, {useEffect,useState} from 'react'
import {fetchVolunteer, volunteerSelector} from '../../../Api/volunteerSlice'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../../Shared/spin'
import Datatable from './datatable'
import {Pagination} from 'antd'
import {Link} from 'react-router-dom'
export default function Volunteer() {
  const [curentpage, setCurrentPage] = useState(1)
  
  const dispatch = useDispatch();

  const {volunteer, total,loading} = useSelector(volunteerSelector)

  
  useEffect(()=>{

    dispatch(fetchVolunteer())


 }, [dispatch])
 const  pagefunc = (skip, limit) =>{

  let p = (skip-1)*10
  dispatch(fetchVolunteer(p, limit))
setCurrentPage(skip)

  }

    return (

        <div>

<div className="d-flex  mb-4 align-items-center justify-content-between">
    <h5>Volunteer</h5>
    <Link to="/admin/volunteer/create">
      {/* <Button type="ghost" icon={<PlusOutlined style={{transform:'translateY(-3px)'}}/>}>
            Create volunteer</Button> */}
            </Link>
</div>

           {
            loading? <Loader/> : <Datatable data={volunteer}/>
          }
            <div style={{transform:"translateY(-5rem)"}} className=" pb-4 d-flex justify-content-end align-items-center">
{!loading && <Pagination onChange={pagefunc} defaultCurrent={curentpage} total={parseInt(total)} />}

</div>
       
        </div>
    )
}
