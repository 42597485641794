import React from 'react'
import styled from 'styled-components'
import {Row, Col} from 'antd'
import Loader from '../../Shared/spin'
import Fade from 'react-reveal/Fade'
import moment from 'moment'
import InterWeave from 'interweave'
import limitDesc from '../../Shared/limitDes'
export default function Articale({news, loading}) {



 return (
        <ArticleWrap className="container  py-3 mb-5">
           {

               loading? <Loader/> :

news.map((item, i)=>{

    console.log(item);
    return  <Fade duration="1000" distance="100px" bottom>

{

// !moment(Date.now()).isAfter(item.date) && 

    <Row key={i} style={{transform:"translateY(-3rem)"}} className="my-4 py-4 border-bottom border-dark">
                <Col className="px-3" xl={8} sm={24}>
           <img className="shadow" src={item.image} alt="imge" width="100%"/>
                </Col>
                <Col className="px-3 news" xl={13} sm={24}>
                    <h1>{item.title}</h1>


                  <InterWeave content={ limitDesc(item.description, 300)  }/>  



                   <div className="d-flex align-item-center justify-content-start v">
                      <p>Date:&nbsp;{ moment(item.date).format("MMM Do YY")} </p>

                       <p>Venue:&nbsp;{item.venue}</p>
                   </div>
                   <a href={item.extra_link} target="_tab" style={{backgroundColor:"var(--acentColor2)"}} className="btn text-white">Read More</a>


                </Col>
                <Col className="px-3 py-4" xl={3} sm={24}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                <h2 className="text-center mb-0">{moment(item.date).format("D")}</h2>
                <h3 className="text-center">{moment(item.date).format("MMMM")}</h3>
             </div>
                </Col>
            
            </Row>
}
            </Fade>
})

           } 
           


        </ArticleWrap>
    )
}

const ArticleWrap = styled.div`



.news{

    h1 {

     font-size: 1.2rem;  
     color:var(--acentColor);
     line-height:1.4; 
    }
    h2, h3, h4, h5{

font-size: 0.9rem;
color:var(--textColor);
}
border-right:2px solid var(--acentColor2);
    span > p {

font-size:14px;
text-align:justify;
font-weight:400 !important;
color:grey;

strong {

font-weight:400 !important;

    
}
}
}
.v{

    p{

        color:var(--brandColor);

        font-size:1.1rem;
        margin:0.5rem;
        text-transform:capitalize;
    }
}
h2{
    font-size:7rem;
    color:var(--brandColor);
}

h5{

    color:var(--acentColor);
    
}
img{

    border-radius:10px;

}

p{

   color:var(--textColor);

}
@media(max-width:960px)
{
    h5{
        padding:1.5rem;
    }
}

`
