import React from 'react'
import imge from '../../../images/cpr/CPR 072204 body.jpg'
import styled from 'styled-components'

export default function Intro() {
    return (
        <IntroWrap className="row">

            <div className="col-sm-8">

<h3 style={{color:"var(--brandColor)"}}>What is CPR?
</h3>

{/* <h6>Science based Guildelines

</h6> */}

<p className="">
    
<div className="d-flex align-items-center">
                   {/* <h1 className="px-3">C</h1> */}
                   <p>CPR – or Cardiopulmonary Resuscitation – is an emergency lifesaving procedure performed when the heart stops beating. Immediate CPR can double or triple chances of survival after cardiac arrest. </p>

               </div>

        <div className="mb-3 ">
           <h3 style={{color:"var(--brandColor)"}}>Why Is CPR Important ?</h3>
           <p>Keeping the blood flow active – even partially – extends the opportunity for a successful resuscitation once trained medical staff arrive on site</p>
       
           <h3 style={{color:"var(--brandColor)"}}>Chain of Survival</h3>
           <p>CPR is a critical step in the AHA’s Chain of Survival. The term Chain of Survival provides a useful metaphor for the elements of the ECC systems concept.</p>
           </div>


            {/* <p> The American Heart Association invites you to share our vision: a world where no one dies from cardiac arrest. Every year, 475,000 people die from cardiac arrest in the United States. Big number. Bigger opportunity. With your help, we can bring that number down to zero. Join us today, starting with this video: Learn more about the AHA's vision of a world where no one dies of cardiac arrest.</p> */}

   </p>
    

            </div>
            <div className="col-sm-4">
<img src={imge} alt="sdgfsdf" width="100%"/>

            </div>

            
        </IntroWrap>
    )
}

const IntroWrap = styled.div`

p{
 color:var(--acentColor);
 font-size:1rem;
 line-height:1.6;
 font-weight:400;
    
}
@media(max-width:960px)
{
    h3{
        font-size:1.3rem;
    }
}
`