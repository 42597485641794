import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { message } from 'antd';
import keyUri from '../../uri'

export const initialState = {

    loading:false,
    hasError:false,
    news:[],
    currentNews:null,
    total:0
}

export const newsSlice = createSlice({

    name:"news",
    initialState,
    reducers:{

        getNews: state =>{

            state.loading = true;   
        },
         getNewsSuccessfull:( state, {payload}) =>{

            state.loading = false;   
            state.news = payload.news
            state.total = payload.total


        },           

            getUpdate: state =>{

             state.loading = false
        },

            getCurrentNews:(state, {payload}) =>{

               state.loading = false;
              state.currentNews = payload
              console.log(payload);
    
        },

        getNewsError: state =>{

            state.loading = false;   
        }

    }
})

export const {getNews, getUpdate, getCurrentNews, getNewsSuccessfull, getNewsError } = newsSlice.actions;
export const newsSelector = state => state.news
export default newsSlice.reducer;

const config = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
  };


export const fetchNews = (skip=0, limit=10, searchword='') =>async dispatch =>{

    dispatch(getNews());

    try {
      
        const { data } = await axios.get(keyUri + `/api/news?skip=${skip}&limit=${limit}&search=${searchword}`)

        data && dispatch(getNewsSuccessfull(data))
        

    } catch (error) {
        
      dispatch(getNewsError());

    }
}


export const fetchCurrentNews = (id) => async dispatch =>{

    dispatch(getNews())
    
    try {
    
        const {data} = await axios.get(keyUri + `/api/news/${id}`);
console.log(data);
  data &&  dispatch(getCurrentNews(data))
    
    } catch (error) {

    dispatch(getNewsError())

    }
    
    
    }



export const createNews = (newsdata) =>async dispatch =>{
    const key = 'create';

    dispatch(getNews());
    message.loading({ content: 'loading...', key })

    try {

        const { data } = await axios.post(keyUri + '/api/news', newsdata, config)
       console.log(data);
       data && message.success({ content: data.msg, key, duration: 2 });
        
    } catch ({response}) {

        dispatch(getNewsError());
        response.data && message.error({ content: response.data.message, key, duration: 2 });

    }

}



export const updateNews = (id, newsupdate) => async dispatch =>{

    const key = 'update';

    dispatch(getNews());
    message.loading({ content: 'updating...', key })
    try {
        
             const {data} = await axios.put(keyUri + `/api/news/${id}`, newsupdate, config)
         dispatch(getUpdate()) 
           
        setTimeout(() => {

            message.success({ content: data.success, key, duration: 2 });
          }, 200)

         

    } catch (error) {

        dispatch(getNewsError());
    }


}



export const deleteNews = (id) => async dispatch =>{

    const key = 'delete';

    dispatch(getNews());
    message.loading({ content: 'Deleting...', key })
    try {
        
             const {data} = await axios.delete(keyUri + `/api/news/${id}`)
        
             dispatch(fetchNews())

        setTimeout(() => {

            message.success({ content: data.msg, key, duration: 2 });
          }, 200)

         

    } catch (error) {

        dispatch(getNewsError());
    }


}