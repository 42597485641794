import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import logo from '../../../images/newlogo.jpg'
import { Button } from 'antd';
import AboutMenu from './about'
import MessionMenu from './messionP'
import ProfEDUMenu from './profEdu'
import PatientEduMenu from './pedu'
import Support from './support'
import {useMedia} from 'react-use'
import {Affix} from 'antd'
import {LoginOutlined} from '@ant-design/icons'
import MobNav from '../mobnavbar'



export default function Navbar() {

    const [top, setTop] = useState(0);

 

    const isWide = useMedia('(max-width: 480px)');


    return (

        <Affix offsetTop={top} >

        {isWide ? <MobNav/> :  <NavbarWrap className=" navbar navbar-expand-lg py-0  bg-white">


<Link className="navbar-brand" to="/">
    <img src={logo} alt="logo" className="logo"/>
</Link>

<div className="collapse navbar-collapse" id="navbarSupportedContent">

<ul className="navbar-nav mr-auto">


<li className="nav-item dropdown">
<Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
About Us
</Link>
<AboutMenu/>
</li>

<li className="nav-item dropdown">
<Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
Mission Programs
</Link>
<MessionMenu/>
</li>

<li className="nav-item dropdown">
<Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
Professional Edu
</Link>
<ProfEDUMenu/>
</li>

<li className="nav-item dropdown">
<Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
Patient Edu & Support
</Link>
<PatientEduMenu/>
</li>
<li className="nav-item dropdown">
<Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
Support the Cause
  </Link>
<Support/>
</li>




</ul>


<Button href="/login" type="primary"  icon={<LoginOutlined style={{transform:"translateY(-2px)", backgroundColor:"var(--brandColor)"}}/>} >
Login 
</Button>

</div>


</NavbarWrap> }
        

</Affix>
    )
}

const NavbarWrap = styled.nav`

/* position:fixed;
left:50%;
top:3.5%;
transform:translate(-50%, -50%); */
.dropdown .nav-item:hover {


    background-color:var(--brandColor);

    a:hover{

       color:white !important; 
    }
    
}

@media(max-width:1800px){

width:92%;

}

@media(max-width:1500px){

width:95%;

}

width:80%;
margin:auto;
border-radius:8px;
box-shadow: 1px 1px 10px 0px #bfbfbf;
z-index:22;


.nav-link {

    color: var(--navTextColor);
    font-size:1rem;
            text-transform:capitalize;
            text-decoration:none;
            margin:auto 0.5rem;

         
}
.navlinks{

    align-items:center;
    justify-content:space-around;

    li{
        list-style:none;
        
        

    }
}
.dropdown:hover  .dropdown-menu{

display:block;


}
.dropdown-menu{



    /* margin-top: 1.5rem; */
    /* width:750px; */
    padding:1rem auto;
box-shadow: 1px 1px 10px 0px #bfbfbf;
border:none;
z-index:-1 !important;
border-radius:0.3rem;

.nlink{

    position:absolute;
    bottom:10%;
    left:30%;
    text-decoration:underline;
}

&:hover{

    display:block;
}



ul{

padding-left:0;
margin-top:1rem;
li{

    list-style:none;

    .nav-link{

        color:var(--textColor);
        border-radius:5px;

        &:hover{
 color:white;
/* background-color:var(--brandColor); */

}
    }
}
}

img{
width:90%;
border-radius:10px;


}
}
  .logo{

        width:170px;
    }
@media(max-width:960px){

    width:100%;
border-radius:0;
    .logo{

        width:120px;
    }


}

`

