import React from 'react'
import { Table,  Space } from 'antd';
import { deleteDYB} from '../../../Api/dailybriefing'
import {useDispatch} from 'react-redux'
import DeleteConfirm from '../../../Shared/deleteConfirm'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import Fade from 'react-reveal/Fade'
import {Link} from 'react-router-dom'
import Interweave from 'interweave';
export default function Datatable({blogs}) {

        const dispatch = useDispatch()


        const confirm = (e, id) => {
            dispatch(deleteDYB(id._id))
           
          }
          
          const cancel = (e) =>{
            return null
          }



    const columns = [
        {
          title: 'title',
          dataIndex: 'title',
          key: 'title',
          render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
        },
        {
          title: 'author',
          dataIndex: 'author',
          key: 'author',
        },
        {
          title: 'Action',
          key: 'action',
          render: (id) => {

          

          return  <Space size="middle">
              
              <h5 className="text-secondary">
               <Link to={`/admin/daily-breifing/update/${id._id}`}>
                <FaRegEdit/> 
                 </Link> 
                
                
                </h5>
            <h5 className="text-danger">
                <DeleteConfirm confirm={(e)=>confirm(e, id)} title="blog" cancel={cancel} >
                    <FaRegTrashAlt style={{cursor:"pointer"}} />
                </DeleteConfirm>
            </h5>

            </Space>
        },
        },
      ];
 

    return (
        <Fade>
       
            <Table pagination={false}  rowKey={record => record._id} columns={columns}   expandable={{
              expandedRowRender: record =><div style={{width:'90%',margin:'auto'}} >
    
   <Interweave content={record.description} />
    
              </div>,
              rowExpandable: record => record.description !== 'Not Expandable',
            }}

          dataSource={blogs}
          expandRowByClick={false}
                  />
        </Fade>
    )
}
