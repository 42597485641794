import React, {useEffect} from 'react'
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import {userStorySelector, fetchAllStory} from '../../Api/userStorySlice'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Shared/spin'
import Interweave from 'interweave'
import time from '../../Shared/timeformate'
import Heading from '../../Shared/heading'
const { Panel } = Collapse;
export default function Stories() {


    const dispatch = useDispatch();
    const {userStory, loading } = useSelector(userStorySelector);

     useEffect(()=>{

          dispatch(fetchAllStory())

     }, [dispatch])


    return (
        <div className="container my-3 ">
<Heading title="Your Stories"/>

            <Collapse
    bordered={false}
    defaultActiveKey={['0']}
    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    className="bg-light site-collapse-custom-collapse"
  >

      {

          loading? <Loader/> : userStory.map((item, i)=>{

           return  <Panel key={i} header={<h5 style={{color:"var(--brandColor)"}}>{item.title}</h5>}  className="site-collapse-custom-panel">
      <p className="px-4 py-2"
      style={{lineHeight:"1.8", color:"var(--acentColor)", fontSize:"16px"}}
      ><Interweave content={item.description}/></p>

      <div className="px-4 py-2">

<p className="text-sm-right text-center">by {item.email} &nbsp; {time(item.createdAt)}</p>

      </div>
    </Panel>


          }) 
      }
   
  
  </Collapse>  
        </div>
    )
}
