import React from 'react'

export default function AchievMent() {
    let data = [
        'Free Health Screening for SA community on August 10th, 2019 at Indo American Community Center, 2809 W Maryland Ave, Phoenix, AZ 85017.',
        `Hands only CPR and AED training to non-medical lay community in a 5 City project in Phoenix, AZ, Gilbert, AZ, Glendale, AZ, Columbus, Ohio and Tulsa, Oklahoma.

        The Himalayan Cardiorenal Screening Project in Leh, Ladakh, Kashmir, India`,

        `Twinepidemic Collaboration with Project C.U.R.E. for a CURE Clinic in GHANA, Africa`
    ]
    return (
        <div className="container my-5">
            <h4 className="text-center" style={{color:"var(--brandColor)"}}>OUR ACHIEVEMENTS</h4>
            {
                data.map((item, i)=>{

                    return <div key={i} className="p-4 shadow-sm my-4 rounded">

                   <p className="text-center" style={{color:"var(--acentColor)", fontSize:"1.1rem"}}>{item}</p>

                    </div>
                })
            }
        </div>
    )
}
