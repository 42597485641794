import React from 'react'
import { Table,Space } from 'antd';
import DateFrmate from '../../../Shared/timeformate'
// import moment from 'moment'
import DeleteConfirm from '../../../Shared/deleteConfirm'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import {deleteStory} from '../../../Api/userStorySlice'
import Fade from 'react-reveal/Fade'
import Interweave from 'interweave';
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'

export default function Datatable({data}) {

  const dispatch = useDispatch()
        console.log(data);


        const confirm = (e, id) => {
            dispatch(deleteStory(id._id))
           
          }
          
          const cancel = (e) =>{
            return null
          }

    const columns = [

      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
      },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
        },
        
        
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
          },

          {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render:(riseddate) =>{
  
              return <small ><b className="text-success">Raised on</b>&nbsp;  {DateFrmate(riseddate)}</small> 
        
            }
          },

        {
          title: 'Action',
          key: 'action',
          render: (id) => {

          return  <Space size="middle">
              
              <h5 className="text-secondary">
              <Link to={`/admin/share-your-story/update/${id._id}`}>
                <FaRegEdit/>
                </Link>
                
                </h5>
            <h5 className="text-danger">
                <DeleteConfirm title="share-your-story" confirm={(e)=>confirm(e, id)} cancel={cancel} >
                    <FaRegTrashAlt style={{cursor:"pointer"}} />
                </DeleteConfirm>
            </h5>
            </Space>
        },
        },
      ];


    return (
        <Fade>
            <Table pagination={false} rowKey={record => record._id} columns={columns}
            
            expandable={{
                expandedRowRender: record =><div style={{width:'90%',margin:'auto'}} >
      
     <Interweave content={record.description} />
      
                </div>,
                rowExpandable: record => record.description !== 'Not Expandable',
              }}

            dataSource={data}
            expandRowByClick={false}
            
            />
        </Fade>
    )
}
