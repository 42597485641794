import React from 'react'
import Header from '../../Shared/staticlayout/header'
import headerImage from '../../../images/bannerimages/eco-concept-with-group-volunteers (1).jpg'
import DonateSlide from '../sap_danate/dimg'
import Form from './form'
import Text from './text'
export default function Volunteer() {
    return (
        <div >
            <Header headerImg={headerImage} title="VOLUNTEER"/>
            <Text/>
            <Form/>
            <DonateSlide/>
        </div>
    )
}
