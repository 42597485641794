import React from 'react'
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom'
export default function Support() {
    return (
        <Fade bottom duration={300} distance={"30px"}>
        <div style={{width:"250px"}} className="dropdown-menu" aria-labelledby="navbarDropdown">
    
    <div className="row" style={{position:"relative"}}>
        <div className="col">
        <ul className="text-dark text-capitalize">
        <li className="nav-item ">
        <Link className="nav-link" to="/donate">Donate </Link>
      </li>
      <li className="nav-item ">
        <Link className="nav-link" to="/special-events">Special Events</Link>
      </li>
     <li className="nav-item ">
        <Link className="nav-link" to="/volunteer">Volunteer </Link>
      </li>
      
      <li className="nav-item ">
        <Link className="nav-link" to="/pre-registration">Pre Registartion </Link>
      </li>

      <li className="nav-item ">
        <Link className="nav-link" to="/share-your-story">Share Your Story</Link>
      </li>
      {/* <li className="nav-item ">
        <Link className="nav-link" to="/patient-advisory-council">Patient Advisory Council </Link>
      </li> */}
    
      {/* <li className="nav-item ">
        <Link className="nav-link" to="/scientific-advisory-board">Scientific Advisory Board </Link>
      </li> */}
            </ul>
        </div>
       
    </div>
    
    
        </div>
        </Fade>
    )
}


