import {createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import { message } from 'antd';
import keyUri from '../../uri'


const user =  localStorage.getItem('user') ?
   JSON.parse(localStorage.getItem('user')) : null


export const initialState = {

    loading:false,
    hasErrors:false,
   isAuthenticate: user ? user.token? true : false : false ,
   user:user


}

export const authenticateSlice = createSlice({


    name:"auth",
    initialState,
    reducers:{

      getlogin:state =>{

        state.loading = true;
      },

      getAuthenticate: (state, {payload}) =>{

        state.loading = false;
        state.isAuthenticate = true;
        state.user = payload
   
        localStorage.setItem('user', JSON.stringify(state.user) )
        

      },

    
    

      isAuthenticateError: state =>{

        state.hasErrors = true;
     

      }



    }


})


export const {getlogin, getAuthenticate, isAuthenticateError }  = authenticateSlice.actions

export const authenticateSelector = state => state.authentic_user
export default authenticateSlice.reducer


const config = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
  };
export const logOut = () => async dispatch =>{
    // const key = 'logOut';


    try {
        
       
        localStorage.removeItem('user');
        window.location.reload();
 

        

    } catch (error) {

        dispatch(isAuthenticateError())

    }

}



export const isAuthenticateUser = (logindata) => async dispatch =>{
    const key = 'login';
    // dispatch(getlogin())
    // message.loading({ content: 'loading...', key })

    try {
        
        const {data} = await axios.post(keyUri + '/api/login', logindata, config)
       
       
        dispatch(getAuthenticate(data))

    

        // setTimeout(() => {

        //     message.success({ content: data.msg, key, duration: 2 });

        //   }, 500) 


    } catch (error) {


setTimeout(() => {

    message.error({ content: error.response.data.msg, key, duration: 2 });
  }, 500) 

        dispatch(isAuthenticateError())
    }

}
