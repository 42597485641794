import React, { useState} from 'react'
import styled from 'styled-components'
import { Form, Input, Button  } from 'antd';
// import Select from '../../../Shared/select'
import { createResearch} from '../../../Api/researchSlice'
import {useDispatch} from 'react-redux'

import UploadImage from '../../../Shared/UploadImageCD'
import ReactQuill from 'react-quill';
import credential from '../../../../key'


const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
 
export default function Research() {

  const [description, setDescription] = useState('');
  const [form] = Form.useForm();
    const dispatch = useDispatch();
    // const {blogs, loading} = useSelector(blogSelector)
    const [imge, setImge] = useState(null);
    const [imgerr, setImageErr] = useState();
    // const [name, setName] = useState('')
    // const [catagory, setCatagory] = useState([])



const onFinish = (values) => {

    values.image = imge
    values.description = description
  
dispatch(createResearch(values))

setImge(null)
setDescription('')
form.resetFields();

};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const handleClick = (e) => {

  e.preventDefault();

 window.cloudinary.openUploadWidget({cloud_name:credential.cloud_name, upload_preset:credential.upload_preset},
  function(error, result) {


      if(result){

          setImge(result[0].url) 
          setImageErr(undefined);
      } else{

          setImge('')
          alert(error) 

      }
      
  });
}


      
    return (
        <CreateResearchWrap className="container ml-3">
            <h5 >Create Research</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
             <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

    <Form.Item
        label="Author Name"
        name="author_name"
        rules={[{ required: true, message: 'Enter Name' }]}
      >

        <Input />
      </Form.Item> 
      
      
      <Form.Item
        label="Title"
        name="research_title"
        rules={[{ required: true, message: 'Enter Title!' }]}
       >
      < Input />
      </Form.Item>

    <Form.Item
        label="Enter read More link"
        name="extra_link"
        >
<Input />

      </Form.Item>


      
  <Form.Item  wrapperCol={{ span: 20, offset: 4 }}>

<UploadImage click={(e)=>handleClick(e)} imge={imge} err={imgerr}/>

 </Form.Item>

<Form.Item wrapperCol={{ span: 20, offset: 4 }} >

     <ReactQuill theme="snow" value={description} onChange={setDescription}/>
     
</Form.Item>


<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
   

    </Form>
        </CreateResearchWrap>
    )
}


const CreateResearchWrap = styled.div`

`