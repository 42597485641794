import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Form, Input, Button  } from 'antd';
// import Select from '../../../Shared/select'
import { updateDYB, fetchCurrentDYB, dycSelector} from '../../../Api/dailybriefing'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import ReactQuill from 'react-quill';
import Loader from '../../../Shared/spin'
import Fade from 'react-reveal/Fade'



const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  

export default function CreateBlog() {


const { id } = useParams()
const {currentDailyBreifing, loading} = useSelector(dycSelector)
  const [description, setDescription] = useState('');
  const [form] = Form.useForm();
    const dispatch = useDispatch();
  




useEffect(()=>{

dispatch(fetchCurrentDYB(id))


}, [dispatch])


useEffect(()=>{

    currentDailyBreifing && setDescription(currentDailyBreifing.description)
     
    form.setFieldsValue({
      title: currentDailyBreifing &&  currentDailyBreifing.title ,
      author: currentDailyBreifing &&  currentDailyBreifing.author 
    });


}, [currentDailyBreifing])



const onFinish = (values) => {


values.description = description

dispatch(updateDYB(id, values))



};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};




      
    return (
      loading ? <Loader/> :
      <Fade>
      <CreateBlogWrap className="container ml-3">
            <h5 >Edit DailyBrefing</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
             <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

    <Form.Item
        label="Enter Daily Briefing Title"
        name="title"
        rules={[{ required: true, message: ' isRequired!' }]}
      >

        <Input />
      </Form.Item> 
      
      <Form.Item
        label="author"
        name="author"
        rules={[{ required: true, message: ' isRequired!' }]}
      >

        <Input />
      </Form.Item> 
     

<Form.Item wrapperCol={{ span: 20, offset: 4 }} >

     <ReactQuill theme="snow" value={description} onChange={setDescription}/>
     
</Form.Item>


<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
   
   

    </Form>
        </CreateBlogWrap>
        </Fade>
    )
}


const CreateBlogWrap = styled.div`




`