import React, { useState} from 'react'
import styled from 'styled-components'
import { Form, Input, Button } from 'antd';
// import Select from '../../../Shared/select'
import { createDYB } from '../../../Api/dailybriefing'
import {useDispatch} from 'react-redux'

import ReactQuill from 'react-quill';



const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };


export default function CreateDYB() {

  const [description, setDescription] = useState('');
  const [form] = Form.useForm();
    const dispatch = useDispatch();

 
const onFinish = (values) => {

  values.description = description
dispatch(createDYB(values))

setDescription('')
form.resetFields();

};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

  
    return (
        <CreateBlogWrap className="container ml-3">
            <h5 >Create DailyBreifing</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
             <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >


      
<Form.Item
        label="Enter Daily Briefing Title"
        name="title"
        rules={[{ required: true, message: 'required!' }]}
      >

        <Input />
      </Form.Item> 

      <Form.Item
        label="author"
        name="author"
        rules={[{ required: true, message: 'required!' }]}
      >

        <Input />
      </Form.Item> 

<Form.Item wrapperCol={{ span: 20, offset: 4 }} >

     <ReactQuill theme="snow" value={description} onChange={setDescription}/>
     
</Form.Item>

<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
   
   

    </Form>
        </CreateBlogWrap>
    )
}


const CreateBlogWrap = styled.div`




`