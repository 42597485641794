import React, {useEffect} from 'react'
import styled from 'styled-components'
import { Form, Input, Button  } from 'antd';
// import Select from '../../../Shared/select'
import { updateUser, fetchCurrentUser, userSelector} from '../../../Api/userSlice'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import Loader from '../../../Shared/spin'
import Fade from 'react-reveal/Fade'


const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
 

export default function CreatebBlog() {


const { id } = useParams()
const {currentAdmin, loading} = useSelector(userSelector)

const [form] = Form.useForm();
    const dispatch = useDispatch();

  




useEffect(()=>{

dispatch(fetchCurrentUser(id))


}, [dispatch])


useEffect(()=>{


     form.setFieldsValue({
      name: currentAdmin &&  currentAdmin.name ,
       email: currentAdmin &&  currentAdmin.email,
   

    });


}, [currentAdmin])



const onFinish = (values) => {



dispatch(updateUser(id, values))



};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
  
};



      
    return (
      loading ? <Loader/> :
      <Fade>
      <CreateBlogWrap className="container ml-3">
            <h5 >Update User</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
             <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >




<Form.Item
        label="Enter user name"
        name="name"
        rules={[{ required: true, message: 'Enter username' }]}
      >

        <Input />
      </Form.Item> 
      
      
      <Form.Item
        label="Enter Email"
        name="email"
      
        rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Enter E-mail!',
            },
          ]}
     
     >

<Input  />



      </Form.Item>



      <Form.Item
        name="password"
        label="Enter New Password"
        rules={[
          {
            required: true,
            message: 'Enter password!',
          },
          {
            min: 6,
            message: 'password must be minmum 6 characters!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject('The two passwords that you entered do not match!');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>


<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
   
   

    </Form>
        </CreateBlogWrap>
        </Fade>
    )
}


const CreateBlogWrap = styled.div`




`