import React from 'react'
import { Form, Input, Button, Select } from 'antd';
import styled from 'styled-components'
import {VolunteerSubmit, volunteerSelector, isSuccessOK} from '../../Api/volunteerSlice'
import {useDispatch, useSelector} from 'react-redux'
import logo from '../../../images/logo icon-06.png'
import { Modal } from 'antd';
const { Option } = Select;


const layout = {
    labelCol: {
      span: 0,
    },
    wrapperCol: {
      span: 24,
    },
  };


export default function Registartion() {

const dispatch = useDispatch()
    const {isSuccess, loading} = useSelector(volunteerSelector)

    const [form] = Form.useForm();

     
    return (
        <ValunteerWrap className="container mb-5" >
    
    {/* <p className='loading'>Loading....</p> */}

       <div className='form'>
        <iframe height="1200" title="Embedded Wufoo Form" allowtransparency="true" frameborder="0" scrolling="no" style={{width:"100%" , border:"none"}} src="https://twinepidemic.wufoo.com/embed/za86eo21kwssv4/"><a href="https://twinepidemic.wufoo.com/forms/za86eo21kwssv4/">Fill out my Wufoo form!</a> </iframe>

      </div>
      
        </ValunteerWrap>
    )
}


const ValunteerWrap = styled.div`




.ant-input {

    font-size: 20px;
    box-shadow: 2px 2px 3px 1px #dedede;
    border: 0.3px solid #fbfbfb;
    border-radius: 0 0 10px 10px;
    padding:0.5rem 1rem;
}

.ant-input-group-addon{

   font-size: 20px;
    box-shadow: 2px 2px 3px 1px #dedede;
    border: 0.3px solid #fbfbfb;
    border-radius: 10px 0 0 10px;
    background-color:white;

}

.cstbtn{

    padding: 0.5rem 3rem;
    border: none;
    height: 100%;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    float:right;
    box-shadow: 1px 1px 5px 0px #5a5858;
}

.ant-select-single .ant-select-selector .ant-select-selection-item{

    line-height: 40px;
    color: grey;
    font-weight: 500;

}


@media(max-width:760px) {


    .ant-input {

font-size: 14px;

}

.ant-input-group-addon{

font-size: 14px;


}

h1{

    font-size:1.0rem;
}

}
`