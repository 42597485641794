import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {fetchAllBlogs,  blogSelector} from '../../Api/blogSlice'
import { isPublicP } from '../../Api/layoutSlice'
import { Pagination } from 'antd';
import Loader from '../../Shared/spin'


import Header from '../../Shared/staticlayout/header'
import Blog from './blogs'


export default function Blogs() {

    const dispatch = useDispatch()
    const {blogs, total, loading} = useSelector(blogSelector)
    const [curentpage, setCurrentPage] = useState(1)
    useEffect(()=>{

        dispatch(fetchAllBlogs())
        dispatch(isPublicP())

    }, [dispatch])

    const  pagefunc = (skip, limit) =>{

        let p = (skip-1)*10
        dispatch(fetchAllBlogs(p, limit))
    setCurrentPage(skip)
    
        }


        console.log(blogs);

    return (
        <div>
            <Header title="blogs" 
            headerImg="https://cdn.pixabay.com/photo/2016/08/16/09/53/international-conference-1597531_960_720.jpg"/>
            {loading? <Loader/> : <Blog blogs={blogs} />}
         
            <div className="container pb-4 d-flex justify-content-end align-items-center">

            {!loading && <Pagination onChange={pagefunc} defaultCurrent={curentpage} total={parseInt(total)} />}
            </div>
        </div>
    )
}
