import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { message } from 'antd';
import keyUri from '../../uri'

export const initialState = {
    loading: false,
    hasErrors: false,
    events: [],
    currentEvent:null,
    donars:[],
    total:0

  }

export const eventSlice = createSlice({

    name:"events",
    initialState,
    reducers:{
        getEvents: state => {
          state.loading = true
        },
        getEventsSuccess: (state, { payload }) => {
         
          state.events = payload.event
          state.loading = false
          state.hasErrors = false
          state.total = payload.total
          state.donars=payload.donar
       
        },
        createEvent: state =>{

          state.loading = false
          state.hasErrors = false
        },
        getOneEvent : (state, {payload}) =>{


          state.loading = false
          state.currentEvent = payload
                    
                  },

            getUpdate : state =>{

                    state.loading = false
                },

                getDonateSuccess: (state, {payload}) =>{

                   state.loading = false
                   state.donars = payload
                },
        getEventsFailure: state => {
          state.loading = false
          state.hasErrors = true
        },

      
      },

})

export const { getEvents, getUpdate, getDonateSuccess, createEvent, getOneEvent, getEventsSuccess, getEventsFailure } = eventSlice.actions
export const eventsSelector = state => state.events

export default eventSlice.reducer


const config = {
  headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
  }
};




export const fetchEvents = (skip=0, limit=10, searchword='') => async dispatch =>{
    dispatch(getEvents())

      try {
        
        const {data} = await axios.get(keyUri +`/api/events?skip=${skip}&limit=${limit}&search=${searchword}`)
   

    dispatch(getEventsSuccess(data))

      } catch (error) {
        dispatch(getEventsFailure())
      }

  }


  export const donate = (amtinfo) => async dispatch =>{
    dispatch(getEvents())
  
      try {
        
        const {data} = await axios.post(keyUri +'/api/paypal-transaction', amtinfo, config)
  
    dispatch(getDonateSuccess(data))
  window.location.reload()
  
      } catch (error) {
        dispatch(getEventsFailure())
      }
  
  }


  export const fetchOneEvent = (id) => async dispatch =>{
    dispatch(getEvents())

      try {
        
        const {data} = await axios.get(keyUri +`/api/events/${id}`)

    dispatch(getOneEvent(data))

      } catch (error) {
        dispatch(getEventsFailure())
      }

  }


export const createEventData = (eventdata) => async dispatch =>{
  
  const key = 'create';

  dispatch(getEvents())

  message.loading({ content: 'loading...', key })


      try {
        
        const {data} = await axios.post(keyUri +`/api/event`, eventdata, config)
    
       data && message.success({ content: data.message, key, duration: 2 });

      } catch ({response}) {
        dispatch(getEventsFailure())
        response.data && message.error({ content: response.data.message, key, duration: 2 });
      }
  }




  export const deleteEvent = (id, title) => async dispatch =>{

    const key = 'delete';

    dispatch(getEvents());
    message.loading({ content: `Deleting...`, key })
    try {
        
             const {data} = await axios.delete(keyUri +`/api/event/${id}`)
        
             dispatch(fetchEvents())

        setTimeout(() => {

            message.success({ content:`${title} - ${data.success}`, key, duration: 2 });
          }, 500)

         

    } catch (error) {

        dispatch(getEventsFailure());
    }


}



export const updateEvent = (id, eventupdate) => async dispatch =>{

  const key = 'update';

  dispatch(getEvents());
  message.loading({ content: 'updating...', key })
  try {

       const {data} = await axios.put(keyUri +`/api/event/${id}`, eventupdate, config)
      console.log(data);
       dispatch(getUpdate()) 
         
      setTimeout(() => {

          message.success({ content: data.success, key, duration: 2 });
        }, 200)

       

  } catch (error) {

      dispatch(getEventsFailure());
  }

}



export const fetchAllDonor = () => async dispatch =>{
  dispatch(getEvents())

    try {
      
      const {data} = await axios.get(keyUri +`/api/donate`)
 console.log(data);

  dispatch(getDonateSuccess(data.donar))

    } catch (error) {
      dispatch(getEventsFailure())
    }

}






  
