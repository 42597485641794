import React, {useState} from 'react'
import {  Slider, Button, Affix, InputNumber, Input, Row, Col } from 'antd';
import { FaShareSquare} from "react-icons/fa";
import styled from 'styled-components'
import PaymentBtn from './paymentSec'
import ModalSec from '../../Shared/paymentModal'
import TimeFormate from '../../Shared/timeformate'
import donarIcon from '../../../images/DefaultAvatar.65712475de0674c9f775ae3b64e7c69f.svg'
export default function DonateSec({target_amt, raised_amt, _id, donars}) {
   
    const [bottom] = useState("20px");

    const [amt, setAmt] = useState(2);
    const [mail, setUserMail] = useState(null);

// const onChange = (value) =>{

//     setAmt(value)
// }

    console.log(mail);
    return (
         <Affix  offsetBottom={bottom}>
        <DonateWrap className=" p-3 mt-3">

    <h2>${raised_amt}&nbsp;<small className="targetamt">raised </small> </h2>

    <Slider tooltipVisible={false} value={raised_amt} />

<div className="d-flex  align-item-center justify-content-start">

<div className="px-3">
    <p className="text-dark font-weight-bold px-1 mb-0 border-bottom border-dark">{donars.length}</p>
    <p className="">donars</p>
</div>
<div className="px-3">

    <p className="text-dark font-weight-bold px-1 border-bottom border-dark mb-0">2k</p>
    <p className="">shares</p>

</div>

</div>


<div className="buttonSec">

    <Button block className="btns" icon={<FaShareSquare />} >Share</Button>
<br/>
    {/* <Button block className="btns" icon={<FaDonate />} >Donate Now</Button> */}
    <ModalSec mtitle="Donate Now">

    <Input
        placeholder="Enter Your email"
      autoFocus
      rules={[
        {
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
        {
          required: true,
          message: 'Please input your E-mail!',
        },
      ]}
      onChange={(e)=>setUserMail(e.target.value)}
      style={{display:"block",
      color:"green",
      fontSize:"1.5rem", width:"100%", margin:"1rem auto"}}
    />

    <InputNumber
      defaultValue={amt}
      min={2}
  
      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={value => value.replace(/\$\s?|(,*)/g, '')}
      onChange={(value)=>setAmt(value)}
      style={{display:"block",
      color:"green",
      
      fontSize:"1.5rem", width:"100%", margin:"1rem auto"}}
    />
        
    <PaymentBtn amt={amt} email={mail} id={_id}/>
    </ModalSec>


</div>


<div className="donars">
{

donars.map((item, i)=>{

return <Row className="bg-light my-2 py-2 border-bottom " key={i}>
    <Col span={7} > <img src={donarIcon} alt="donarIcon" className=" d-block m-auto"/></Col>
    <Col span={15}>
       <Row>
        <Col span={24}><h6>Anonymous</h6></Col>
<Col span={24}> <h6 className="d-inline"><b>${item.donar_amt}</b></h6> - <small style={{fontSize:"12px"}}>{TimeFormate(item.createdAt)}</small></Col>

       </Row>

    </Col>

</Row>


})  
}



</div>


     
  
        </DonateWrap>
        </Affix>
    )
}


const DonateWrap = styled.div`
box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.2);
.targetamt{

    font-size:0.8rem;
    color:var(--textColor);
}

.btns{
    background-image: linear-gradient(to right, #ff758c 0%, #ff7eb3 100%);
        color: white;
    margin:0.5rem 0;
    svg{

        margin:0 0.5rem 0 0;
        text-align:left;

        
    }
}

`