import React, { useState }  from 'react'
import { Modal,Form, Input, Button } from 'antd';
import {createQtn, fetchAllQtn, qtnSelector} from '../../Api/expertSlice'
import {useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import logo from '../../../images/logo icon-06.png'

const { TextArea } = Input;


const layout = {
 
    wrapperCol: {
      span: 24,
    },
  };
  // const tailLayout = {
  //   wrapperCol: {
  //     offset: 2,
  //     span: 22,
  //   },
  // };


export default function AskQtn() {

    const [visible, setVisible] = useState(false)
     const [submitVisible, setSubmitVisible] = useState(false)


    const dispatch = useDispatch()
 const {loading} = useSelector(qtnSelector)

   const showModal = () => {
     
    setVisible(true)
      };


        const onFinish = (values) => {

            dispatch(createQtn(values))
             dispatch(fetchAllQtn())

             setVisible(false)
             loading &&   setSubmitVisible(true)


        };
      
        const onFinishFailed = (errorInfo) => {
          console.log('Failed:', errorInfo);
        };



    
     const handleOk = () => {
                setVisible(false)

      };

     const handleCancel = () => {
        setVisible(false)

      };


      

    return (
      <AskWrap>
        <>
        <h3 style={{color:"var(--acentColor)"}}>Ask our expert opinion about your case, research or generic information

</h3>
        <button className="btn w-sm-25 text-white d-block mr-auto" style={{backgroundColor:"var(--acentColor2)"}} onClick={showModal}>
          Ask Question
        </button>
        <Modal
          width={800} footer={false}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          closeIcon={<div style={{display:"none"}} />}
         
       >
           <Form
      {...layout}
      name="Ask Question"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

<Form.Item
   
        name="Question"
        rules={[
          {
            required: true,
            message: 'required!',
          },
        ]}
      >
        <TextArea
        style={{color:"var(--acentColor)", fontSize:"1.3rem"}}
         placeholder="Ask Your Question"
          rows={4}/>
      </Form.Item>

<Form.Item>
        <Button type="primary" htmlType="submit">
          {loading ? "loading..." : "Submit"}
        </Button>
      </Form.Item>
</Form>
        </Modal>


        <Modal
         className="p-4"  width={800} footer={false}
           title="Submit Question"
           visible={submitVisible}
           onCancel={()=>setSubmitVisible(false)}
        >

<img src={logo} width="120px" className="d-block mx-auto"/>
       <h2 className="text-center" style={{color:"var(--brandColor)"}}>Thanks for asking  your Question !</h2>
      <p className="text-center text-secondary" style={{fontSize:"20px"}}>We would like to use your experience to share knowledge to people, we promise to not give away any of your personal information.</p>
 <hr   style={{height:"5px", width:"20%", backgroundColor:"var(--acentColor)" }}/>
    

        </Modal>
      </>
      </AskWrap>
    )
}

const AskWrap = styled.div`
@media(max-width:960px)
{
  h3{
        font-size:1.3rem;
    }
}

`