import React from 'react'
import Header from './header'
import Author from './author'
import Review from './review'
import VideoSec from './video'
import BookDesc from './bookDesc'
export default function OurBooks() {
    return (
        <div>
            
<Header/>
<BookDesc/>
<Author/>

<Review/>
<VideoSec/>
        </div>
    )
}
