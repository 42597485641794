import React from 'react'
import two from '../../../images/cpr/2020_CPR_and_ECC_Guidelines_graphic.jpg'
import bus from '../../../images/cpr/business woman using AED on man on office floor.jpg'
import styled from 'styled-components'
export default function TheScience() {
    return (
        <TheScienceWrap className="my-5">
            <h4>TheScience</h4>
            <p>The AHA is the leader in resuscitation science, education, and training,
                 and publisher of the official Guidelines for CPR and ECC.</p>
            

      <div className="row">

<div className="col-sm-6">
    <img src={two} width="100%" alt="kjkj"/>
    <h6> Guidelines for CPR & ECC: Read the Guidelines and the Guidelines Highlights, available in 17 languages</h6>
</div>
<div className="col-sm-6">

<img src={bus} width="100%" alt="kjkj"/>
<h6> Guidelines for CPR & ECC: Read the Guidelines and the Guidelines Highlights, available in 17 languages</h6>

</div>


      </div>

        </TheScienceWrap>
    )
}


const TheScienceWrap = styled.div`
img{

    height:400px;
 border-radius:15px;
}



h4{

color:var(--brandColor);
}

p{
color:var(--acentColor);
font-size:1rem;
line-height:1.6;
font-weight:400;

}

h6{

margin-top:1rem;
    line-height:1.7;
    text-decoration:underline;
    color:var(--acentColor);

}
@media(max-width:960px)
{
    h4{
        font:1.3rem;
    }
    img{

     height:250px;
    }
}

`