import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Form, Input, Button } from 'antd';
// import Select from '../../../Shared/select'
import { updateBlog, fetchCurrentBlog, blogSelector} from '../../../Api/blogSlice'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import UploadImage from '../../../Shared/UploadImageCD'
import ReactQuill from 'react-quill';
import Loader from '../../../Shared/spin'
import Fade from 'react-reveal/Fade'
import credential from '../../../../key'


const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  

export default function CreatebBlog() {


const { id } = useParams()
const {currentBlog, loading} = useSelector(blogSelector)
  const [description, setDescription] = useState('');
  const [form] = Form.useForm();
    const dispatch = useDispatch();
    // const {blogs, loading} = useSelector(blogSelector)
    const [imge, setImge] = useState(null);
    const [imgerr, setImageErr] = useState();
  

useEffect(()=>{

dispatch(fetchCurrentBlog(id))


}, [dispatch])


useEffect(()=>{

    currentBlog && setDescription(currentBlog.description)
     currentBlog && setImge(currentBlog.image)
    //  currentBlog && setTitle(currentBlog.title)
    //  currentBlog && setCatagory(currentBlog.catagory)
     form.setFieldsValue({
      title: currentBlog &&  currentBlog.title ,
      catagory: currentBlog &&  currentBlog.catagory,
      author:currentBlog &&  currentBlog.author,
    });


}, [currentBlog])



const onFinish = (values) => {

values.image = imge
values.description = description

dispatch(updateBlog(id, values))

};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const handleClick = (e) => {

  e.preventDefault();

 window.cloudinary.openUploadWidget({ cloud_name:credential.cloud_name, upload_preset:credential.upload_preset},
  function(error, result) {


      if(result){

          setImge(result[0].url) 
          setImageErr(undefined);
      } else{

          setImge('')
          alert(error) 

      }
      
  });
}
   
    return (
      loading ? <Loader/> :
      <Fade>
      <CreateBlogWrap className="container ml-3">
            <h5 >Create Blog</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
             <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

    <Form.Item
        label="Enter Blog Title"
        name="title"
        rules={[{ required: true, message: 'Enter Blog Title ' }]}
      >

        <Input />
      </Form.Item> 
      
      
      <Form.Item
        label="Select Catagory"
        name="catagory"
        rules={[{ required: true, message: 'Enter catagory' }]}>

       <Input />

      </Form.Item>


      <Form.Item
        label="author"
        name="author"
        rules={[{ required: true, message: 'required!' }]}
      >

        <Input />
      </Form.Item> 
  
      <Form.Item  wrapperCol={{ span: 20, offset: 4 }}>

<UploadImage click={(e)=>handleClick(e)} imge={imge} err={imgerr}/>

 </Form.Item>

<Form.Item wrapperCol={{ span: 20, offset: 4 }} >

     <ReactQuill theme="snow" value={description} onChange={setDescription}/>
     
</Form.Item>


<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
   
    </Form>
        </CreateBlogWrap>
        </Fade>
    )
}


const CreateBlogWrap = styled.div`

`