
import React from 'react'
import { Row, Col, Slider, Card  } from 'antd';
import Interweave from 'interweave'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import  limitDesc from '../../Shared/limitDes'
import time from '../../Shared/timeformate'

const { Meta } = Card;
export default function EventSec({events}) {
    return (
        <EventsWrap className="container my-4">
       <Row>
    
     {
events.map((item, i)=>{
    let isrecentDonate = item.raised_amt >0 ?  time(item.updatedAt) : null

    return <Col xs={24} sm={24} md={12} lg={8}   className="p-sm-3 py-2  " key={i}>
        <Link to={`/events/${item._id}`} >
    <Card hoverable className="" cover={<img alt="example" height="220rem" src={item.image} />}
    >
    <Meta title={item.title} description={<Interweave content = {limitDesc(item.description)}/>} />
  
    {isrecentDonate? <small className="text-secondary">Last donation &nbsp; {isrecentDonate} </small> :null }
    <Slider max={item.target_amt} value={item.raised_amt}  tooltipVisible />
    <h6 classname="text-secondary m-0 " style={{opacity:0.7}}><b>${item.raised_amt} Raised</b> of ${item.target_amt}</h6>
        </Card>
        </Link>
     </Col>
})

    }
    
   
    </Row>
        </EventsWrap>
    )
}


const EventsWrap = styled.div`

.ant-slider-step{

    background: #7bdea340;
    height:0.3rem;
}
.ant-slider-track{
 background: #309657;
 height:0.3rem;


 &:hover{
      background-color: #309657 !important;

 }
    
}
.ant-slider-handle{

display:none;

}


.ant-card-meta-description {


span > p {

    font-size:14px;
    text-align:justify;
    font-weight:400 !important;
    color:grey;

    strong {

    font-weight:400 !important;

        
    }
}
}

`