import React from 'react'
import {Row, Col} from 'antd'
import styled from 'styled-components'
import { Image } from 'antd';
import Loader from '../../Shared/spin'
import Interweave from 'interweave'
import AuthorImg from '../../../images/DefaultAvatar.65712475de0674c9f775ae3b64e7c69f.svg'
import { BsTag } from "react-icons/bs";
import Donate from './donateSec'

export default function CurrentEvent({currentEvent, loading}) {



return (
    
        <CurrentEventWrap className="container my-5">
            
 {currentEvent && <h2>{currentEvent.title}</h2>}

 {!currentEvent && <Loader/>}
{
  currentEvent && <Row>

   <Col span={16} className="pr-4">

  <div className="content my-3">


<Image
   width="100%"
   src={currentEvent.image}
 /><br/>
<div className="author py-3">

<img src={AuthorImg} alt="dfdf" width="50px"/>

<small className="ml-4">{currentEvent.author}</small>
<hr/>
<small className=" pr-3 border-right border-secondary">Event Date &nbsp; :&nbsp; {currentEvent.event_date}</small> 
<small className="pl-3"><BsTag className="mx-2"/>{currentEvent.author}</small>
<hr/>
</div>
<div className="desc">

    <Interweave content={currentEvent.description}/>   

</div>

  </div>

   </Col>
   <Col span={8} className="pl-2">

       <Donate {...currentEvent}/>
   </Col>

</Row>
}
   
            
        </CurrentEventWrap>
    )
}

const  CurrentEventWrap = styled.div`
small{

    font-size:1rem;
}

.desc{

    color:var(--textColor);
    font-size:1rem;
    line-height:1.9;
    word-spacing:3px;
}

.ant-slider-step{

background: #7bdea340;
height:0.3rem;
}
.ant-slider-track{
background: #309657;
height:0.3rem;


&:hover{
  background-color: #309657 !important;

}

}
.ant-slider-handle{

display:none;

}

`