import React from 'react'
import limitDesc from '../../Shared/limitDes'
import InterWeave from 'interweave'
import { Button } from 'antd';
import styled from 'styled-components'
export default function LatestConf({data}) {

    return (
         <LatestConfigWrap className="container mb-5">
{            
data && <div className="row shadow-sm rounded" style={{backgroundColor:"#fff4f4"}}>
    <div className="col-sm-5 p-0" >

<img src={data.image} alt="imge" style={{objectFit:"cover", width:"100%"}} height="100%"/>

    </div>
    <div className="col-sm-7 p-4">
        <div>
<h2 style={{color:"var(--acentColor)"}}>LATEST EVENTS</h2>
<h6 style={{color:"var(--brandColor)"}}>{data.title}</h6>
<InterWeave content={ limitDesc(data.description, 400)  }/>  

<Button type="primary" target="_tab" href={data.extra_link}>Register Now</Button>
</div>

    </div>

</div>}

        </LatestConfigWrap>
    )
}

const LatestConfigWrap = styled.div`

p{

    font-size:1.1rem;
    color:var(--acentColor);

    font-weight:500;
}
`
// backgroundImage:`url('${data.image}')`,