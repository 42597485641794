import React from 'react'
import { Row, Col,  Button  } from 'antd';
import Interweave from 'interweave'
import styled from 'styled-components'
import limitDesc from '../../Shared/limitDes'
import moment from 'moment';
import { Link } from 'react-router-dom'

export default function Blog({blogs}) {

    return (
        <BlogWrap>
              {

blogs.map((blog, i)=>{

return <Row key={i} className="mb-5">
<Col xl={10} sm={24} className="pr-5">
<Link to={`/blogs/${blog._id}`}>

<img className="shadow-sm" src={blog.image} alt="blogimage" width="100%"/>
</Link>
</Col>

<Col xl={14} sm={24} >
<div className="content pr-5">
 
<small>by {blog.author} on {moment(blog.createdAt).format("MMMM Do YYYY")}</small>
<Link to={`/blogs/${blog._id}`}>
<h5 style={{color:"var(--acentColor)"}} >{blog.title}</h5>
</Link>
 <div className="desc text-justify">
     <Interweave content={limitDesc(blog.description, 240)}/>
    
</div>   
</div>

<div className="btnSec">

<Row>

    <Col span={10}>
    <Link to={`/blogs/${blog._id}`}>
<Button className="my-2" type="primary">Read More</Button>
</Link>
    </Col>
    <Col span={4}>

 


    </Col>

    <Col span={10}>



    </Col>

</Row>

</div>



</Col>
</Row>

})


        } 
        </BlogWrap>
    )
}


const BlogWrap = styled.div`

h2{

text-transform:capitalize;
    font-family:var(--HeadingFont);
    line-height:1.7;

}

img{

    height:100%;
    border-radius:7px;

}

@media(max-width:960px)
{
    img{
        width:100%;
        
    }
}
`