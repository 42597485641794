import React, {useEffect} from 'react'
import DataTable from './datatable'
import { fetchUsers, userSelector } from '../../../Api/userSlice'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../../Shared/spin'
import Fade from 'react-reveal/Fade'
import {Button} from 'antd'
import {Link} from 'react-router-dom'
import {PlusOutlined} from '@ant-design/icons'
export default function Blog() {

    const dispatch = useDispatch()
    const {users, loading} = useSelector(userSelector)

    useEffect(()=>{

       dispatch(fetchUsers())


    }, [dispatch])

    return (
        <div>
<div className="d-flex  mb-4 align-items-center justify-content-between">
    <h5>Users</h5>
    <Link to="/admin/user/create">
      <Button type="ghost" icon={<PlusOutlined style={{transform:'translateY(-3px)'}}/>}>
            Create User </Button></Link>
</div>
            
            {
                loading ? <Loader/> : <Fade><DataTable users={users}/></Fade>
            }
       
        </div>
    )
}
