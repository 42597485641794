import { Form, Input, Button, Checkbox } from 'antd';
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {isAuthenticateUser, authenticateSelector} from '../Api/authSlice'
import {useDispatch, useSelector} from 'react-redux'
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 18 },
};


export default function Login({history}) {

 const [loading, setLoading] = useState(false)

const dispatch = useDispatch()



const {isAuthenticate } = useSelector(authenticateSelector)

useEffect(()=>{

    if (!isAuthenticate) {
        history.push('/login');
      }
      else{

        history.push('/admin');
      }
    

}, [isAuthenticate, history])



    const onFinish = values => {
      setLoading(true)
        dispatch(isAuthenticateUser(values));
        setTimeout(()=>setLoading(false), 2000)
        

      };
    
      const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };
    


    return (
        <LoginWrap className="container ">
   <div className="login"> 
   <div id="login">   
<Form
{...layout}
name="basic"
initialValues={{ remember: true }}
onFinish={onFinish}
onFinishFailed={onFinishFailed}
>
<Form.Item
  label="User email"
  name="email"
  rules={[{ required: true, message: 'Please input your username!' }]}
>
  <Input />
</Form.Item>

<Form.Item
  label="Password"
  name="password"
  rules={[{ required: true, message: 'Please input your password!' }]}
>
  <Input.Password />
</Form.Item>

{/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
  <Checkbox>Remember me</Checkbox>
</Form.Item> */}

<Form.Item {...tailLayout}>
  <Button type="primary" loading={loading} htmlType="submit">
    Submit
  </Button>
</Form.Item>
</Form> 
</div>  
</div>  
        </LoginWrap>
    )
}



const LoginWrap = styled.div`



.login{

    #login{

        width: 50%;
    padding: 3rem 1rem 1rem;
    box-shadow: 1px 1px 3px 1px #dddddd;
    border-radius:7px;
    transition:0.2s ease-in-out;
    background-color:#fdfdfd;
    &:hover {

    box-shadow: 1px 1px 5px 1px #bdbdbd;

        
    }
    }
height:83.8vh;
display:flex;
align-items:center;
justify-content:center;



}


`