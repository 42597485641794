import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon
    ,
  } from "react-share";


export default function Sharebtns() {
const [url, setURL] = useState()

  useEffect(()=>{
let path = window.location.href
setURL(path)

  }, [])


    return (
        <ShareWrap style={{zIndex:"223"}}>
           <FacebookShareButton url={url}>
           <FacebookIcon size={22} round={true} />
               </FacebookShareButton> 

               <WhatsappShareButton url={url}>
           <WhatsappIcon size={22} round={true} />
               </WhatsappShareButton> 

               <TwitterShareButton url={url}>
           <TwitterIcon size={22} round={true} />
               </TwitterShareButton> 
               
        </ShareWrap>
    )
}


const ShareWrap= styled.div`
margin-left:1rem;
.react-share__ShareButton{

    margin:0 0.3rem;
    &:focus{
border:none;
outline:none;

    }

}
`