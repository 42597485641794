import React from 'react'
import {Row, Col} from 'antd'
import styled from 'styled-components'
import bookimg from '../../../images/book-removebg-preview.png'
import backimg from '../../../images/Home Page copy-07.jpg'

export default function Header() {
    return (
        <HeaderWrap className="bg-dark container-fluid" style={{backgroundImage:`url('${backimg}')`}}>

            <div className="container">
<Row align="bottom">

    <Col xs={24} sm={24} md={6} lg={8} xl={10}>

<img src={bookimg} alt="book" width="100%"/>
       
    </Col>
    <Col span={2} >
    </Col>
    <Col xs={24} sm={24} md={6} lg={10} xl={12}>
        <div className="pt-3">
        <h3 className="text-left">INVOKING YOUR INNER THERAPIST IN HEART FAILURE</h3>

        <div className="d-flex">
            <button style={{backgroundColor:"var(--brandColor)", color:"white"}}>
                <a style={{color:'white'}} target="_tab" href="https://www.amazon.com/dp/B08K9BJB35/ref=as_li_ss_tl?dchild=1&keywords=kris+vijay&qid=1601315805&sr=8-1&linkCode=sl1&tag=httpwwwpubl06-20&linkId=1ada702a9cf65fd8003c1e5b3f96b991&language=en_US" >Buy On Amazon</a></button>
            <button className="ml-4" style={{backgroundColor:"white", color:"var(--brandColor)"}}>
              <a target="_tab" href="https://elevatemarketplace.s3-us-west-2.amazonaws.com/Client+Content/SpotlightPub/Kris+V.+_media_kit.pdf">Get PressKit</a>  </button>

        </div>


        </div>
    </Col>

</Row>

            </div>
            
        </HeaderWrap>
    )
}


const HeaderWrap = styled.div`

background-size:cover;
background-position:bottom;
transform:translateY(-7rem);
position: relative;


button {

    
    padding:0.4rem 0.6rem;
    border:none;
    border-radius:5px;
    font-size:1.3rem;
    box-shadow:2px 2px 5px 1px grey;
    transform:translateY(5rem);


}
h3{

color:var(--brandColor);
font-size:3.2rem;

font-weight:500;
transform:translateY(5rem);

}

img{

    transform:translateY(12rem);
}


.container{
height:90vh;
}

@media(max-width:960px){

    h3{

font-size:1.3rem;
text-align:center;
line-height:1.7;

transform:translateY(7.9rem);

} 

img{

transform:translateY(7.7rem);
width:90%;
}
button{

    font-size:1rem;
    transform:translateY(8.5rem);

  
}
}



`