import React from 'react'
import {Row, Col} from 'antd'
import styled from 'styled-components'
import Interweave from 'interweave'
export default function Introduction({data}) {

let {text, introtitle,  imge, tcolor, pcolor, subheading } = data
    return (
        <IntroWrap className="container mb-4">
            
            <Row>
                <Col xs={24} md={24} lg={14}>
                <h3 style={{color:tcolor}}>{introtitle}</h3>
               {subheading && <p  >{subheading}</p>}
              {data.desc && <p className="hh" style={{color:"var(--acentColor)", lineHeight:"1.7"}}>{data.desc}</p>}  
                <p style={{color:pcolor}}><Interweave content={text}/></p>

                </Col >

                <Col span={1}>
                </Col>
                <Col xs={24} md={24} lg={9}>
                <img style={{objectFit: "cover"}} src={imge} alt="introimg" height="100%"  width="100%" className=" rounded mx-auto d-block"/>
                </Col>

            </Row>

        </IntroWrap>
    )
}


const IntroWrap =  styled.div`

h3{

    color:var(--acentColor);

}

p{

    color:var(--brandColor);
    font-size:18px;
}
@media(max-width:960px)
{
    h3{
        font-size:1.4rem;
    }
    p{
        font-size:1rem;
    }
   
}

`