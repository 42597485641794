import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { message } from 'antd';
import keyUri from '../../uri'

export const initialState = {
    loading: false,
    hasErrors: false,
    users: [],
    currentAdmin:null
   
    
  }

export const userSlice = createSlice({

    name:"users",
    initialState,
    reducers:{
        getUsers: state => {
          state.loading = true
        },
        getUserSuccess: (state, { payload }) => {
         
          state.users = payload
          state.loading = false
          state.hasErrors = false
          
        },

        createUser: state =>{

          state.loading = false
          state.hasErrors = false
        },
        getOneUser : (state, {payload}) =>{


          state.loading = false
          state.users = payload

                    
                  },

            getUpdate : state =>{

                    state.loading = false
                },

                getCurrentUser:(state, {payload}) =>{
                    state.loading = false
                    state.currentAdmin = payload 

                },
        getUserFailure: state => {
          state.loading = false
          state.hasErrors = true
        },

      
      },

})

export const { getUsers, getUpdate, getCurrentUser, createUser, getOneUser, getUserSuccess, getUserFailure } = userSlice.actions
export const userSelector = state => state.users

export default userSlice.reducer


const config = {
  headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
  }
};



export const fetchUsers = () => async dispatch =>{
    dispatch(getUsers())

      try {
        
        const {data} = await axios.get(keyUri + '/api/admins')

    dispatch(getUserSuccess(data))

      } catch (error) {
        dispatch(getUserFailure())
      }

  }


  export const fetchOneUser = (id) => async dispatch =>{
    dispatch(getUsers())

      try {
        
        const {data} = await axios.get(keyUri + `/api/admin/${id}`)

    dispatch(getOneUser(data))

      } catch (error) {
        dispatch(getUserFailure())
      }

  }


export const createUserData = (userdata) => async dispatch =>{
  
  const key = 'create';

  dispatch(getUsers())

  message.loading({ content: 'loading...', key })


      try {
        
        const {data} = await axios.post( keyUri +`/api/register`, userdata, config)

       
    dispatch(createUser())

    setTimeout(() => {

      message.success({ content: data.msg, key, duration: 2 });
    }, 500) 

      } catch (error) {
       
        dispatch(getUserFailure())

        setTimeout(() => {

            message.error({ content: error.response.data.msg, key, duration: 2 });
          }, 500) 
      }

  }




  export const deleteUser = (id, title) => async dispatch =>{

    const key = 'delete';

    dispatch(getUsers());
    message.loading({ content: `Deleting...`, key })
    try {
        
             const {data} = await axios.delete(keyUri + `/api/admin/${id}`)
        
             dispatch(fetchUsers())

        setTimeout(() => {

            message.success({ content:`${title} - ${data.success}`, key, duration: 2 });
          }, 500)

         

    } catch (error) {

        dispatch(getUserFailure());
    }


}



export const updateUser = (id, userupdate) => async dispatch =>{

  const key = 'update';

  dispatch(getUsers());
  message.loading({ content: 'updating...', key })
  try {

       const {data} = await axios.put(keyUri + `/api/admin/${id}`, userupdate, config)
      
       dispatch(getUpdate()) 
         
      setTimeout(() => {

          message.success({ content: data.success, key, duration: 2 });
        }, 200)

       

  } catch (error) {

    console.log();

      dispatch(getUserFailure(error.response.data.msg));

      setTimeout(() => {

        message.error({ content:error.response.data.msg, key, duration: 2 });
      }, 200)
  }


}


export const fetchCurrentUser = (id) => async dispatch =>{

    dispatch(getUsers())
    
    try {
    
        const {data} = await axios.get( keyUri +`/api/admin/${id}`);

    dispatch(getCurrentUser(data))
    
    } catch (error) {

    dispatch(getUserFailure())

    }
    
    
    }








  
