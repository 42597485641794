import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Form, Input, Button,DatePicker } from 'antd';
// import Select from '../../../Shared/select'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import UploadImage from '../../../Shared/UploadImageCD'
import ReactQuill from 'react-quill';
import Loader from '../../../Shared/spin'
import Fade from 'react-reveal/Fade'
import { newsSelector, updateNews, fetchCurrentNews } from '../../../Api/newsSlice';
import credential from '../../../../key'
import moment from 'moment'


const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  

export default function UpdateNewsSec() {


const { id } = useParams()
const {currentNews, loading} = useSelector(newsSelector)
  const [description, setDescription] = useState('');
  const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [imge, setImge] = useState(null);
    const [imgerr, setImageErr] = useState();

    const s =  currentNews &&   moment(currentNews.date).format('MMMM Do YYYY').toString()


useEffect(()=>{

dispatch(fetchCurrentNews(id))
console.log(id)


}, [dispatch])


useEffect(()=>{

    currentNews && setDescription(currentNews.description)
     currentNews && setImge(currentNews.image)
   

console.log(s);
   currentNews &&   form.setFieldsValue({
      title:  currentNews.title ,
      venue:  currentNews.venue,
      date:moment(currentNews.date),
      extra_link:  currentNews.extra_link,

    });
    

}, [currentNews])



const onFinish = (values) => {

values.image = imge
values.description = description

dispatch(updateNews(id, values))



};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const handleClick = (e) => {

  e.preventDefault();

 window.cloudinary.openUploadWidget({cloud_name:credential.cloud_name, upload_preset:credential.upload_preset},
  function(error, result) {


      if(result){

          setImge(result[0].url) 
          setImageErr(undefined);
      } else{

          setImge('')
          alert(error) 

      }
      
  });
}


      
    return (
      loading ? <Loader/> :
      <Fade>
      <CreateBlogWrap className="container ml-3">
            <h5 >Update News</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
             <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

<Form.Item
        label="Enter News Title"
        name="title"
        rules={[{ required: true, message: 'Enter Title' }]}
      >

        <Input />
      </Form.Item> 
      
   <Form.Item
        label="date"
        name="date"
       
        rules={[{ required: true, message: 'Enter date' }]}
     >
<DatePicker  />
   </Form.Item> 
      
      <Form.Item
        label="venue"
        name="venue"
        rules={[{ required: true, message: 'Enter venue' }]}

     >
<Input />

      </Form.Item>

    <Form.Item
        label="Enter read More link"
        name="extra_link"
    

     >
<Input />

      </Form.Item>

  
      <Form.Item  wrapperCol={{ span: 20, offset: 4 }}>

<UploadImage click={(e)=>handleClick(e)} imge={imge} err={imgerr}/>

 </Form.Item>

<Form.Item wrapperCol={{ span: 20, offset: 4 }} >

     <ReactQuill theme="snow" value={description} onChange={setDescription}/>
     
</Form.Item>


<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
   
   

    </Form>
        </CreateBlogWrap>
        </Fade>
    )
}


const CreateBlogWrap = styled.div`
`