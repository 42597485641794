import React from 'react'
import Header from '../../Shared/staticlayout/header'
import donateimg from '../../../images/bannerimages/woman-holds-heart-blue-space-close-up-health-care-organ-donation.jpg'
import Intro from './intro'
import Dimg from './dimg'
import DonateNow from '../home/donateNow'
import AchievMent from './achievMent'
export default function index() {
    return (
        <div>
           <Header headerImg={donateimg} title="DONATE"/> 
            <Intro/>
            <Dimg/>
            <DonateNow/>
            <AchievMent/>

        </div>
    )
}
