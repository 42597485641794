import React from 'react'
import { Input } from 'antd';
import {fetchAllBlogs} from '../../Api/blogSlice'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
const { Search } = Input;

export default function SearchSec() {

 
const dispatch = useDispatch()
      const onSearch =  value => {

        // const {data} = await axios.get(`/search?search=${value}`)
   
        dispatch(fetchAllBlogs(0, 10, value))
        


      };



    return (
        <SearchWrap className="container my-3 pb-5">
          <Search placeholder="input search text" onSearch={onSearch} enterButton />

        </SearchWrap>
    )
}


const SearchWrap = styled.div`
.ant-input-search-enter-button input {
    border-right: 0;
    font-size: 1.4rem;
}

.ant-input-group-addon {

    background-color: var(--brandColor) !important;
}

.anticon {

  font-size:1.8rem;
}


.ant-input {

font-size: 20px;
box-shadow: 2px 2px 3px 1px #dedede;
border: 0.3px solid #fbfbfb;
border-radius: 0 0 10px 10px;
padding:0.5rem 1rem;
}


.ant-input-group-addon{

font-size: 20px;
 box-shadow: 2px 2px 3px 1px #dedede;
 border: 0.3px solid #fbfbfb;
 border-radius: 0 0 10px 0;
 background-color:white;

}

.ant-input-group-addon .ant-btn {

  background-color:var(--brandColor);
  border:none;
  box-shadow: 0px 0px 0px 0px #5a5858 !important;

}


`