import React from 'react'
import { Tabs } from 'antd';
import BlogTab from '../blogs/blogs'
import JournalTab from '../journals/journalSec'
import ResearchTab from '../research/researchcard'
import Search from './search'
const { TabPane } = Tabs;

export default function LibraryTab({brefing, blogs,journal, research}) {
   

    return (
        <div className="w-75 mx-auto">
             <Tabs defaultActiveKey="2" >
   
    <TabPane tab="Articles" key="2">

<Search sec="article"/>


   <BlogTab blogs={blogs}/>
    </TabPane>
    <TabPane tab="Research" key="3">
    <Search sec="research"/>

      <JournalTab data={research}/>
    </TabPane>
    <TabPane tab="Daily Briefing" key="1">
    <Search sec="daily"/>

    <ResearchTab data={brefing}/>
    </TabPane>

    <TabPane tab="Journal" key="4">
    <Search sec="journal"/>

    <ResearchTab data={journal}/>
    </TabPane>
  </Tabs>
        </div>
    )
}
