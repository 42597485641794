import React from 'react'
import Header from './header'
import Intro from './introduction'
import styled from 'styled-components'
export default function StaticLayout({title, data, headerImg, children }) {

    return (
        <StaticLayoutWrap className="mb-3">
            {/* <Header title={title} headerImg={headerImg}/> */}

            <div className="container acc my-5">
            <Intro data={data}/>
            <div>
            {
    children
}
             </div>
   </div>
   
        </StaticLayoutWrap>
    )
}


const StaticLayoutWrap = styled.div`
.acc{
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: var(--acentColor);
    font-size: 1.3rem !important;
    border-radius:5px !important;

    font-weight:500;
}

.ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 0px solid #ffffff;
    margin: 1rem 0;
    background: #eaeaea;
    
}

ul > li {


line-height:1.7;
font-size:1rem;
color:var(--acentColor);

}

p{

line-height:1.8;
font-size:1.1rem;
color:var(--acentColor);
padding-right:1rem;
font-weight:400;
}

}


@media(max-width:960px){


    

  .acc {


    transform: translateY(-3rem);

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: var(--acentColor);
    font-size: 1rem !important;
    border-radius:5px !important;

    font-weight:500;
}


    ul > li {


line-height:1.7;
font-size:0.8rem;
color:var(--acentColor);

}

p{

line-height:1.7;
font-size:0.8rem;
color:var(--acentColor);
}

  }  



}

`