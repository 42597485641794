import React from 'react'
// import {GiCaduceus} from 'react-icons/gi'
import { Carousel } from 'antd';
import styled from 'styled-components'
import review from '../../../images/review.png'
export default function Tesimony() {


     let authordata = [{

        reader:"Craig A. Hoover",
        qualification:"MD, MBA, FACC, FSCAI",
      subheading:"Assistant Professor of Clinical Medicine, University of Arizona College of Medicine",
        description:"Dr. Vijay is one of the rarest of physicians – he not only possesses an encyclopedic knowledge of the science and pathophysiology of illness, but he also intuitively understands the spiritual nature of our being.  In today’s world of high-tech Cardiology, we are paid to do things - tests, prescriptions, procedures – but there is little incentive to understand the impact that heart disease plays in our emotional health.  In this short book you will gain insight into the ways that our cardiovascular system fails us and how we can work to repair the damage.  But more importantly, you will see how a caring and skilled physician and educator can help integrate emotional and lifestyle changes with the medical therapies that are critical to living a more robust life.  Dr. Vijay puts the high touch in high-tech, and we are all better for it."
      },
      {

        reader:"Dr Dayaprasad G Kulkarni",
        qualification:"",
        subheading:"Assistant Professor Dept. of Biomedical and Chemical Engineering, Syracuse University",

        description:"“People are much more than their diseases; Doctors are much more than technicians. And treatments are much more than commodities on a menu”. This is what I frequently say that as practitioners in this corporate world sometimes we tend to forget. The essence of the book brought out by Dr Kris Vijay clearly conveys this message and in a way creates an opportunity to democratize cardiac care. The tremendous experience, multidimensional thought process and hard work brings out a true innovative approach that is so simple and accessible to everyone. Having a heart of gold himself and a true passion to not just provide the best of care to his patients but to serve them with compassion, Dr Vijay’s message in the form of this book is a must read for both doctors and patients alike. “"
      },
    ]


    return (
        <AuthWrap className=" pt-sm-4 px-sm-5" style={{backgroundColor:"white", transform:"translateY(-4rem)"}} className="container-fluid">
<h3  className="text-center my-3 mt-5" style={{color:"var(--brandColor)"}}>Book Review</h3>  
<img className="mx-auto my-2 d-block"  src={review} alt="review" width="180px"/>

  <Carousel autoplay effect="fade" dots={false}	>

      {

authordata.map((item, i)=>{

    return <div key={i}>


<div className="text-center container desc  pt-2 text-light m-auto">


<p className="text-secondary " style={{lineHeight:"1.8"}}>{item.description}</p>

<h4 className="mb-1">{item.reader}, <small style={{fontSize:"10px"}}>{item.qualification}</small></h4>
<small style={{color:"var(--brandColor)", fontWeight:"500"}}>{item.subheading}</small>

</div>

   

    </div>

})
      }
  
 
  </Carousel>
        </AuthWrap>
    )
}


const AuthWrap = styled.div`

hr{

    width:10%;
    border:none;
    height:2px;
    background-color:white;

}


.ant-carousel .slick-dots li{

    background-color: var(--brandColor);
    

}


.ant-carousel .slick-dots li.slick-active button{

background:var(--brandColor) !important;


}

img{

    display:block;
    margin:1rem auto;
    
}

svg{

    color:white;
    font-size:4rem;
    text-align:center;
    display:block;
    margin:auto;

}

p{

    font-size:1rem;
    color:#000000;
}



@media(max-width:980px){


.desc{

width:100%;
p{
font-size:0.8rem;
}

}

}

`
