import React, {useEffect, useState } from 'react'
import DPaymentBtn from '../sap_danate/dpayment'
import { Modal, Button,InputNumber, Input} from 'antd';

export default function DonateNow({amount}) {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [mail, setUserMail] = useState(null);




console.log(amount);
    const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        setIsModalVisible(false);
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };

    return (
        <div>

                    <Button type="primary" onClick={showModal } className="px-4 my-3   d-block mx-auto " style={{fontSize:"1rem", height:"40px"}}>DONATE NOW</Button>
               

     <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        
     <Input
        placeholder="Enter Your email"
      autoFocus
      rules={[
        {
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
        {
          required: true,
          message: 'Please input your E-mail!',
        },
      ]}
      onChange={(e)=>setUserMail(e.target.value)}
      style={{display:"block",
      color:"green",
      fontSize:"1.5rem", width:"100%", margin:"1rem auto"}}
    />

    <InputNumber
      defaultValue={amount}
      min={2}
  
      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={value => value.replace(/\$\s?|(,*)/g, '')}
    //   onChange={(value)=>setAmt(value)}
    value={amount}
    disabled
      style={{display:"block",
      color:"green",
      
      fontSize:"1.5rem", width:"100%", margin:"1rem auto"}}
    />
   
    <DPaymentBtn amt={amount} email={mail} />


      </Modal>
            
        </div>
    )
}
