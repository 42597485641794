import React from 'react'
import Loader from '../../Shared/spin'
import Qtn from './expert'

export default function Experts(props) {

const {Questions, loading} = props



return (

        <div >
            {
             loading? <Loader /> :  <Qtn  questions ={Questions.questions}/> 
            }
        </div>
    )
}
