import React, {useEffect } from 'react'
import Header from '../../Shared/staticlayout/header'
import {useDispatch, useSelector} from 'react-redux'
import {fetchAllQtn, qtnSelector} from '../../Api/expertSlice'
import {isPublicP} from '../../Api/layoutSlice'

import Qtns from './experts'
import AskQtn from './askQtn'
export default function Experts() {

const dispatch = useDispatch()
const questions = useSelector(qtnSelector)

useEffect(()=>{

    dispatch(fetchAllQtn());
    dispatch(isPublicP());


}, [dispatch])


    return (
        <div>
         <Header 
         title="ASK AN EXPERT"
         headerImg="https://cdn.pixabay.com/photo/2016/08/16/09/53/international-conference-1597531_960_720.jpg"/>
         <div className="d-block container py-5">
         <AskQtn/>
     </div>
         <Qtns {...questions}/>
  
        </div>
    )
}
