
import React, { useState } from 'react';
import { Drawer, Button, Menu } from 'antd';
import logo from '../../../images/newlogo.jpg'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
// import './mobstyle.css'
const { SubMenu } = Menu;

export default function MobNav() {

    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
      setVisible(true);
    };
  
    const onClose = () => {
      setVisible(false);
    };
    // icon={<MenuUnfoldOutlined  className="humberg"/>}
    
    return (
        <MobNavWrap>
        <Button className="humberg"  type="text" onClick={showDrawer}>

<div></div>
<div></div>
<div></div>
            </Button>
       
        <Drawer
          title= {<img src={logo} width="150px"/>}
          placement="left"
          closable={false}
          onClose={onClose}
          visible={visible}
          bodyStyle={{padding:"0.5rem 0"}}
        >
       <Menu
        mode="inline"
        // defaultSelectedKeys={['/about-us']}
       >
                 <SubMenu  key="sub1"  title="About Us">
  
   
                 <Menu.Item key="9" >
            <Link to="/news">News&Events</Link>
            </Menu.Item>

        <Menu.Item key="/board-of-directors">
           <Link to="/board-of-directors">Board of Directors</Link> 
            </Menu.Item>

        <Menu.Item key="/history">
        <Link to="/history">History</Link> 
            
            </Menu.Item>

        {/*  <Menu.Item key="/advisory-board">
        <Link to="/advisory-board">Advisory Board</Link> 
            </Menu.Item>

           <Menu.Item key="/staff">
           <Link to="/staff">Staff</Link> 
           </Menu.Item>

            <Menu.Item key="/partners">
        <Link to="/partners">Partners</Link> 
            </Menu.Item>  */}
     </SubMenu>




     <SubMenu  key="sub2"  title="Mission Programs">
  
   
  <Menu.Item key="/community-health-screening" >
<Link to="/community-health-screening">Community Health Screening</Link>
</Menu.Item>

<Menu.Item key="/medical-conference">
<Link to="/medical-conference">Medical Conference </Link> 
</Menu.Item>

<Menu.Item key="/cpr-Training">
<Link to="/cpr-Training">CPR Training</Link> 

</Menu.Item>

<Menu.Item key="/advisory-board">
<Link to="/research">Research</Link> 
</Menu.Item>

</SubMenu>








<SubMenu  key="sub3"  title="Professional Edu">
  
   
  <Menu.Item key="/daily-briefing" >
<Link to="/daily-briefing">Daily Briefing</Link>
</Menu.Item>

<Menu.Item key="/conference-room">
<Link to="/conference-room">Conference Room </Link> 
</Menu.Item>

<Menu.Item key="/library">
<Link to="/library">Library </Link> 

</Menu.Item>

<Menu.Item key="/research">
<Link to="/research">Research</Link> 
</Menu.Item>

<Menu.Item key="/ask-an-expert">
<Link to="/ask-an-expert">Ask an Expert</Link> 
</Menu.Item>

<Menu.Item key="/blogs">
<Link to="/blogs">Blogs</Link> 
</Menu.Item>
{/* <Menu.Item key="/scientific-advisory-board">
<Link to="/scientific-advisory-board">Scientific Advisory Board</Link> 
</Menu.Item> */}

<Menu.Item key="/medical-conference">
<Link to="/medical-conference">Medical Conference</Link> 
</Menu.Item>

<Menu.Item key="/our-books">
<Link to="/our-books">Our Books </Link> 
</Menu.Item>

</SubMenu>




<SubMenu  key="sub4"  title="Patient Edu & Support">
  
   
  <Menu.Item key="/covid19-complition-comorbidities" >
<Link to="/covid19-complition-comorbidities">Covid19:Complition & Comorbidities</Link>
</Menu.Item>

<Menu.Item key="/heart-disease">
<Link to="/heart-disease">Heart Disease  </Link> 
</Menu.Item>



<Menu.Item key="/the-twinepidemic">
<Link to="/research">The Twinepidemic</Link> 
</Menu.Item>

<Menu.Item key="/kidney-disease">
<Link to="/kidney-disease">Kidney Disease</Link> 
</Menu.Item>

<Menu.Item key="/recipes-for-a-healthy-life">
<Link to="/recipes-for-a-healthy-life">Recipes for a Healthy Life</Link> 
</Menu.Item>

<Menu.Item key="/free-community-health-screenings">
<Link to="/free-community-health-screenings">Free Community Health Screenings</Link> 
</Menu.Item>

{/* <Menu.Item key="/prepare-for-your-visit-with-the-doctor">
<Link to="/prepare-for-your-visit-with-the-doctor">Prepare for your Visit with the Doctor </Link> 
</Menu.Item> */}

<Menu.Item key="/cpr-training">
<Link to="/cpr-training">CPR Training</Link> 
</Menu.Item>

{/* 
<Menu.Item key="/patient-advisory-council">
<Link to="/patient-advisory-council">Patient Advisory Council</Link> 
</Menu.Item> */}

</SubMenu>




<SubMenu  key="sub5"  title="Support">
  
   
  <Menu.Item key="/donate" >
<Link to="/donate">Donate</Link>
</Menu.Item>

<Menu.Item key="/special-events">
<Link to="/special-events">Special Events </Link> 
</Menu.Item>

<Menu.Item key="/volunteer">
<Link to="/volunteer">Volunteer </Link> 

</Menu.Item>

<Menu.Item key="/share-your-story">
<Link to="/share-your-story">Share Your Story</Link> 
</Menu.Item>

{/* <Menu.Item key="/patient-advisory-council">
<Link to="/patient-advisory-council">Patient Advisory Council </Link> 
</Menu.Item> */}

{/* <Menu.Item key="/scientific-advisory-board">
<Link to="/scientific-advisory-board">Scientific Advisory Board </Link> 
</Menu.Item> */}



</SubMenu>

</Menu>
        </Drawer>
      </MobNavWrap>
    )
}


const MobNavWrap = styled.div`



.ant-btn{

    z-index:22 !important;

    margin:0.5rem;

}


.humberg{

div{

width: 30px;
height:2px;
margin:5px 0;
background-color:var(--brandColor);
}
    /* font-size:1.5rem;
    color:white; */
}

`