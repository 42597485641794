import React from 'react'
import styled from 'styled-components'
import { Layout, Menu, Switch } from 'antd';
import {Link, useLocation} from 'react-router-dom'
import {authenticateSelector} from '../Api/authSlice'
import {useSelector} from 'react-redux'
import {
  SnippetsOutlined,
  QuestionCircleFilled,
    FormOutlined,
    AppstoreFilled,
    FileTextOutlined,
    CalendarOutlined,
    FileSearchOutlined,
    SolutionOutlined,
    NotificationOutlined,
    DollarCircleOutlined,
    TeamOutlined,
    UsergroupAddOutlined
  } from '@ant-design/icons';

import whitelogo from '../../images/newlogo.jpg'
import darklogo from '../../images/whitelogo.png'

const {  Sider } = Layout;

export default function Sidemenu({click, color, collapsed }) {

const {pathname} = useLocation()

const {user} = useSelector(authenticateSelector)


    return (
   <SideMenuWrap color={color}>
             <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="py-sm-5 py-2">
<a href="http://www.twinepidemic.org" target="_tab">
<img className="mx-auto d-block" src={color?darklogo: whitelogo} alt="alt" width="170px"/>
 </a>        
            </div>



          <Menu
           theme={color? 'dark' : 'light'}
           mode="inline"
           defaultSelectedKeys={[pathname]}
           className="menu"
          >
           
          <Menu.Item key="/admin" icon={<AppstoreFilled />} >
           <Link to="/admin">Dashboard</Link> 
            </Menu.Item>
         <Menu.Item key="/admin/blogs" icon={<FormOutlined />} > 
          <Link to="/admin/blogs">Blogs</Link>
          
          </Menu.Item>
         <Menu.Item key="/admin/events" icon={<CalendarOutlined />} >
           
         <Link to="/admin/events">Events</Link></Menu.Item>
         <Menu.Item key="/admin/news" icon={<SnippetsOutlined />} >
         <Link to="/admin/news">News</Link>
         </Menu.Item>

         <Menu.Item key="/admin/research" icon={<FileSearchOutlined />} >
         <Link to="/admin/research">Research</Link>
         </Menu.Item>

         <Menu.Item key="/admin/journal" icon={<SolutionOutlined />} >
         <Link to="/admin/journal">Journal</Link>
         </Menu.Item>

         
         <Menu.Item key="/admin/volunteer" icon={<TeamOutlined />} >
         <Link to="/admin/volunteer">Volunteer</Link>
         </Menu.Item>

         <Menu.Item key="/admin/share-your-story" icon={<NotificationOutlined />} >
         <Link to="/admin/share-your-story">Share your story</Link>
         </Menu.Item>

         <Menu.Item key="/admin/experts" icon={<QuestionCircleFilled />} >
         <Link to="/admin/experts">Experts</Link>
         </Menu.Item>

         <Menu.Item key="/admin/daily-breifing" icon={<FileTextOutlined />} >
         <Link to="/admin/daily-breifing">Daily Briefing</Link>
         </Menu.Item>

         <Menu.Item key="/admin/donor" icon={<DollarCircleOutlined />} >
         <Link to="/admin/donor">Donor</Link>
         </Menu.Item>
    
        {

        user.curentUser.super_admin && <Menu.Item key="/admin/users" icon={<UsergroupAddOutlined />} >
                <Link to="/admin/users">Users</Link>
                </Menu.Item>
        }
        
         

          </Menu>
          <div className="mode">
         <Switch checkedChildren="Dark" unCheckedChildren="Light" onChange={()=>click()} />
          </div>
        </Sider>
        </SideMenuWrap>
      
    )
}


const SideMenuWrap = styled.div`

height:101vh !important;

.menu{


font-size:1rem;
svg{

  font-size:1.1rem;
  transform:translate(-5px, -2px);
}

}

/* .ant-menu-item .ant-menu-item-selected span.anticon.anticon-snippets  svg {
  color: white !important;
} */

.mode{

position:absolute;
bottom:5%;
left:10%;

.ant-switch{

  background-color:${props=>props.color? "grey":"var(--brandColor)"};
}
}


`

    {/* <SubMenu  key="sub1" icon={<UserOutlined/>} title="Vouchers">
 
           <Menu.Item key="2" >All vouchers</Menu.Item>
           <Menu.Item key="3" >Add new voucher</Menu.Item>

         </SubMenu> */}

         {/* <SubMenu  key="sub2" icon={<CodeSandboxSquareFilled />} title="Products">
 
        <Menu.Item key="4"  >All Products</Menu.Item>
        <Menu.Item key="5" >Add new Product</Menu.Item>

        </SubMenu> */}