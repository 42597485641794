import {createSlice} from '@reduxjs/toolkit'

export const initialState = {

    loading:false,
    hasErrors:false,
   isPrivate:false,
   isPublic:true

}

export const layoutSlice = createSlice({


    name:"layout",
    initialState,
    reducers:{

      Lodinglayout:state =>{

        state.loading = true;
      },

      isPrivatePage: state =>{

       
        state.isPrivate = true;
        state.isPublic = false;


      },

      isPublicPage: state =>{

       
        state.isPublic = true;
        state.isPrivate = false;

      },


      isLayoutError: state =>{

        state.hasErrors = true;
     

      }



    }


})


export const {isPrivatePage,isLayoutError,isPublicPage, Lodinglayout}  = layoutSlice.actions

export const layoutSelector = state => state.layoutState
export default layoutSlice.reducer

export const isPrivateP = () => async dispatch =>{

    dispatch(Lodinglayout())

    try {
        
        dispatch(isPrivatePage())

    } catch (error) {

        dispatch(isLayoutError())
    }

}

export const isPublicP = () => async dispatch =>{

    dispatch(Lodinglayout())

    try {
        
        dispatch(isPublicPage())

    } catch (error) {

        dispatch(isLayoutError())
    }

}

