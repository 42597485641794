import React from 'react'
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom'
import navimg from '../../../images/bannerimages/doctor-giving-presentation-team-interim-doctors (1) (1).jpg'
export default function about() {
    return (
        <Fade bottom duration={300} distance={"30px"}>
        <div style={{width:"750px"}} className="dropdown-menu" aria-labelledby="navbarDropdown">
    
    <div className="row" style={{position:"relative"}}>
        <div className="col-sm-3">
        <ul className="text-dark text-capitalize">
        <li className="nav-item ">
        <Link className="nav-link" to="/daily-briefing">Daily Briefing </Link>
      </li>
      {/* <li className="nav-item ">
        <Link className="nav-link" to="/conference-room">Conference Room</Link>
      </li> */}
     <li className="nav-item ">
        <Link className="nav-link" to="/library">Library </Link>
      </li>
      <li className="nav-item ">
        <Link className="nav-link" to="/research">Research</Link>
      </li>
      <li className="nav-item ">
        <Link className="nav-link" to="/ask-an-expert">Ask an Expert </Link>
      </li>
    
 
            </ul>
        </div>

        <div className="col-sm-3">
        <ul className="text-dark text-capitalize">

      {/* <li className="nav-item ">
        <Link className="nav-link" to="/scientific-advisory-board">Scientific Advisory Board </Link>
      </li> */}

      <li className="nav-item ">
        <Link className="nav-link" to="/medical-conference">Medical Conference</Link>
      </li>
      <li className="nav-item ">
        <Link className="nav-link" to="/our-books">Our Books </Link>
      </li>
   <li className="nav-item ">
        <Link className="nav-link" to="/blogs">Blogs</Link>
      </li>
      </ul>
  </div>


        <div className="col-sm-6">
    
    <img className="mt-3"  src={navimg} alt="navImag"/>
  </div>
        
       
    </div>
    
    
        </div>
        </Fade>
    )
}


