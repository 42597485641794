import React from 'react'
import {Row, Col} from 'antd'
import styled from 'styled-components'
import bimage1 from '../../../../images/boardmembers/Charles Paltzer.jpg'
import bimage2 from '../../../../images/boardmembers/Indu Anand.jpg'
import bimage3 from '../../../../images/boardmembers/Kris Vijay.jpg'
import bimage4 from '../../../../images/boardmembers/Mandeep Sahani.jpg'
import bimage5 from '../../../../images/boardmembers/Pankaj Jain.jpg'
import bimage7 from '../../../../images/boardmembers/Renzo Cataldo.jpg'


export default function ourteam() {

let ourTeamData = [{

    title:"Kris Vijay, MD",
    status:"President",
    imge:bimage3
},
{

    title:"Renzo Cataldo, MD",
    status:" Vice President",
    imge:bimage7
},
{

    title:"Pankaj Jain, MD",
    status:"Co- Secretaries",
    imge:bimage5
},
{

    title:"Mandeep Sahani",
    status:"Co- Secretaries",
    imge:bimage4
},

{

    title:" Indu Anand",
    status:"Treasurer",
    imge:bimage2
},
,

{

    title:" Charles Paltzer",
    status:"Executive Director",
    imge:bimage1
}]

    return (
        <OurTeamWrap>
                        <h3 className="text-center my-2 " style={{color:"var(--acentColor)"}}>Our Team</h3>

     
        <Row gutter={25} className="text-center py-5" justify="center" align="center">
           {

ourTeamData.map((item, i)=>{

    return <Col xs={12} lg={8} key={i} >

<img  style={{borderRadius:"50%", objectFit:"cover"}} src={item.imge} alt="img" width="60%" height="60%"  className="mx-auto shadow-sm"/>
<div className="p-2">
<h5 style={{color:"var(--brandColor)"}}>{item.title}</h5>
<p style={{color:"var(--acentColor)"}}>{item.status}</p>
</div>
 </Col>


})

           } 
        </Row>
        </OurTeamWrap>
    )
}


const OurTeamWrap = styled.div`

@media(max-width:960px) {

h5{

    font-size:1rem;

}
p{

        font-size:0.8rem;

}

}


`