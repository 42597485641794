import React from 'react'
import styled from 'styled-components'

export default function AcPanel() {

let data = [{

    title:"QUIT SMOKING",
    text:`Smokers have more than twice the risk for heart attack as nonsmokers and are much more likely to die if they suffer a heart attack. Smoking is also the most preventable risk factor. If you smoke, quit. Better yet, never start smoking at all. Nonsmokers who are exposed to constant smoke also have an increased risk.`
},
{

    title:"IMPROVE CHOLESTEROL LEVELS.",
    text:`The risk for heart disease increases as your total amount of cholesterol increases. Your total cholesterol goal should be less than 200 mg/dl; HDL, the good cholesterol, higher than 40 mg/dl in men and 50 mg/dl in women (and the higher the better); and LDL should be less than 130 mg/dl in healthy adults. For those with diabetes or multiple risk factors for heart disease, the LDL goal should be less than 100 mg/dl (some experts recommend less than 70 mg/dl if you are very high risk). Interpretation and treatment of cholesterol values must be individualized, taking into account all of your risk factors for heart disease. A diet low in cholesterol and saturated and trans fats and high in complex carbohydrates and good fats (omega 3s) will help lower cholesterol levels and reduce your risk for heart disease. Regular exercise will also help lower "bad" cholesterol and raise "good" cholesterol. Often, medications are needed to reach cholesterol goals.`
},
{

    title:"CONTROL HIGH BLOOD PRESSURE",
    text:`About 67 million people in the U.S. have hypertension, or high blood pressure, making it the most common heart disease risk factor. Nearly one in three adults has systolic blood pressure (the upper number) over 130, and/or diastolic blood pressure (the lower number) over 80, which is the definition of hypertension. Like cholesterol, blood pressure`
},
{

    title:"CONTROL DIABETES.",
    text:`About 67 million people in the U.S. have hypertension, or high blood pressure, making it the most common heart disease risk factor. Nearly one in three adults has systolic blood pressure (the upper number) over 130, and/or diastolic blood pressure (the lower number) over 80, which is the definition of hypertension. Like cholesterol, blood pressure `
},
{

    title:"GET ACTIVE",
    text:`Many of us lead sedentary lives, exercising infrequently or not at all. People who don't exercise have higher rates of death and heart disease compared to people who perform even mild to moderate amounts of physical activity. Even leisure-time activities like gardening or walking can lower your risk of heart disease. Most people should exercise 30 minutes a day, at moderate intensity, on most days. More vigorous activities are associated with more benefits. Exercise should be aerobic, involving the large muscle groups. Aerobic activities include brisk walking, cycling, swimming, jumping rope, and jogging. If walking is your exercise of choice, use the pedometer goal of 10,000 steps a day. Consult your doctor before starting any exercise program.interpretation and treatment should be individualized, considering your entire risk profile. Control blood pressure through diet, exercise, weight management, watching your salt, and if needed, medications.`
},
{

    title:"EAT RIGHT",
    text:`About 67 million people in the U.S. have hypertension, or high blood pressure, making it the most common heart disease risk factor. Nearly one in three adults has systolic blood pressure (the upper number) over 130, and/or diastolic blood pressure (the lower number) over 80, which is the definition of hypertension. Like cholesterol, blood pressure `
},
{

    title:"ACHIEVE AND MAINTAIN A HEALTHY WEIGHT",
    text:`Excess weight puts significant strain on your heart and worsens several other heart disease risk factors such as diabetes, high blood pressure, high cholesterol, and triglycerides. Research is showing that obesity itself increases heart disease risk. By eating right and exercising, you can lose weight and reduce your risk of heart`
},
{

    title:"MANAGE STRESS",
    text:`Poorly controlled stress and anger can lead to heart attacks and strokes. Use stress and anger management techniques to lower your risk. Learn to manage stress by practicing relaxation techniques, learning how to manage your time, setting realistic goals, and trying some new techniques such as guided imagery, meditation, massage, Tai Chi, or yoga.`
}]

    return (
        <ACWrap className="ml-sm-5 ml-1">
            <p>
            Making changes in your lifestyle is a proven method for reducing your risk of developing heart disease. While there are no guarantees that a heart-healthy lifestyle will keep heart disease away, these changes will certainly improve your health in other ways, such as improving your physical and emotional wellbeing. Also, because some risk factors are related to others, making changes in one area can benefit other areas. Heart disease is preventable 80%-90% of the time due to lifestyle changes. Making these changes can help prevent heart attacks and strokes.
           <br/>
            Here are some ways you can reduce your risk of heart disease.
            </p>
            <div>
               {

                   data.map((item, i)=>{
            

                    return <div key={i} className="my-4 my-sm-5 ">

                       <h4 style={{color:"var(--brandColor)"}}>{item.title}</h4>
                       <p className="ml-3" >{item.text}</p>
                    
                    </div>



                   })
               }

            </div>
        </ACWrap>
    )
}


const ACWrap = styled.div`

@media(max-width:960px){

    h4{

        font-size:1rem;
    }
}

`