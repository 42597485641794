import React from 'react'
import {Row, Col} from 'antd'
import styled from 'styled-components'
export default function OtherBoard() {


    let ourTeamData = [{

        title:"Marc Silver ",
        status:""

    },
    {
    
        title:"Analyn Scott",
        status:""

    },
    {
    
        title:"Corina Grancorvitz ",
        status:""

    },
    ,
    {
    
        title:"Rob Halter",
        status:""

    },
    {
    
        title:"Anuj Bhargava",
        status:""
    },
    {
    
        title:"Michael Gratch",
        status:""
    },
    {
    
        title:"Joseph Lillo",
        status:""

    },
    {
    
        title:"Priti Singh",
        status:""

    },
    {
    
        title:"Navaz Dolasa",
        status:""
    },
    {
    
        title:"Michael Castro",
        status:""

    },
    {
    
        title:"Gina Marie Scarpa",
        status:""

    },
    {
    
        title:"Ira Goldberg",
        status:""

    },
    {
    
        title:"Raj Sivananthan",
        status:""

    },
    {
    
        title:"Perminder Sanghera",
        status:""

    },
    {
    
        title:"Teresa Mercer",
        status:""

    },
    {
    
        title:"Desiree Little",
        status:""

    },
    {
    
        title:"Christian Bruberda",
        status:""

    }]


    return (
   
        <OurBoardMembers className="container mt-5">
        <h3 className="text-center mt-2 " style={{color:"var(--acentColor)"}}>Advisory Board Members</h3>


<Row gutter={25} className="text-center pb-5" justify="center" align="center">
{

ourTeamData.map((item, i)=>{

return <Col className="my-4" xs={12} lg={8} key={i} >

<div className="p-2">
<p style={{color:"var(--acentColor)"}}>{item.status}</p>
<h5 style={{color:"var(--acentColor)"}}>{item.title}</h5>
<hr/>

</div>
</Col>


})

} 
</Row>



</OurBoardMembers>


    )
}


const OurBoardMembers = styled.div`


hr{

    height:2px;
    border:none;
    width:30%;
    margin:auto;
    background-color:var(--brandColor);
}

p{

font-size:0.8rem;
margin:0;

}
@media(max-width:960px) {

h5{

    font-size:1rem;

}


}


`