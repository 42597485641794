import React from 'react'
import {Row, Col} from 'antd'
import styled from 'styled-components'
export default function OtherBoard() {


    let ourTeamData = [{

        title:"Rachel Benson",
        status:""

    },
    {
    
        title:"Constantine Blaha",
        status:""

    },
    {
    
        title:"Kimberly Hoff",
        status:""

    },
    ,
    {
    
        title:"Navin Govind",
        status:""

    },
    {
    
        title:"Nelson Llumiquinga",
        status:""
    },
    {
    
        title:"Lisa Noble",
        status:""
    },
    {
    
        title:"Sanku Rao, MD",
        status:""

    },
    {
    
        title:"Randal Schulhauser",
        status:""

    }]


    return (
   
        <OurBoardMembers className="container">
        <h3 className="text-center my-2 " style={{color:"var(--acentColor)"}}>Directors</h3>


<Row gutter={25} className="text-center py-5" justify="center" align="center">
{

ourTeamData.map((item, i)=>{

return <Col className="my-4" xs={12} lg={8} key={i} >

<div className="p-2">
<p style={{color:"var(--acentColor)"}}>{item.status}</p>
<h5 style={{color:"var(--acentColor)"}}>{item.title}</h5>
<hr/>

</div>
</Col>


})

} 
</Row>

{/* <>

<h3 className="text-center mt-2 " style={{color:"var(--acentColor)"}}>Executive Director</h3>


<Row gutter={25} className="text-center pb-5" justify="center" align="center">


 <Col className="mb-4" xs={12} lg={8}  >

<div className="p-2">
<h5 style={{color:"var(--acentColor)"}}>Charles Paltzer</h5>
<hr/>

</div>
</Col>

 
</Row>

</> */}




</OurBoardMembers>


    )
}


const OurBoardMembers = styled.div`


hr{

    height:2px;
    border:none;
    width:30%;
    margin:auto;
    background-color:var(--brandColor);
}

p{

font-size:0.8rem;
margin:0;

}
@media(max-width:960px) {

h5{

    font-size:1rem;

}


}


`