import React from 'react'
import {Row, Col} from 'antd'
import styled from 'styled-components'
import InterWeave from 'interweave'
import limitDesc from '../../Shared/limitDes'
import Item from 'antd/lib/list/Item'
import {Link } from 'react-router-dom'
export default function RecentArticle({blog}) {

    return (
        <>
{

    blog && <RecentArticleWrap className="container-fluid py-sm-5 ">
            <div className="container pb-sm-5 pt-sm-2 py-5">

                <Row>
                    <Col xs={24} md={12} lg={12}>

<h6>Recent Article</h6>
<h4>
    {blog.title}

</h4>
<p>
  <InterWeave content={ limitDesc(blog.description, 252)  }/>  


</p>

<Link to={`/blogs/${blog._id}`}  className="btn text-white" style={{backgroundColor:"var(--acentColor)"}}>Read More</Link>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
<div className="simg ">
            <img src={blog.image} alt="iMge" />
</div>
                    </Col>

                </Row>
            </div>
        <div>

       
        </div>
        
        </RecentArticleWrap>
}
   </>     
    )
}


const RecentArticleWrap = styled.div`


background-color:#ff77bf0f;
h4{

    color:var(--acentColor);
}

h6{

    color:var(--brandColor);
}
p{

    color:var(--acentColor);
    font-size:1.1rem;
    
}
.simg{
  top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    box-shadow: 0px 1px 20px 1px grey !important;
border-radius:10px;
    position:absolute;
   img {

    width:370px;
    border-radius:10px;
    box-shadow: 25px 25px 0px 1px var(--acentColor);
} 
}


@media(max-width:960px) {


    .simg{
 
    position:relative;
    margin-top:1.5rem;

   img {

    width:100%;


    border-radius:10px;
    box-shadow: 15px 15px 0px 1px var(--acentColor);
} 
}

}

`