import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { message } from 'antd';
import keyUri from '../../uri'
export const initialState = {

    loading:false,
    hasErrors:false,
    blogs:[],
    currentBlog:null,
    total:0

}

export const blogSlice = createSlice({

    name:"blogs",
    initialState,
    reducers:{

        getBlog:state =>{

            state.loading = true;

        },
        getBlogSuccess:(state, {payload}) =>{

            state.loading = false;
            state.blogs = payload.blogs;
            state.total = payload.total

            
        },

        getCurrentBlog:(state, {payload}) =>{

            state.loading = false;
            state.currentBlog = payload
            
        },
        getUpdate : state =>{

            state.loading = false
        },

        
        getBlogError:state =>{

            state.loading = false;
            
        }
    }
})

export const {getBlog, getUpdate, getCurrentBlog, getBlogError, getBlogSuccess} = blogSlice.actions;

export const blogSelector = state => state.blogs

export default blogSlice.reducer


const config = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
  };


export const fetchAllBlogs = (skip=0, limit=10, searchword='') => async dispatch =>{

    dispatch(getBlog())

try {

    const {data} = await axios.get(keyUri + `/api/blogs?skip=${skip}&limit=${limit}&search=${searchword}`);

    data && dispatch(getBlogSuccess(data))

} catch (error) {
    
    dispatch(getBlogError())

}
}

export const fetchCurrentBlog = (id) => async dispatch =>{

    dispatch(getBlog())
    
    try {
    
        const {data} = await axios.get(keyUri + `/api/blogs/${id}`);
        console.log(data)

    dispatch(getCurrentBlog(data))
    
    } catch (error) {

    dispatch(getBlogError())

    }      
}


export const createBlog = (blog) => async dispatch =>{

    
    const key = 'create';
    dispatch(getBlog());

    message.loading({ content: 'loading...', key })

    try {
        
        const {data} = await axios.post(keyUri + '/api/blog', blog, config)

         data && message.success({ content: data.message, key, duration: 2 });
      

    } catch ({response}) {

        dispatch(getBlogError());
        response.data && message.error({ content: response.data.message, key, duration: 2 });
    }

}

export const deleteBlog = (id) => async dispatch =>{

    const key = 'delete';

    dispatch(getBlog());
    message.loading({ content: 'Deleting...', key })
    try {
        
             const {data} = await axios.delete(keyUri + `/api/blog/${id}`)
        
             dispatch(fetchAllBlogs())

        setTimeout(() => {

            message.success({ content: data.success, key, duration: 2 });
          }, 200)

         

    } catch (error) {

        dispatch(getBlogError());
    }


}


export const updateBlog = (id, blogupdate) => async dispatch =>{

    const key = 'update';

    dispatch(getBlog());
    message.loading({ content: 'updating...', key })
    try {
        
             const {data} = await axios.put(keyUri + `/api/blog/${id}`, blogupdate, config)
         dispatch(getUpdate()) 
           
        setTimeout(() => {

            message.success({ content: data.success, key, duration: 2 });
          }, 200)

         

    } catch (error) {

        dispatch(getBlogError());
    }


}

  