import React, {useEffect,useState} from 'react'
import {fetchJournal, journalSelector} from '../../../Api/journalSlice'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../../Shared/spin'
import Datatable from './datatable';
import {Button,Pagination} from 'antd'
import {Link} from 'react-router-dom'
import {PlusOutlined} from '@ant-design/icons'
export default function Journal() {
  const [curentpage, setCurrentPage] = useState(1)

  const dispatch = useDispatch();

  const {journal,total, loading} = useSelector(journalSelector)

   console.log(journal);

  useEffect(()=>{

    dispatch(fetchJournal())


 }, [dispatch])
 const  pagefunc = (skip, limit) =>{

  let p = (skip-1)*10
  dispatch(fetchJournal(p, limit))
setCurrentPage(skip)

  }


    return (

    
        <div>

<div className="d-flex  mb-4 align-items-center justify-content-between">
    <h5>Journal</h5>
    <Link to="/admin/journal/create">
      <Button type="ghost" icon={<PlusOutlined style={{transform:'translateY(-3px)'}}/>}>
            Create journal</Button></Link>
</div>

          {
            loading? <Loader/> : <Datatable data={journal}/>
          }
            <div style={{transform:"translateY(-5rem)"}} className=" pb-4 d-flex justify-content-end align-items-center">
{!loading && <Pagination onChange={pagefunc} defaultCurrent={curentpage} total={parseInt(total)} />}

</div>
      
        </div>
    )
}
