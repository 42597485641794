import React, {useEffect} from 'react'
import Header from './header'
import Learn from './headingSec'
import ReadMore from './readmore'
import {useSelector, useDispatch} from 'react-redux'
import { fetchEvents, eventsSelector } from '../../Api/eventSlice'
import {newsSelector, fetchNews} from '../../Api/newsSlice'
import News from './latestConf'
import Events from './events'
import RecentArticle from './recentArticle'
import { isPublicP } from '../../Api/layoutSlice'
import  { blogSelector, fetchAllBlogs } from '../../Api/blogSlice'
import Testmonial from '../About/boardDirectors/testimonial'
import DonateNow from './donateNow'
import Mcards from './mcards'
export default function Home() {

    const dispatch = useDispatch()
    const events = useSelector(eventsSelector)
    const {blogs, loading} = useSelector(blogSelector)
    const {news} = useSelector(newsSelector)

    useEffect(()=>{

        dispatch(fetchEvents(0, 3, ''))
        dispatch(fetchAllBlogs(0, 1, ''))
        dispatch(fetchNews(0, 1, ''))

            dispatch(isPublicP())

    },[dispatch])

    return (
        <>
        <Header/>
        <div className=" w-100" style={{height:"2rem"}}/>
        {/* <Learn/> */}
        <ReadMore/>
        <Events {...events}/>
        <News data={news[0]}/>
        <DonateNow/>
        <RecentArticle blog={blogs[0]}/>

        {/* <div style={{backgroundColor:"var(--acentColor)"}}>
        <Testmonial/>
        </div> */}
        <Mcards/>
        </>
    )
}
