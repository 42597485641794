import React from 'react'
import { Table,Space } from 'antd';
import {deleteEvent} from '../../../Api/eventSlice'
import {useDispatch} from 'react-redux'
import moment from 'moment'
import DeleteConfirm from '../../../Shared/deleteConfirm'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import Fade from 'react-reveal/Fade'
import {Link} from 'react-router-dom'
import Interweave from 'interweave';
export default function Datatable({events}) {

        const dispatch = useDispatch()


        const confirm = (e, id) => {
          
            dispatch(deleteEvent(id._id, id.title))
           
          }
          
          const cancel = (e) =>{
            return null
          }



    const columns = [
        {
          title: 'title',
          dataIndex: 'title',
          key: 'title',
          render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
        },
        {
          title: 'Created By',
          dataIndex: 'author',
          key: 'author',
        },
        {
          title: 'Event date',
          dataIndex: 'event_date',
          key: 'event_date',
        },
        {
          title: 'Raised Amount',
          dataIndex: 'raised_amt',
          key: 'raised_amt',
        },
        {
         title: 'Target Amount',
          dataIndex: 'target_amt',
          key: 'target_amt',

            
        },
        {
          title: 'publish',
          dataIndex: 'publish',
          key: 'publish',
          render:(isPublish, data) =>{

            return isPublish?
             <small ><b className="text-success">published </b>&nbsp;  {moment(data.createdAt).format("MMM Do YYYY") }</small>:   
             <small>Not yet publish</small>
          }
          
        },
        {
          title: 'thumbnail',
          key: 'image',
          dataIndex: 'image',
          render:(url)=>(

            <img src={url} alt="blogimage" width="30px"/>
          )
        },
        {
          title: 'Action',
          key: 'action',
          render: (id) => {


          return  <Space size="middle">
              
              <h5 className="text-secondary">
               <Link to={`/admin/event/update/${id._id}`}>
                <FaRegEdit/> 
                 </Link> 
                
                
                </h5>
            <h5 className="text-danger">
                <DeleteConfirm confirm={(e)=>confirm(e, id)} title="blog" cancel={cancel} >
                    <FaRegTrashAlt style={{cursor:"pointer"}} />
                </DeleteConfirm>
            </h5>

            </Space>
        },
        },
      ];


    return (
        <Fade>
            <Table pagination={false}  rowKey={record => record._id} columns={columns}  

            expandable={{
                expandedRowRender: record =><div className="px-5">
      
     <Interweave content={record.description} />
      
                </div>,
                rowExpandable: record => record.name !== 'Not Expandable',
              }}

              dataSource={events}
            expandRowByClick={false}
            />
        </Fade>
    )
}
