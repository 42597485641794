import React, {useState} from 'react'
import { Modal } from 'antd';

import { FaRegEdit } from 'react-icons/fa';


  
  export default function ModalSec( {children, mtitle}) {

    const [visible, setVisible ] = useState()

    const showModal = () => {
      
        setVisible(true)
      };
    
      const handleOk = e => {
    
        setVisible(false)

      };
    
      const handleCancel = e => {
   
        setVisible(false)

      };
    

      return (
        <>
        <h5><FaRegEdit style={{cursor:"pointer"}} onClick={showModal}/></h5>
        <Modal
          title={mtitle}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
          width={1000}
        >
        {children}
        </Modal>
      </>
      )
  }
  