import React, {useEffect} from 'react'
import {Row, Col} from 'antd'
import styled from 'styled-components'
import { Image } from 'antd';
import Loader from '../../Shared/spin'
import Interweave from 'interweave'
import AuthorImg from '../../../images/DefaultAvatar.65712475de0674c9f775ae3b64e7c69f.svg'
import { BsTag } from "react-icons/bs";
import  {fetchCurrentBlog, blogSelector} from '../../Api/blogSlice'
import {useDispatch, useSelector} from 'react-redux'
import { useParams} from 'react-router-dom'
import Header from '../../Shared/staticlayout/header';

export default function CurrentBlog() {

const dispatch = useDispatch()
const {currentBlog} = useSelector(blogSelector);
let { id } = useParams();

useEffect(() => {
 
    dispatch(fetchCurrentBlog(id));

}, [dispatch])

return (<>
    
  {currentBlog &&  <Header title={currentBlog.title}     headerImg="https://cdn.pixabay.com/photo/2016/08/16/09/53/international-conference-1597531_960_720.jpg"/> }
        <CurrentBlogWrap className="container  my-5">
            
 {/* {currentBlog && <h2>{currentBlog.title}</h2>} */}

 {!currentBlog && <Loader/>}
{
  currentBlog && <Row>

   <Col span={24} className="pr-4">

  <div className="content my-3">


<Image
   width="100%"
   src={currentBlog.image}
 /><br/>
<div className="author py-3">

<img src={AuthorImg} alt="dfdf" width="50px"/>

<small className="ml-4">{currentBlog.author}</small>
<hr/>
<small className=" pr-3 border-right border-secondary">Event Date &nbsp; :&nbsp; {currentBlog.event_date}</small> 
<small className="pl-3"><BsTag className="mx-2"/>{currentBlog.author}</small>
<hr/>
</div> <Col/>

<Col></Col>
<div className="desc">

    <Interweave content={currentBlog.description}/>   

</div>

  </div>

   </Col>
   {/* <Col span={8} className="pl-2">
''
   </Col> */}

</Row>
}
   
            
        </CurrentBlogWrap>
        </>
    )
}

const  CurrentBlogWrap = styled.div`
small{

    font-size:1rem;
}

.desc{

    color:var(--textColor);
    font-size:1rem;
    line-height:1.9;
    word-spacing:3px;
}

.ant-slider-step{

background: #7bdea340;
height:0.3rem;
}
.ant-slider-track{
background: #309657;
height:0.3rem;


&:hover{
  background-color: #309657 !important;

}

}
.ant-slider-handle{

display:none;

}

`