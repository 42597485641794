import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logo from '../../images/whitelogo1.png'
import {Row, Col} from 'antd'
import {
    FaFacebookSquare, FaYoutube, FaInstagramSquare,
FaTwitterSquare, FaLinkedin
} from 'react-icons/fa'
export default function Footer() {
    return (
        <FooterWrap className="text-center   pt-sm-5">

            <Row  className="container py-3 py-sm-2">

                <Col className="py-3 py-sm-2"  xs={24} md={12} sm={24} lg={6}>
                    <img src={logo} className="d-block m-auto mb-5"  alt="footerlogo"/>
                </Col>
                <Col className="ml-sm-5" xs={12} md={12} sm={24} lg={4}>
                    <div className="text-light">
                    <h4 className="mb-4 text-center">Programs </h4>
                 <ul>
                     <li> <Link to="/medical-conference" >Conferences</Link></li>
                     <li> <Link to="/community-health-screening" >Screenings</Link></li>

                     <li> <Link to="/cpr-Training" >Trainings</Link></li>


                 </ul>
                </div>

                </Col>
                <Col xs={12} md={12} sm={24} lg={4}>


                <div className="text-light">
                    <h4 className="mb-4 text-center">About us </h4>
                 <ul>
                     <li> <Link to="/news-&-events" >News & Events</Link></li>
                     <li> <Link to="/board-of-directors" >Board of Directors</Link></li>

                     <li> <Link to="/history" >History</Link></li>


                 </ul>
                </div>


                </Col>
                <Col xs={12} md={12} sm={24} lg={4}>


                <div className="text-light">
                    <h4 className="mb-4 text-center">Education </h4>
                 <ul>
                     <li> <Link to="/library" >Library</Link></li>
                     <li> <Link to="/research" >Research</Link></li>

                     <li> <Link to="/ask-an-expert" >Ask an expert</Link></li>


                 </ul>
                </div>



                </Col>
                <Col xs={12} md={12} sm={24} lg={4}>



                <div className="text-light">
                    <h4 className="mb-4 text-center">Get in touch </h4>
                 <ul>
                     <li> <Link to="/volunteer-with-us" >Volunteer with us</Link></li>
                     <li> <Link to="/share-a-story" >Share a story</Link></li>

                     <li >
                        <div className="px-3 mt-3 d-flex justify-content-around align-items-center">
                         <a target="_tab" href="https://www.facebook.com/twinepidemic">
                            <FaFacebookSquare/>
                             </a>
                        <a target="_tab" href="https://www.youtube.com/channel/UCbn08XMNuXEB9UqbZFm455g
" >
                            <FaYoutube/>
                            </a> 
                         {/* <FaInstagramSquare/>
                         <FaTwitterSquare/> */}
                <a target="_tab" href="https://www.linkedin.com/company/twinepidemic/">

           <FaLinkedin/>              
     </a>

                    
                        </div>
                     </li>

                 </ul>
                </div>



                </Col>

            </Row>

            <div style={{backgroundColor:"rgb(35 43 101)"}} className="pt-3 mt-3 pb-3">
               <a target="new_tab" href="https://brewmybrand.com" className="text-white">Designed by Brewmybrand </a> 
            </div>
        </FooterWrap>
    )
}

const FooterWrap = styled.div`

background-color:var(--acentColor);
color:white;

h4{

    color:white;
}
ul {

    margin:0;
    padding:0;
    li {

        list-style:none;
        a {

            color:white !important;
            line-height:2;
            font-size:1rem; 
                   text-align:center;

        }

        svg {

            font-size:1.3rem;
        }
    }
}

img{

width:80%;
}
@media(max-width:760px){

    img{

width:70%;

}
    h4{

        font-size:18px;
    }
}
ul> li > a{

    font-size:0.8rem;
}
`