import React from 'react'
import styled from 'styled-components'
import aedImg from '../../../images/cpr/AED 2 ucm_489136.jpg'
import two_Img from '../../../images/cpr/2 Steps To Save a Life UCM_487476.jpg'
import KidsImg from '../../../images/cpr/Kids CPR with breaths.jpg'
export default function CprPerform() {
    return (
        <CprrPWrap>
<div>
<div className="row bg-light">
    <div className="col-sm-6">
        <img src={aedImg} alt='hhh' width="100%"/>
    </div>
    <div className="col-sm-6 p-4" style={{color:"var(--acentColor)"}}>
        <h4>Automated External Defibrillators(AED)</h4>
        <p className="mt-5">AEDs can greatly increase a cardiac arrest victim's chances of survival. The AHA offers a two-page guide on how to implement an AED program at a company or organization. To minimize the time to defibrillation for cardiac arrest victims, deployment of AEDs should not be limited to only trained people (although training is still recommended).</p>
    </div>

</div>
<div>
<div className="mt-5">
           <h4>How is CPR Performed?</h4> 
           <p>There are two commonly known versions of CPR:</p>

           <ul>

               <li>For healthcare providers and those trained: conventional CPR using chest compressions and mouth-to-mouth breathing at a ratio of 30:2 compressions-to-breaths. In adult victims of cardiac arrest, it is reasonable for rescuers to perform chest compressions at a rate of 100 to 120/min and to a depth of at least 2 inches (5 cm) for an average adult, while avoiding excessive chest compression depths (greater than 2.4 inches [6 cm]</li>
          
                <li>For the general public or bystanders who witness an adult suddenly collapse: compression-only CPR, or Hands-Only CPR. Hands-Only CPR is CPR without mouth-to-mouth breaths. It is recommended for use by people who see a teen or adult suddenly collapse in an out-of-hospital setting (such as at home, at work, or in a park). </li>

           </ul></div>

           
<div className="row bg-light">
    <div className="col-sm-6">
        <img src={two_Img} alt='hhh' width="100%"/>
    </div>
    <div className="col-sm-6 p-4" style={{color:"var(--acentColor)"}}>
        <h4>Hands-Only CPRconsists of two easy steps:</h4>

<ul>

    <li>Call 9-1-1 (or send someone to do that)</li>
    <li>Push hard and fast in the center of the chest</li>

</ul>

    </div>

</div>
</div>


<div>
<div className="mt-5">
           <h4>About High-Quality CPR?</h4> 
           <p>High-quality CPR should be performed by anyone - including bystanders. There are five critical components:</p>

           <ul>

               <li>Minimize interruptions in chest compressions</li>
          
                <li>Provide compressions of adequate rate and depth </li>
 <li>Avoid leaning on the victim between compressions</li>
          
                <li>Ensure proper hand placement</li>
                <li>Avoid excessive ventilation</li>

           </ul></div>

           

</div>
<div className="border2 px-4 py-3 my-4">
<div className="row">

<div className="col-sm-6">

<h4>The 6 links in the adult out-of-hospital Chain of Survival are:</h4>
<ul>
<li>Recognition of cardiac arrest and activation of the emergency response system (calling 9-1-1 in the US)</li>
<li>Early CPR with an emphasis on chest compressions </li>
<li>Rapid defibrillation</li>
<li>Advanced resuscitation by Emergency Medical Services and other health care providers Post-cardiac arrest care</li>
<li>Recovery (including additional treatment, observation, rehabilitation, and psychological support)</li>

</ul>

<p>A strong Chain of Survival can improve chances of survival and recovery for victims of cardiac arrest.</p>
</div>
<div className="col-sm-6">
<img src={KidsImg} width="100%" alt="imge" />

</div>

</div>
</div>



</div>


        </CprrPWrap>
    )
}

const CprrPWrap = styled.div`

.border2{

    border:5px solid var(--acentColor2);
border-radius:1rem;
color:var(--acentColor);

}


h4{

color:var(--brandColor);
}

p{
color:var(--acentColor);
font-size:1rem;
line-height:1.6;
font-weight:400;

}
ul {

li{
color:var(--acentColor);
font-size:1rem;
line-height:1.7;
font-weight:300;
    
}
}


img {

    height:300px;
    object-fit:cover;
    border-radius:1.5rem;
}

@media(max-width:960px)
{
    h4{
        font-size:1.3rem;
    }
    img {

height:230px;

}
}
`