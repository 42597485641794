import React, {useEffect,useState} from 'react'
import {fetchAllQtn, qtnSelector} from '../../../Api/expertSlice'
import {useDispatch, useSelector} from 'react-redux'
import DataTable from './datatable'
import Loader from '../../../Shared/spin'
import {Pagination} from 'antd'
export default function Expert() {
    const dispatch = useDispatch()
    const [curentpage, setCurrentPage] = useState(1)
    
const {Questions,total, loading} = useSelector(qtnSelector)
    useEffect(()=>{

        dispatch(fetchAllQtn())

    }, [dispatch])
    const  pagefunc = (skip, limit) =>{

        let p = (skip-1)*10
        dispatch(fetchAllQtn(p, limit))
    setCurrentPage(skip)
    
        }


    return (
        <div>
            <div className="d-flex  mb-4 align-items-center justify-content-between">
    <h5>Experts</h5>
   
</div>
            {
                loading? <Loader/> : <DataTable questions={Questions.questions}/>
            }
              <div style={{transform:"translateY(-5rem)"}} className=" pb-4 d-flex justify-content-end align-items-center">
{!loading && <Pagination onChange={pagefunc} defaultCurrent={curentpage} total={parseInt(total)} />}

</div>
        </div>
    )
}
