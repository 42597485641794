import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Form, Input, Button  } from 'antd';
// import Select from '../../../Shared/select'
import { updateStory, fetchCurrentStory, userStorySelector} from '../../../Api/userStorySlice'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import ReactQuill from 'react-quill';
import Loader from '../../../Shared/spin'
import Fade from 'react-reveal/Fade'



const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  

export default function Editstory() {


const { id } = useParams()
const {currentUserStory, loading} = useSelector(userStorySelector)
 const [description, setDescription] = useState('');
 const [form] = Form.useForm();
  const dispatch = useDispatch();
  console.log(currentUserStory)


useEffect(()=>{

dispatch(fetchCurrentStory(id))


}, [dispatch])


useEffect(()=>{

  currentUserStory && setDescription(currentUserStory.description)
     
    form.setFieldsValue({
      title: currentUserStory &&  currentUserStory.title ,
      name:currentUserStory && currentUserStory.name,
      email:currentUserStory && currentUserStory.email,

    });


}, [currentUserStory])



const onFinish = (values) => {


values.description = description

dispatch(updateStory(id, values))



};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};


   
    return (
      loading ? <Loader/> :
      <Fade>
      <CreateBlogWrap className="container ml-3">
            <h5 >Edit Story</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
             <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

    <Form.Item
        label="Enter Story Title"
        name="title"
        rules={[{ required: true, message: ' isRequired!' }]}
      >

        <Input />
      </Form.Item> 
      <Form.Item
        label="Enter name"
        name="name"
        rules={[{ required: true, message: ' isRequired!' }]}
      >

        <Input />
      </Form.Item> 
      <Form.Item
        label="Enter email"
        name="email"
        rules={[{ required: true, message: ' isRequired!' }]}
      >

        <Input />
      </Form.Item> 
      
     

<Form.Item wrapperCol={{ span: 20, offset: 4 }} >

     <ReactQuill theme="snow" value={description} onChange={setDescription}/>
     
</Form.Item>


<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
   
   

    </Form>
        </CreateBlogWrap>
        </Fade>
    )
}


const CreateBlogWrap = styled.div`




`