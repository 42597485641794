import React, {useEffect} from 'react'
import Header from '../../Shared/staticlayout/header'
import Article from './news'
import {useDispatch, useSelector} from 'react-redux'
import {fetchNews, newsSelector} from '../../Api/newsSlice'
import {isPublicP} from '../../Api/layoutSlice'
import bgimg from '../../../images/bannerimages/news and events (1).jpg'
export default function Index() {

    const dispatch = useDispatch()
    const news = useSelector(newsSelector)

    useEffect(()=>{

        dispatch(fetchNews())
        dispatch(isPublicP())


    }, [dispatch])

   

    return (
        <div>
            <Header title="NEWS & EVENTS"  headerImg={bgimg}/>
            <Article {...news} />          
        </div>
    )
}
