import React, {useEffect, useState} from 'react'
import {researchSelector, fetchResearch} from '../../Api/researchSlice'
import {useSelector, useDispatch} from 'react-redux'
import Header from '../../Shared/staticlayout/header'
import Loader from '../../Shared/spin'
import ResearchCard from './researchcard'
import { Pagination } from 'antd';
import boardimg from '../../../images/bannerimages/businessman-using-pen-planning-financial-strategy-tablet-with-dashboard (1).jpg'

export default function Research() {

const dispatch = useDispatch()
const {research,total, loading} = useSelector(researchSelector)
const [curentpage, setCurrentPage] = useState(1)
    useEffect(()=>{


    dispatch(fetchResearch())
  


    }, [dispatch])

console.log(total);
  const  pagefunc = (skip, limit) =>{

    let p = (skip-1)*10
    dispatch(fetchResearch(p, limit))
setCurrentPage(skip)

    }


    return (
        <div className="">
            <Header headerImg={boardimg} title="RESEARCH"/>

            {

loading ? <Loader/> : <ResearchCard data={research}/>

            }
<div className="container  pb-4 d-flex justify-content-end align-items-center">
{!loading && <Pagination onChange={pagefunc} defaultCurrent={curentpage} total={parseInt(total)} />}

</div>
        </div>
    )
}
