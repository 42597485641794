import React from 'react'
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';


const { Panel } = Collapse;


export default function Accordian() {
    return (
        <div>
             <Collapse
    bordered={false}
    defaultActiveKey={['1']}
    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    className="site-collapse-custom-collapse"
  >
  
    <Panel header="Does diabetes cause heart disease?" key="2" className="site-collapse-custom-panel">
    <p className="pl-sm-5 pl-2">The high glucose (sugar) levels in the blood of people with diabetes can eventually damage blood vessels as well as the nerves that control them.<br/>

Body tissues typically use sugar as an energy source. It’s stored in the liver as a form of glycogen.<br/>

If you have diabetes, sugar can stay in your bloodstream and leak out of the liver into your blood, with subsequent damage to your blood vessels and the nerves that control them.<br/>

A blocked coronary artery can slow or stop blood from supplying oxygen and nutrients to your heart. The risk of heart disease increases the longer you have diabetes.<br/>

Monitoring blood sugar is an important part of properly managing diabetes. Check levels with a self-monitoring device according to your doctor’s instructions.<br/>

Keep a journal of your levels and bring it to your next medical appointment so that you and your doctor can review it together.<br/>
</p>
    </Panel>




 </Collapse>

<p className="mt-5 mb-2" style={{color:"var(--brandColor)", fontSize:"1rem"}}>
    <i>

    The following are some additional factors that can increase your risk of heart disease if you have diabetes.
    </i>
</p>

 <Collapse
    bordered={false}
    defaultActiveKey={['1']}
    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    className="site-collapse-custom-collapse"
  >
    <Panel header="High blood pressure" key="3" className="site-collapse-custom-panel">
   <p className="pl-sm-5 pl-2"> High blood pressure is one of the most common risk factors for heart disease among people with diabetes.<br/>

It places strain on your heart and damages your blood vessels. This makes you more susceptible to a variety of complications including:<br/><br/>

- heart attack<br/>
- stroke<br/>
- kidney problems<br/>
- vision issues<br/><br/>

If you have both diabetes and high blood pressure, you’re at least twice as likely to develop heart disease as people without diabetes.<br/>

The simplest way to manage your blood pressure is to adopt a healthy diet, exercise regularly, and if needed, take medications as your doctor prescribed.</p>
     </Panel>

    <Panel header="High cholesterol" key="4" className="site-collapse-custom-panel">
    <p className="pl-sm-5 pl-2">  Poorly managed levels of blood fats like cholesterol and triglycerides are common in people with diabetes. They can also increase the risk of developing heart disease.<br/>

Too much LDL (“bad”) cholesterol and not enough HDL (“good”) cholesterol may cause a buildup of fatty plaque in your blood vessels. This can create blockages and lead to having a heart attack or stroke.<br/>

Although in many cases genetics influence cholesterol levels, you can still manage and improve your levels by making healthy lifestyle choices and maintaining a regular exercise routine.</p>
     </Panel>

    <Panel header="Obesity" key="5" className="site-collapse-custom-panel">
    <p className="pl-sm-5 pl-2">  People with diabetes are more likely to have overweight or obesity. Both conditions are risk factors for heart disease.<br/>

Obesity has a strong influence on:<br/>

- blood pressure<br/>
- blood sugar<br/>
- cholesterol levels<br/>

Weight loss can reduce the risk of heart disease.<br/>

One of the most effective ways to manage your weight is to work with a dietitian or nutritionist to create a healthy eating plan. Regular exercise also plays an important role in weight management.<br/></p>
    </Panel>


  <Panel header="Sedentary lifestyle" key="6" className="site-collapse-custom-panel">
    <p className="pl-sm-5 pl-2">
    Having a sedentary lifestyle can seriously increase heart disease risk factors like high blood pressure and obesity.<br/>

The Centers for Disease Control and Prevention (CDC)Trusted Source recommends that every adult get at least 2 hours and 30 minutes of moderate intensity aerobic exercise per week.<br/>

Examples include:<br/>

- walking <br/>
- cycling<br/>
- dancing<br/>

The CDC also recommends doing strength-training exercises at least twice a week on nonconsecutive days.<br/>

Talk with your doctor to find out which exercises might be best suited for your fitness needs.<br/>
    </p>

    </Panel>


       
    <Panel header="Smoking" key="7" className="site-collapse-custom-panel">
    <p className="pl-sm-5 pl-2">

    If you have diabetes and you’re a smoker, your risk of developing heart disease is much higher than that of nonsmokers.<br/>

Both cigarette smoke and diabetes create a buildup of plaque in the arteries, which causes them to narrow.<br/>

This can result in a variety of complications, ranging from heart attack and stroke to foot problems. In severe cases, foot problems can even lead to amputation.<br/>

Remember that it’s never too late to quit. Ask your doctor about which smoking cessation methods might work best for you.<br/>
    </p>

    </Panel>

       
    <Panel header="Symptoms" key="8" className="site-collapse-custom-panel">
    <p className="pl-sm-5 pl-2">
    The symptoms of heart disease may vary based on its severity. Some people experience no symptoms at all. These are some of the most common symptoms:<br/>

- pressure, tightness, or pain in your chest behind the breastbone that may spread to your arms, neck, or back<br/>
- shortness of breath<br/>
- fatigue<br/>
- feeling dizzy or weak<br/>
    </p>

    </Panel>

       
    <Panel header="Diet" key="9" className="site-collapse-custom-panel">
    <p className="pl-sm-5 pl-2">
    To help prevent heart disease if you have diabetes, try to follow a heart-healthy diet, which can help reduce your overall cholesterol and blood pressure, among other benefits. Examples of heart-healthy foods include:<br/>

- leafy greens like spinach and kale<br/>
- cold-water fish, such as salmon and sardines<br/>
- almonds, pecans and other nuts<br/>
- whole grains and oats<br/><br/>

Try to limit your intake of:<br/><br/>

- sodium<br/>
- sugar<br/>
- trans fat<br/>
- saturated fats<br/>
<br/>
Always try to opt for low fat options in grocery stores or at restaurants.<br/>
    </p>

    </Panel>

       
    <Panel header="Statistics" key="10" className="site-collapse-custom-panel">
    <p className="pl-sm-5 pl-2">
    Death due to cardiovascular disease is 70 percent higher in adults with diabetes. Trusted Source than in those without it, the CDC reports.<br/>
About 32 percent of people with type 2 diabetes have heart disease, according to a 2017 study.<br/>

At least 68 percent of people with diabetes ages 65 and older will die from some form of heart disease, according to the American Heart Association.<br/>

People under the age of 65 with diabetes also have a significantly higher risk of:<br/><br/>

- heart attack<br/>
- stroke<br/>
- kidney disease<br/><br/>
    </p>

    </Panel>

       
    <Panel header="Prevention" key="11" className="site-collapse-custom-panel">
    <p className="pl-sm-5 pl-2">
    There are ways to help prevent heart disease if you have diabetes.<br/>

To do this, the National Institute of Diabetes and Digestive and Kidney Diseases recommends the manage your diabetes “ABCs”:<br/><br/>

- A1C test. This blood test shows your average blood glucose level during the past 3 months. For most people with diabetes, the result should be below 7 percent.<br/>
- Blood pressure. The blood pressure goal for many people with diabetes is below 140/90 mm Hg.<br/>
- Cholesterol. Too much LDL (“bad”) cholesterol in your blood can cause blockages in your blood vessels. Ask your doctor what your cholesterol level should be.<br/>
- Smoking. Along with diabetes, smoking narrows your blood vessels. If you stop smoking, you’ll lower your risk of heart disease as well as heart attack, stroke, and other health issues.<br/>
    </p>

    </Panel>

       
    <Panel header="Treatment of heart disease in diabetes" key="12" className="site-collapse-custom-panel">
    <p className="pl-sm-5 pl-2">

    In addition to recommended that you eat a healthy diet and get regular exercise, your doctor may prescribe medications to treat heart disease if you have diabetes.<br/>

Talk to your doctor before taking over-the-counter medications to treat heart disease.<br/>

Some may interact with your diabetes medication, or they may contain sugar and other carbohydrates that can affect your blood sugar level.<br/>

The following are examples of medications your doctor may prescribe:<br/>

- Liraglutide or Semaglutide or Dulaglutide is administered as an injection. In 2017, the Food and Drug Administration (FDA) approved the drug for reducing the risk of heart attacks and strokes in adults with type 2 diabetes and heart disease.<br/>
- Empagliflozin , Dapagliflozin and Canagliflozin are approved for prevention of heart disease in diabetics as well as for for lowering blood<br/>
- Statins. Statins, such as atorvastatin (Lipitor) and rosuvastatin (Crestor), reduce cholesterol levels, especially LDL (“bad”) cholesterol.<br/>
- Antihypertensives. Antihypertensives, including diuretics and beta-blockers, lower blood pressure.<br/>
    </p>

    </Panel>

       
    <Panel header="Other cardiovascular complications" key="13" className="site-collapse-custom-panel">
    <p className="pl-sm-5 pl-2">
    If you have diabetes and untreated heart disease, you could have serious complications such as:<br/><br/>

- heart failure<br/>
- heart attack<br/>
- stroke<br/>
    </p>

    </Panel>

       
    <Panel header="Heart attack" key="14" className="site-collapse-custom-panel">
    <p className="pl-sm-5 pl-2">
    You can have a heart attack if part of your heart muscle isn’t getting enough blood due to diabetes damaging the vessels.<br/>

After experiencing a heart attack, people with diabetes have a greater risk of heart failure than people who don’t have diabetes.<br/>

The symptoms of a heart attack may include the following:<br/><br/>

- chest pain or discomfort<br/>
- weakness or lightheadedness<br/>
- pain or discomfort in your arms, shoulders, back, neck, or jaw<br/>
- nausea or vomiting and being unusually tired, which is seen especially in women experiencing a heart attack<br/><br/>

If you’re experiencing these symptoms, call 911 right away.<br/>

If you have diabetes, the excess sugar in your blood may eventually block your blood vessels, preventing blood from reaching your brain. This can cause a stroke.<br/>

People with diabetes are 1.5 times more likely to have a stroke than those without diabetes.<br/>

The risk factors for heart disease and stroke are similar. Those factors include having:<br/>

- high LDL (“bad”) and low HDL (“good”) cholesterol levels<br/>
- high blood pressure<br/>
- obesity<br/><br/>

The following are some symptoms you may suddenly experience if you’re having a stroke:<br/><br/>

- numbness in your face, arm or leg, usually on one side of your body<br/>
- difficulty speaking or understanding another person speaking<br/>
- dizziness<br/>
- vision problems in one or both eyes<br/>
- severe headache
<br/>
Call 911 immediately if you experience any of these symptoms. Successful treatments usually work only up to 3 hours after a stroke occurs.<br/>
    </p>

    </Panel>

       
    <Panel header="Heart failure" key="15" className="site-collapse-custom-panel">
  <p className="pl-sm-5 pl-2">

  People with diabetes have a greater risk of developing heart failure, which is caused by the heart’s inability to pump enough blood to the body. Heart failure is one of the most serious cardiovascular complications of diabetes.

These are some of the symptoms of heart failure:<br/>
<br/>

- shortness of breath<br/>
- coughing and wheezing<br/>
- swollen legs, feet, and ankles<br/>
- fatigue<br/>
<br/>
See your doctor if you have these symptoms. Although heart failure can’t be cured, it can be successfully treated with medications or surgery.

  </p>
    </Panel>

       
    <Panel header="When to see a doctor" key="16" className="site-collapse-custom-panel">
  <p className="pl-sm-5 pl-2">
  If you have diabetes and are experiencing heart disease symptoms such as pain or pressure in your chest, shortness of breath, or fatigue, you should see your doctor right away.<br/>

They may recommend making lifestyle changes and eating a healthy diet. They may also prescribe medications. These recommendations could save your life.<br/>

Now that you have a better understanding of the connection between heart disease and diabetes, it’s time to take action.<br/>

Whenever possible, eat healthy, stay active, and do your best to manage your blood pressure, blood sugar, and cholesterol levels.<br/>

Having diabetes doesn’t mean you’ll also develop other conditions, such as heart disease.<br/>

You have the power to manage your own risk factors and improve your heart health through lifestyle changes and working with your doctor to create a treatment plan that’s right for you.<br/>

For more details and specifics, one can refer to a book called “ Invoking your Inner Therapist in Heart Failure : Untold patient experiences, From Prevention to End stage” which will help in the understanding of risk factors, how to prevent heart disease, diabetes and other associated complications .
  </p>
    </Panel>





  </Collapse>
        </div>
    )
}
