import React from 'react'
import Header from '../../Shared/staticlayout/header'
import Intro from '../../Shared/staticlayout/introduction'
import imge from '../../../images/family-autumn-park-coronavirus-theme-mother-with-daughter (1).jpg'
import introimg from '../../../images/introimages/285.jpg'
import Ccontent from './covid'
export default function Covid() {


    let introData = {

        introtitle:"2019–20 Coronavirus Pandemic",
        text:"The  2019–20  coronavirus  pandemic  is  a  pandemic  of  coronavirus  disease  2019  (COVID-19)  caused  by  the  severe   acute   respiratory   syndrome   coronavirus   2   (SARS-CoV-2).   The   disease   was   first   identified   in   Wuhan, Hubei, China in December 2019",
        imge: introimg,
        tcolor:"var(--brandColor)",
        pcolor:"var(--acentColor)"

    
    }
    

    return (
        <div>
            <Header headerImg={imge} title="Covid19"/>
            <div className="mb-5">
            <Intro data={introData} />
            <Ccontent/>
            </div>
        </div>
    )
}
