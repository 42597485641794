import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
export default function Mcards() {

    let data = [{

        title:"DONATE",
        desc:"Your support is critical",
        link:'/donate'
        
    },
    {

        title:"SHARE YOUR STORY",
        desc:"Your Experience can guide others",
        link:'/share-your-story'
        
    },
    {

        title:"VOLUNTEER",
        desc:"You are valuable to our cause",
        link:'/volunteer'
        
    }]

    return (
        <McardsWrap className="container my-5">

            <div className="row">
                {

                    data.map((item, i)=>{

                        return     <div key={i} className="col-sm-4 ">
                            <div className="text-center shadow p-4 rounded">

<h4 style={{color:"var(--brandColor)"}}>{item.title}</h4>
<p style={{color:"var(--acentColor)", fontSize:"1.1rem"}}>{item.desc}</p>
<Button  type="primary">
    <Link to={item.link}>Know More</Link></Button>
</div>
                        </div>

                    })
                }
                <div className="col-sm-4"></div>
            </div>
            
        </McardsWrap>
    )
}


const McardsWrap = styled.div`

@media(max-width:780px){


    h4{

        font-size:1.2rem;
    }

    p{

         font-size:1rem;
    }
}

`