import React, {useEffect, useState} from 'react'
import {dycSelector, fetchAllDYB} from '../../Api/dailybriefing'
import {useSelector, useDispatch} from 'react-redux'
import Header from '../../Shared/staticlayout/header'
import Loader from '../../Shared/spin'
import DailyBrief from './dailybrief'
import { Pagination } from 'antd';
import headerImg from '../../../images/bannerimages/dailyBreifing.jpg'
export default function Daily() {

const dispatch = useDispatch()
const {dailyBriefings,total, loading} = useSelector(dycSelector)
const [curentpage, setCurrentPage] = useState(1)
    useEffect(()=>{


    dispatch(fetchAllDYB())
  


    }, [dispatch])


  const  pagefunc = (skip, limit) =>{

    let p = (skip-1)*10
    dispatch(fetchAllDYB(p, limit))
setCurrentPage(skip)

    }


    return (
        <div >
            <Header 
            title="Daily Briefing"
            headerImg={headerImg}/>

            {

loading ? <Loader/> : <DailyBrief data={dailyBriefings}/>

            }
<div className="container pb-4 d-flex justify-content-end align-items-center">
{!loading && <Pagination onChange={pagefunc} defaultCurrent={curentpage} total={parseInt(total)} />}

</div>
        </div>
    )
}
