import React from 'react'
import styled from 'styled-components'
export default function Covid() {
    return (
        <CWrap className="container mb-5">
            <p>

            <span >Disease:</span> &nbsp;   Coronavirus disease 2019 (COVID-19)Virus strain: Severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2)<br/>
            <span>First case:</span>&nbsp;   December 1, 2019<br/>
              <span>Origin:</span> &nbsp;   Wuhan, Hubei, China<br/>

              <span>Symptoms:</span>    Symptoms may appear 2-14 days after exposure to the virus.  <br/>     
                                People with these symptoms may have COVID-19: Cough, <br/>      
                                      shortness of breath or difficulty breathing, fever, chills, muscle pain,<br/>
                                      sore throat, new loss of taste or smell.<br/>
               <span>Incubation period:</span>&nbsp;  1-14 days<br/>
               <span>Mode of transmission:</span>&nbsp;   Human-to-human transmission via respiratory droplets<br/>
               <span>Prevention tips :</span> &nbsp;   Avoiding close contact with sick individuals;  frequently washing      <br/>              
                                hands with soap and water; not touching the eyes, nose, or mouth <br/>
                                               with unwashed hands; and practicing good respiratory hygiene<br/>
                <span>Research :</span>&nbsp;   COVID-19 Open Research Dataset (CORD-19)<br/>
            </p>

            <div className="content mb-4">

                <h4>COVID & The Heart</h4>

                <p>Covid-19 is a coronavirus in 2019 also called SARS-COV-2 as it is similar to the coronavirus of SARS that occurred many years ago. When an infected person expels the virus in droplets, and someone else inhales it, it can start to spread.  The Virus has multiple spikes called glycoproteins which try to attach to a receptor on the cell in human tissues. The specific receptor that COVD 19 attempts to attach to is called ACE2 receptor. It is commonly found in the lungs. It is also found in the endothelium, heart, intestines, and kidney. Once SARS COV2 attaches to the ACE 2 receptor, it enters the cell and starts to replicate rapidly. It then gets released into the other tissues and starts to create an inflammatory reaction by upregulating many interleukins and cytokines.
                   <br/> <br/>If a cytokine storm ensues, the patient decompensates and needs aggressive management and support. As the virus multiplies, the in-fected person releases copious amounts of it in the first week or two. They may have minor symptoms such as cough, loss of taste, fever, sore throat,  or possibly none at all. If the immune system does not combat this virus, then it can march down to the windpipe and attack the bronchial tree which will develop pneumonia and fill the lungs with a fluid leading to a condition called acute respiratory distress syn-drome (ARDS). About 5% of the people have severe disease and this has been leading to significant amounts of comorbidity and mortali-ty around the world.
                   
                  <br/> <br/> COVID-2 seems to affect the heart in multiple different ways. It appears as though it may cause arrhythmia with ventricular tachycardia, arterial/venous clots, cytokine storm, and acute myocarditis which can lead to heart failure. At present, there have been diagnoses avail-able with a nasal swab and testing for the virus with reverse transcriptase PCR. There is also antibody testing available which detects IgM antibody present soon after the viral load starts to wean off and an IgG antibody that starts to increase and sustain over a longer time. Many of the questions about immunity, recurrence, neutralizing antibody, length of protection are all unclear. Nasal swabs test for the virus is the most effective at the moment due to its high level of specificity and sensitivity.
                  
                  
                 <br/> <br/> In terms of therapy, there are not many therapies that have shown significant benefit in suppressing the disease rapidly. Remdesevir, an antiviral is approved for use for ICU patients to reduce length of stay in the ICU. Dexamethasone is also approved for severely sick pa-tients. No treatment is available yet for mild disease or prophylaxis. No vaccine is available yet. Many clinical trials are ongoing.  In terms of prevention, the national strategy includes social distancing, hand washing, quarantining, restrictive access to hospitals, use of hand sanitizer, risk screenings, respiratory masks, PPE, and hospital evaluations in isolated rooms.</p>
            </div>
        </CWrap>
    )
}


const CWrap = styled.div`

.content{
p{

  font-size: 16px;
    color:var(--acentColor); 
    line-height:1.7;
    font-weight:500;
  
}

h4{
    color:var(--brandColor);

    
}
    


}


span{

    color:var(--brandColor);
}

p{

color:var(--acentColor);
font-size:20px;
line-height:1.8;
}

@media(max-width:960px)
{
  p {
    font-size: 1rem;
   } 
  span{
      font-size:1.2rem;
  }
  h4{
      font-size:1.3rem;
  }
}
`