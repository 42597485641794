import React from 'react'
import InterWeave from 'interweave'
import moment from 'moment'
import { Image } from 'antd';
import loading from '../../../images/loading.gif'
import {Row, Col, Button} from 'antd'


export default function JournalSec({data}) {
    return (

        data.map((item, i)=>{

            return <div key={i} className="container py-2 my-3 ">
            
            <Row  style={{transform:"translateY(-3rem)",  borderRadius:"0.5rem"}}>

        
                <Col span={24}>
                
                <div className="mt-4 ml-2" >
           
           <small> {item.author} &nbsp; on &nbsp; {moment(item.createdAt).format('MMM Do YYYY')}</small>
           <h2 style={{color:"var(--acentColor)",  fontFamily:"var(--headingFont)"}}>{item.research_title}</h2>

         <InterWeave  content={item.description}/>

            <Button className="shadow-sm" style={{backgroundColor:"var(--acentColor)", border:"none", borderRadius:"5px"}} type="primary"><a href={`${item.extra_link}`}>Read More</a></Button>

       </div>
                
                
                              
                </Col>

            </Row>
            
            </div>
            
      
        })
      
    )
}
