import React  from 'react'
import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

export default function Imageupload({click, imge, err}) {


    return (
     
        <div >
    <Button icon={<UploadOutlined />}  onClick={(e)=>click(e)}>Upload Image</Button>{err && <span className="ml-4 text-danger">{err}</span>}
       
       <div className=" mb-3">
        { imge &&  <img src={imge} className=" bg-light mt-3 shadow-sm" alt="uploadImage" width="150px"/> }
       
       
       
        </div>

        </div>     
    
    )
}


