import React from 'react'
import Header from '../../../Shared/staticlayout/header'
// import Goals from './ourGoals'
import Team from './ourTeam'
import Colobration from './ourColobration'
import Testimonial from './testimonial'
import OtherBoard from './otherBoard'
import AdviseryBoard from './advisoryMembers'
import boardimg from '../../../../images/bannerimages/medical-team-doctor-s-office (1).jpg'
import Goals from '../../home/learn'

export default function About() {
    return (
        <div >
            <Header headerImg={boardimg} title="BOARD OF DIRECTORS"/>
            <div className="container mb-5" >
           <Goals/>
           
           <Team/> 
        
           </div>

           <div className="container-fluid py-4 bg-light">
         <OtherBoard/>
    
           </div>

        <AdviseryBoard/>

          {/* <div style={{backgroundColor:"var(--acentColor)"}}>
           <Testimonial/>
           </div> */}
           <div className="container py-4">

           <Colobration/>

           </div>
        </div>
    )
}
