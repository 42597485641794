import React, {useEffect,useState} from 'react'
import {fetchUserStory, userStorySelector} from '../../../Api/userStorySlice'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../../Shared/spin'
import Datatable from './datatable'
import {Pagination} from 'antd'
import {Link} from 'react-router-dom'

export default function Story() {
  const [curentpage, setCurrentPage] = useState(1)
  
  const dispatch = useDispatch();

  const {userStory,total, loading} = useSelector(userStorySelector)


  
  useEffect(()=>{

    dispatch(fetchUserStory())


 }, [dispatch])
 const  pagefunc = (skip, limit) =>{

  let p = (skip-1)*10
  dispatch(fetchUserStory(p, limit))
setCurrentPage(skip)

  }


    return (


        <div>

<div className="d-flex  mb-4 align-items-center justify-content-between">
    <h5>UserStory</h5>
    <Link to="/admin/userStory/create">
      {/* <Button type="ghost" icon={<PlusOutlined style={{transform:'translateY(-3px)'}}/>}>
            Create volunteer</Button> */}
            </Link>
</div>

           {
            loading? <Loader/> : <Datatable data={userStory}/>
          }
         <div style={{transform:"translateY(-5rem)"}} className=" pb-4 d-flex justify-content-end align-items-center">
{!loading && <Pagination onChange={pagefunc} defaultCurrent={curentpage} total={parseInt(total)} />}

</div>
        </div>
    )
}
