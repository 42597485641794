import React, { useEffect } from 'react'
import { Form, Input, Button } from 'antd';
import styled from 'styled-components'
import {userStorySelector, fetchUserStory,  isSuccessOK} from '../../Api/userStorySlice'
import {useDispatch, useSelector} from 'react-redux'
import logo from '../../../images/logo icon-06.png'
import { Modal } from 'antd';



const layout = {
    labelCol: {
      span: 0,
    },
    wrapperCol: {
      span: 24,
    },
  };



export default function StoryForm() {

const dispatch = useDispatch()
    const {isSuccess, loading} = useSelector(userStorySelector)


    useEffect(()=>{

      dispatch(isSuccessOK())

    }, [])

    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log('Success:', values);
        dispatch(fetchUserStory(values))
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

  const cancel = () =>{

dispatch(isSuccessOK())
form.resetFields();
    
  }
   

    return (
        <StoryWrap className="container mb-5" >

{/* <h1 className="text-center" style={{color:"var(--acentColor)"}}>SHARE YOUR STORY</h1> */}
          <p className="text-center" style={{color:"var(--acentColor)", fontSize:"18px"}}>Dr. Vijay is a Medical Sherpa who takes a holistic view of heart failure, using a variety of treatment options along with motivating, inspiring, and coaching to encourage patients to modify their lifestyles. His mission has been in the prevention of risk factors of heart disease and diabetes at primordial, primary, secondary, and tertiary care levels. Prevention of Heart Failure has been his main goal. In addition to spending countless hours caring for patients, he has dedicated himself to improving medical education, research, and the overall quality of care. His special brand of care is making a positive and measurable difference in keeping patients healthy, and importantly, out of the hospital. He volunteers his services to serve the underserved in attempts to mitigate diabetes, heart disease, and kidney disease around the world through his nonprofit organization, Twinepidemic, Inc</p>
            <br/>
                <Form
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
        prefix: '1'
      }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

<Form.Item
      
      name="title"
      rules={[
        {
          required: true,
          message: 'required!',
        },
      ]}
    >
      <Input  placeholder="Enter  title"/>
    </Form.Item>

      <Form.Item
      
        name="name"
        rules={[
          {
            required: true,
            message: 'required!',
          },
        ]}
      >
        <Input  placeholder="Enter  Name"/>
      </Form.Item>

   


      <Form.Item
     
        name="email"
        rules={[
            {
              type: 'email',
            },
          ]}
      >
        <Input email="Enter Email" placeholder = " Enter Email" />

      </Form.Item>

      <Form.Item  name="description" >
        <Input.TextArea rows={8}  placeholder="short note"/>
      </Form.Item>

      <Form.Item >
        <Button type="primary" className="cstbtn" style={{backgroundColor:"var(--brandColor)"}} htmlType="submit">
        {loading? "Loading..." : "Submit"}  
        </Button>
      </Form.Item>
    </Form>

      <Modal className="p-4"  width={800} footer={false} onCancel = {cancel} visible={isSuccess}>
      <img src={logo} width="120px" className="d-block mx-auto"/>
       <h2 className="text-center" style={{color:"var(--brandColor)"}}>Thanks for sharing  <br/> your experience !</h2>
      <p className="text-center text-secondary" style={{fontSize:"20px"}}>We would like to use your experience to share knowledge to people, we promise to not give away any of your personal information.</p>
 <hr   style={{height:"5px", width:"20%", backgroundColor:"var(--acentColor)" }}/>
    
      </Modal>
        </StoryWrap>
    )
}


const StoryWrap = styled.div`


.ant-input {

    font-size: 20px;
    box-shadow: 2px 2px 3px 1px #dedede;
    border: 0.3px solid #fbfbfb;
    border-radius: 0 0 10px 10px;
    padding:0.5rem 1rem;
}

.ant-input-group-addon{

   font-size: 20px;
    box-shadow: 2px 2px 3px 1px #dedede;
    border: 0.3px solid #fbfbfb;
    border-radius: 10px 0 0 10px;
    background-color:white;

}

.cstbtn{

    padding: 0.5rem 3rem !important;
    border: none;
    height: 100%;
    border-radius: 4px;
    font-size: 18px !important;
    font-weight: 500;
    float:right;
    box-shadow: 1px 1px 5px 0px #5a5858;
}

.ant-select-single .ant-select-selector .ant-select-selection-item{

    line-height: 40px;
    color: grey;
    font-weight: 500;

}


@media(max-width:760px) {


    .ant-input {

font-size: 14px;

}

.ant-input-group-addon{

font-size: 14px;


}

h1{

    font-size:1.8rem;
}

}
`