import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Form, Input, Button,DatePicker } from 'antd';
// import Select from '../../../Shared/select'
import { updateEvent, fetchOneEvent, eventsSelector } from '../../../Api/eventSlice'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import moment from 'moment';
import UploadImage from '../../../Shared/UploadImageCD'
import ReactQuill from 'react-quill';
import Loader from '../../../Shared/spin'
import Fade from 'react-reveal/Fade'
import credential from '../../../../key'



const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
 

export default function UpdateEvent() {


const { id } = useParams()
const {currentEvent, loading} = useSelector(eventsSelector)
  const [description, setDescription] = useState('');
  const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [imge, setImge] = useState(null);
    const [imgerr, setImageErr] = useState();
  




useEffect(()=>{

dispatch(fetchOneEvent(id))


}, [dispatch])


useEffect(()=>{

    currentEvent && setDescription(currentEvent.description)
    currentEvent && setImge(currentEvent.image)
 


     form.setFieldsValue({
      title: currentEvent &&  currentEvent.title ,
      catagory: currentEvent &&  currentEvent.catagory,
      target_amt: currentEvent &&  currentEvent.target_amt,
      event_date: currentEvent &&  currentEvent.event_date,
    });


}, [currentEvent])



const onFinish = (values) => {




values.image = imge
values.description = description

dispatch(updateEvent(id, values))



};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const handleClick = (e) => {

  e.preventDefault();

 window.cloudinary.openUploadWidget({cloud_name:credential.cloud_name, upload_preset:credential.upload_preset},
  function(error, result) {


      if(result){

          setImge(result[0].url) 
          setImageErr(undefined);
      } else{

          setImge('')
          alert(error) 

      }
      
  });
}

const config = {
  rules: [
    {
      type: 'object',
      required: true,
      message: ' Select date!',
    },
  ],
};

const disabledDate =(current)  =>{
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}

      
    return (
      loading ? <Loader/> :
      <Fade>
      <CreateBlogWrap className="container ml-3">
            <h5 >Create Event</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
             <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

    <Form.Item
        label="Enter Event Title"
        name="title"
      
        rules={[{ required: true, message: 'Enter Event Title!' }]}
      >

        <Input />
      </Form.Item> 
      
      
      <Form.Item
        label="Select Catagory"
        name="catagory"
        rules={[{ required: true, message: 'Enter Catagory' }]}
  
     ><Input />
      </Form.Item>

      {/* <Form.Item
        label="Select Event Date"
        name="event_date"
        {...config} >
          <DatePicker   disabledDate={disabledDate}/>
     </Form.Item> */}
      

  
      <Form.Item  wrapperCol={{ span: 20, offset: 4 }}>

<UploadImage click={(e)=>handleClick(e)} imge={imge} err={imgerr}/>

 </Form.Item>

<Form.Item wrapperCol={{ span: 20, offset: 4 }} >

     <ReactQuill theme="snow" value={description} onChange={setDescription}/>
     
</Form.Item>


<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
   
    </Form>
        </CreateBlogWrap>
        </Fade>
    )
}


const CreateBlogWrap = styled.div`


`