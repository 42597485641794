import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { message } from 'antd';
import keyUri from '../../uri'

export const initialState = {

    loading:false,
    hasErrors:false,
    userStory:[],
    currentUserStory:null,
    isSuccess:false,
    total:0


}

export const userStorySlice = createSlice({

    name:"userStory",
    initialState,
    reducers:{

        getUserStory:state =>{

            state.loading = true;

        },
        getUserStorySuccess:(state, {payload}) =>{

            state.loading = false;
            state.userStory = payload.userstory;
            state.total = payload.total;
            state.isSuccess = true
        },

        getAllStory:(state, {payload}) =>{
           
            state.loading = false;
            state.userStory = payload.userstory;
          


        },
        getSuccesOk: state =>{

            state.loading = false
            state.isSuccess = false
        },
        getCurrentUserStory:(state, {payload}) =>{

            state.loading = false;
            state.currentUserStory = payload
            
        },
        getUpdate : state =>{

            state.loading = false
        },

        
        getUserStoryError:state =>{

            state.loading = false;
            
        }
    }
})

export const {getUserStory, getAllStory, getSuccesOk, getUserStorySuccess, getCurrentUserStory, getUpdate, getUserStoryError} = userStorySlice.actions;

export const userStorySelector = state => state.userstory

export default userStorySlice.reducer


const config = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
  };


  export const fetchUserStory = (skip=0, limit=10, searchword='') => async dispatch =>{

    dispatch(getUserStory())


try {

    const {data} = await axios.get(keyUri + `/api/userStory?skip=${skip}&limit=${limit}&search=${searchword}`)
 
    data && dispatch(getUserStorySuccess(data))

} catch (error) {
    
    dispatch(getUserStoryError())


}


}



export const fetchAllStory = (skip=0, limit=10, searchword='') => async dispatch =>{

    dispatch(getUserStory())


try {

    const {data} = await axios.get(keyUri + `/api/userStory?skip=${skip}&limit=${limit}&search=${searchword}`)
 
    data && dispatch(getAllStory(data))

} catch (error) {
    
    dispatch(getUserStoryError())


}


}




export const  isSuccessOK = () =>async dispatch =>{

    dispatch(getSuccesOk())
}

export const storySubmit = (values) => async dispatch =>{

    dispatch(getUserStory())


try {

    const { data } = await axios.post( keyUri +'/api/userStory', values, config);
 

    data && dispatch(getUserStorySuccess(data))

} catch (error) {
    
    dispatch(getUserStoryError())


}
}

export const deleteStory= (id) => async dispatch =>{

    const key = 'delete';

    dispatch(getUserStory());
    message.loading({ content: 'Deleting...', key })
    try {
        
             const {data} = await axios.delete(keyUri + `/api/userStory/${id}`)
        
             dispatch(fetchUserStory())

           data && message.success({ content: data.msg, key, duration: 2 });
           

    } catch (error) {

        dispatch(getUserStoryError());
    }
}


export const updateStory = (id, storyupdate) => async dispatch =>{

    const key = 'update';

    dispatch(getUserStory());
    message.loading({ content: 'updating...', key })
    try {
        
             const {data} = await axios.put( keyUri +`/api/userStory/${id}`, storyupdate, config)
             console.log(data)
         dispatch(getUpdate()) 
           
         data &&  message.success({ content: data.msg, key, duration: 2 });

         

    } catch (error) {

        dispatch(getUserStoryError());
    }
}


export const fetchCurrentStory = (id) => async dispatch =>{

    dispatch(getUserStory())
    
    try {
    
        const {data} = await axios.get(keyUri + `/api/userStory/${id}`);
        console.log(data)
  data &&  dispatch(getCurrentUserStory(data))
    
    } catch (error) {

    dispatch(getUserStoryError())

    }

    }