import React, {useEffect, useState} from 'react'
import {blogSelector, fetchAllBlogs} from '../../Api/blogSlice'
import {dycSelector, fetchAllDYB} from '../../Api/dailybriefing'
import {researchSelector, fetchResearch} from '../../Api/researchSlice'
import {journalSelector, fetchJournal} from '../../Api/journalSlice'

import {useSelector, useDispatch} from 'react-redux'
import Header from '../../Shared/staticlayout/header'
import Loader from '../../Shared/spin'
import LibrarySec from './tab'
import { Pagination } from 'antd';
import boardimg from '../../../images/bannerimages/stack-books-library-desk (1).jpg'

export default function Daily() {

const dispatch = useDispatch()
const {blogs,  loading} = useSelector(blogSelector)
const {dailyBriefings} = useSelector(dycSelector)
const {research,total} = useSelector(researchSelector)
const {journal} = useSelector(journalSelector)

const [curentpage, setCurrentPage] = useState(1)
    useEffect(()=>{


    dispatch(fetchAllBlogs())
      dispatch(fetchAllDYB())
    dispatch(fetchResearch())
    dispatch(fetchJournal())



    }, [dispatch])


  const  pagefunc = (skip, limit) =>{

    let p = (skip-1)*10
    dispatch(fetchResearch(p, limit))
setCurrentPage(skip)

    }


    return (
        <div>
            <Header headerImg={boardimg} title="LIBRARY"/>

            {

loading ? <Loader/> : <LibrarySec journal={journal}  blogs={blogs } brefing={dailyBriefings}   research={research} />

            }
<div className="container pb-4 d-flex justify-content-end align-items-center">
{!loading && <Pagination onChange={pagefunc} defaultCurrent={curentpage} total={parseInt(total)} />}

</div>
        </div>
    )
}
