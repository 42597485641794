import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import styled from 'styled-components' 
import {Row, Col} from 'antd'
import testimg1 from '../../../../images/testimonial/Nadina.png'
import testimg2 from '../../../../images/testimonial/brainmosly.jpg'
import testimg3 from '../../../../images/testimonial/Yatin.png'

export default function Testimonial() {


    let ourTeamData = [{

        title:"Nadine Armstrong  ",
        status:"Director, Project C.U.R.E ",
        imge:testimg1,
        text:`We are working together to send a 40ft container of donated medical supplies and equipment specific to the needs of the community which Dr. Vijay is personally working towards assisting.


        `
    },
    {

      title:"Brian Mosley ",
      status:" MBA, ACS, AMB",
      imge:testimg2,
      text:`I learned of laudable efforts and achievements of Dr.Kris Vijay in clinical research towards the pursuit of the truth in science, in health and in field of medicine





      `
  },
  {

    title:"Dr. Yatin Mehta MD ",
    status:"Chairman, Institute of Critical Care ",
    imge:testimg3,
    text:`Twin Epidemic have been doing exemplary work in cardiac diseases, diabetes and renal diseases all over the World, particularly in the developing countries like India, Africa etc.
    `
},
   ]
    


    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows:false,
        autoplay:false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
       
      };


    return (
        <TestimonialWrap className="  container  py-5" >
             <Slider {...settings}>
     {

ourTeamData.map((item, i)=>{

return <Row gutter={30} className="ml-1" justify="center" align="center" key={i} >

   <Col span={24}>
   <div className="msg px-3 py-2" >

<h6>{item.text}</h6>
<span />
   </div>


<div className="d-flex mt-4 align-items-center justify-content-center">

<img  style={{borderRadius:"50%", border:"3px solid white"}} src={item.imge} alt="img"  className="d-block mx-auto shadow" />


<div className="p-2 ">
  <h5 style={{color:"white"}}>{item.title}</h5>
  <p style={{color:"white"}}>{item.status}</p>
 </div>

</div>




   </Col>





</Row> 

})

     }
        </Slider>   
        </TestimonialWrap>
    )
}


const TestimonialWrap = styled.div`

img {

  width:100px;
  height:100px;
  object-fit:cover;
}

.msg{

    background-color:var(--brandColor);
    border-radius:1rem;
    color:white;
    position: relative;
    p{

        font-size:16px;
    }

    h6{

      color:white;
      line-height:1.7;
      font-weight:400;
      font-size:0.95rem;
    }

    span {

        clip-path: polygon(50% 99%, 0 0, 100% 0);

        padding:12px 12px;
        background-color:var(--brandColor);
        position:absolute;
        left:10%;
        bottom:-14.5%;

    }
}

`