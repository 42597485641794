import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { message } from 'antd';
import keyUri from '../../uri'

export const initialState = {

    loading:false,
    hasErrors:false,
    Questions:[],
    currentQuestion:null,
    totalUnAnsweredQtn : 0,
    total:0

}

export const qtnSlice = createSlice({

    name:"questions",
    initialState,
    reducers:{

        getQuestions:state =>{

            state.loading = true;

        },

        getQuestionSuccess:(state, {payload}) =>{

            state.loading = false;
            state.Questions = payload
            state.totalUnAnsweredQtn = payload.totalUnanswerQtn
            state.total = payload.total
            
        },

        getCurrentQuestion:(state, {payload}) =>{

            state.loading = false;
            state.currentQuestion = payload
            
        },

        getCurrentAnswer:(state, {payload}) =>{

            state.loading = false;
            state.currentAnswer = payload
            
        },

        getAnswer: (state) =>{

            state.loading = false;


        },

        getQuestionError:state =>{

            state.loading = false;
            
        },
        getAnswerError:state =>{

            state.loading = false;
            
        },
        getUpdate : state =>{

            state.loading = false
        },
        getSuccesOk: state =>{

            state.loading = false
            state.isSuccess = false
        },
    }
})

export const {getQuestions, getAnswer, createQtnState, getCurrentQuestion,

    getUpdate, getQuestionError, getQuestionSuccess,getCurrentAnswer,getSuccesOk,getAnswerError} = qtnSlice.actions;

export const qtnSelector = state => state.questions

export default qtnSlice.reducer



const config = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
  };



export const fetchAllQtn = (skip=0, limit=10, searchword='') => async dispatch =>{

dispatch(getQuestions())

try {

    const {data} = await axios.get(keyUri +`/api/questions?skip=${skip}&limit=${limit}&search=${searchword}`);

   
dispatch(getQuestionSuccess(data))


} catch (error) {
    
    dispatch(getQuestionError())


}


}



export const fetchCurrentQtn = (id) => async dispatch =>{

    dispatch(getQuestions())
    
    try {
    
        const {data} = await axios.get(keyUri +`/api/question/${id}`);

    dispatch(getCurrentQuestion(data))
    
    } catch (error) {

    dispatch(getQuestionError())

    }
    
    
    }


export const createQtn = (qdata) => async dispatch =>{

    const key = 'Loading';
        dispatch(getQuestions())
        message.loading({ content: 'Loading...', key })

try {
    
   const {data} = await axios.post(keyUri +'/api/question', {question:qdata.Question}, config);
  
   setTimeout(() => {

    message.success({ content: data.msg, key, duration: 2 });
  }, 500)  


} catch (error) {

    dispatch(getQuestionError())

    }

}


export const createAnswer = (answer, id) =>async dispatch =>{
    const key = 'Loading';

    dispatch(getQuestions())
    message.loading({ content: 'Loading...', key })
try {
    
    const {data} = await axios.post(keyUri +`/api/answer/${id}`, answer, config);
    dispatch(fetchAllQtn())
    setTimeout(() => {

        message.success({ content: data.msg, key, duration: 2 });
      }, 500) 
 

} catch (error) {
    
}

}    


export const deleteQtn = (id) => async dispatch =>{

    const key = 'delete';

        dispatch(getQuestions())
        message.loading({ content: 'Deleting...', key })

try {
    
   const {data} = await axios.delete(keyUri +`/api/question/${id}`);
   dispatch(getCurrentQuestion())

   setTimeout(() => {

    message.success({ content: data.msg, key, duration: 2 });
  }, 500) 


} catch (error) {

    dispatch(getQuestionError())
   
 }

}


  
export const deleteAns = (id) => async dispatch =>{

    const key = 'delete';

        dispatch(getAnswer())
        message.loading({ content: 'Deleting...', key })

try {
    
   const {data} = await axios.delete(keyUri +`/api/answer/${id}`);
   dispatch(getCurrentAnswer())

   setTimeout(() => {

    message.success({ content: data.msg, key, duration: 2 });
  }, 500) 


} catch (error) {

    dispatch(getAnswerError())

    
}

 }

 export const updateAns = (id, ansupdate) => async dispatch =>{

    const key = 'update';

    dispatch(getAnswer());
    message.loading({ content: 'updating...', key })
    try {
        
             const {data} = await axios.put(keyUri +`/api/answers/${id}`, ansupdate, config)
         dispatch(getUpdate()) 
           
        setTimeout(() => {

            message.success({ content: data.success, key, duration: 2 });
          }, 200)

         
    } catch (error) {

        dispatch(getAnswerError());
    }

}


export const  isSuccessOK = () =>async dispatch =>{

    dispatch(getSuccesOk())
}

export const questionSubmit = (values) => async dispatch =>{

    dispatch(getQuestions())


try {

    const { data } = await axios.post(keyUri +'/api/question', values, config);
 

    data && dispatch(getQuestionSuccess(data))

} catch (error) {
    
    dispatch(getQuestionError())
}

}