import React from 'react'
import Header from '../../Shared/staticlayout/header'
import Stories from './stories'
import Form from './form'
import headerImage from '../../../images/bannerimages/vintage-typewriter-wooden-table(2)(1) (1).jpg'

export default function Volunteer() {
    return (
        <div className="pb-5" >
            <Header headerImg={headerImage} title="SHARE YOUR STORY"/>
            
            <Form/>

            <Stories/>
        </div>
    )
}