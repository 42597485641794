import React from 'react'
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom'

export default function about() {
    return (
        <Fade bottom duration={300} distance={"50px"}>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
    
    <div className="row" style={{position:"relative"}}>
        <div className="col">
        <ul className="text-dark text-capitalize">
        <li className="nav-item ">
        <Link className="nav-link" to="/community-health-screening">Community Health Screening </Link>
      </li>
      <li className="nav-item ">
        <Link className="nav-link" to="/medical-conference">Medical Conference </Link>
      </li>

      <li className="nav-item ">
        <Link className="nav-link" to="/cpr-Training">CPR Training</Link>
      </li>

      <li className="nav-item ">
        <Link className="nav-link" to="/research">Research</Link>
      </li>
  
            </ul>
        </div>

    
    </div>
    
    
        </div>
        </Fade>
    )
}
