import React from 'react'
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import Apanel2 from './AcPanel'

const { Panel } = Collapse;


export default function Accordian() {
    return (
        <div>
             <Collapse
    bordered={false}
    defaultActiveKey={['1']}
    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    className="site-collapse-custom-collapse"
  >
    <Panel header="What is diabetic kidney disease?" key="1" className="site-collapse-custom-panel">
   <p className="pl-sm-5 pl-2">
   Diabetic kidney disease is a type of kidney disease caused by diabetes.
Diabetes is the leading cause of kidney disease. About 1 out of 4 adults with diabetes has kidney disease.1
The main job of the kidneys is to filter wastes and extra water out of your blood to make urine. Your kidneys also help control blood pressure and make hormones that your body needs to stay healthy.
When your kidneys are damaged, they can’t filter blood like they should, which can cause wastes to build up in your body. Kidney damage can also cause other health problems.
Kidney damage caused by diabetes usually occurs slowly, over many years. You can take steps to protect your kidneys and to prevent or delay kidney damage.
   </p>
    </Panel>

    <Panel header="What are other names for diabetic kidney disease?" key="2" className="site-collapse-custom-panel">
   <p className="pl-sm-5 pl-2"> Diabetic kidney disease is also called DKD, chronic kidney disease, CKD, kidney disease of diabetes, or diabetic nephropathy.
How does diabetes cause kidney disease?
High blood glucose, also called blood sugar, can damage the blood vessels in your kidneys. When the blood vessels are damaged, they don’t work as well. Many people with diabetes also develop high blood pressure, which can also damage your kidneys. Learn more about high blood pressure and kidney disease.
</p>
    </Panel>

    
 <Panel header="What increases my chances of developing diabetic kidney disease?" key="3" className="site-collapse-custom-panel">
    <Apanel2/>
    </Panel>


   <Panel header="How can I tell if I have diabetic kidney disease?" key="4" className="site-collapse-custom-panel">
   <p className="pl-sm-5 pl-2">  Most people with diabetic kidney disease do not have symptoms. The only way to know if you have diabetic kidney disease is to get your kidneys checked.
Health care professionals use blood and urine tests to check for diabetic kidney disease. Your health care professional will check your urine for albumin and will also do a blood test to see how well your kidneys are filtering your blood.
You should get tested every year for kidney disease if you
- have type 2 diabetes
- have had type 1 diabetes for more than 5 years</p>
    </Panel>

 <Panel header="How can I keep my kidneys healthy if I have diabetes?" key="5" className="site-collapse-custom-panel">
   
 <p className="pl-sm-5 pl-2">The best way to slow or prevent diabetes-related kidney disease is to try to reach your blood glucose and blood pressure goals. Healthy lifestyle habits and taking your medicines as prescribed can help you achieve these goals and improve your health overall.
Reach your blood glucose goals<br/><br/>
Your health care professional will test your A1C. The A1C is a blood test that shows your average blood glucose level over the past 3 months. This is different from the blood glucose checks that you may do yourself. The higher your A1C number, the higher your blood glucose levels have been during the past 3 months.<br/>
The A1C goal for many people with diabetes is below 7 percent. Ask your health care team what your goal should be. Reaching your goal numbers will help you protect your kidneys.<br/>
To reach your A1C goal, your health care professional may ask you to check your blood glucose levels. Work with your health care team to use the results to guide decisions about food, physical activity, and medicines. Ask your health care team how often you should check your blood glucose level.<br/>
Control your blood pressure<br/><br/>
Blood pressure is the force of your blood against the wall of your blood vessels. High blood pressure makes your heart work too hard. It can cause heart attack, stroke, and kidney disease.<br/>
Your health care team will also work with you to help you set and reach your blood pressure goal. The blood pressure goal for most people with diabetes is below 140/90 mm Hg. Ask your health care team what your goal should be.<br/>
Medicines that lower blood pressure can also help slow kidney damage. Two types of blood pressure medicines, ACE inhibitors and ARBs, play a special role in protecting your kidneys. Each has been found to slow kidney damage in people with diabetes who have high blood pressure and DKD. The names of these medicines end in –pril or –sartan. ACE inhibitors and ARBs are not safe for women who are pregnant.<br/>
Develop or maintain healthy lifestyle habits<br/>
Healthy lifestyle habits can help you reach your blood glucose and blood pressure goals. Following the steps below will also help you keep your kidneys healthy:
<br/>
<br/>
- Stop smoking.<br/>
- Work with a dietitian to develop a diabetes meal plan and limit salt and sodium.<br/>
- Make physical activity part of your routine.<br/>
- Stay at or get to a healthy weight.<br/>
- Get enough sleep. Aim for 7 to 8 hours of sleep each night.<br/>
  Learn more about these tips to manage diabetes.<br/>
  Take medicines as prescribed<br/>
  Medicines may be an important part of your treatment plan. Your health care professional will prescribe medicine based on your specific needs. Medicine can help you meet your blood glucose and blood pressure goals. You may need to take more than one kind of medicine to control your blood pressure.

  </p>

    </Panel>


<Panel header="How can I cope with the stress of managing my diabetes?" key="6" className="site-collapse-custom-panel">
 <p className="pl-sm-5 pl-2">
 Managing diabetes isn’t always easy. Feeling stressed, sad, or angry is common when you are living with diabetes. You may know what to do to stay healthy but may have trouble sticking with your plan over time. Long-term stress can raise your blood glucose and blood pressure, but you can learn ways to lower your stress. Try deep breathing, gardening, taking a walk, doing yoga, meditating, doing a hobby, or listening to your favorite music. Learn more about healthy ways to cope with stress NIH external link.
 </p>
    </Panel>


<Panel header="Does diabetic kidney disease get worse over time?" key="7" className="site-collapse-custom-panel">
<p className="pl-sm-5 pl-2">Kidney damage from diabetes can get worse over time. However, you can take steps to keep your kidneys healthy and help slow kidney damage to prevent or delay kidney failure. Kidney failure means that your kidneys have lost most of their ability to function—less than 15 percent of normal kidney function. However, most people with diabetes and kidney disease don’t end up with kidney failure.
If your kidneys become damaged as a result of diabetes, learn how to manage kidney disease.
References
[1] Afkarian M, Zelnick LR, Hall YN, et.al. Clinical manifestations of kidney disease among US adults with diabetes. Journal of the American Medical Association. 2016;316(6):602–610.</p>
  
  
<p className="pl-sm-5 pl-2">Your kidneys are located in the middle of your back, just below your rib cage.<br/>

If you have diabetes, you are more likely to develop kidney disease if you have high blood pressure.<br/>

Health care professionals use blood and urine tests to check for kidney disease.<br/>

Protect your kidneys by keeping your blood glucose under control.<br/>

Protect your kidneys by keeping your blood pressure under control.<br/>

Talk to your health care professional or pharmacist about all of the medicines you take, including over-the-counter medicines.<br/>
</p> 
    </Panel>




  </Collapse>
        </div>
    )
}
