import React from 'react'
import { Table,  Space } from 'antd';
import { deleteBlog} from '../../../Api/blogSlice'
import {useDispatch} from 'react-redux'
import moment from 'moment'
import DeleteConfirm from '../../../Shared/deleteConfirm'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import Fade from 'react-reveal/Fade'
import {Link} from 'react-router-dom'
export default function Datatable({blogs}) {

        const dispatch = useDispatch()


        const confirm = (e, id) => {
            dispatch(deleteBlog(id._id))
           
          }
          
          const cancel = (e) =>{
            return null
          }

         

    const columns = [
        {
          title: 'title',
          dataIndex: 'title',
          key: 'title',
          render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
        },
        {
          title: 'author',
          dataIndex: 'author',
          key: 'author',
        },
        {
          title: 'catagory',
          dataIndex: 'catagory',
          key: 'catagory',
        },
        {
          title: 'publish',
          dataIndex: 'publish',
          key: 'publish',
          render:(isPublish, data) =>{

            return isPublish?
             <small ><b className="text-success">published </b>&nbsp;  {moment(data.createdAt).format("MMM Do YYYY") }</small>:   
             <small>Not yet publish</small>
          }
          
        },
        {
          title: 'thumbnail',
          key: 'image',
          dataIndex: 'image',
          render:(url)=>(

            <img src={url} className="rounded" alt="blogimage" height="40px" width="50px"/>
          )
        },
        {
          title: 'Action',
          key: 'action',
          render: (id) => {


          return  <Space size="middle">
              
              <h5 className="text-dark">
               <Link to={`/admin/blog/update/${id._id}`}>
                <FaRegEdit/> 
                 </Link> 
                
                
                </h5>
            <h5 className="text-danger">
                <DeleteConfirm confirm={(e)=>confirm(e, id)} title="blog" cancel={cancel} >
                    <FaRegTrashAlt style={{cursor:"pointer"}} />
                </DeleteConfirm>
            </h5>

            </Space>
        },
        },
      ];


    

    return (
        <Fade>
            <Table pagination={false}   rowKey={record => record._id} columns={columns} dataSource={blogs} />
         
      </Fade>
    )
}
