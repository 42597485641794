import React from 'react'
import InterWeave from 'interweave'
import moment from 'moment'
import { Image } from 'antd';
import loading from '../../../images/loading.gif'
import {Row, Col, Button} from 'antd'
import Limit from '../../Shared/limitDes'
import styled from 'styled-components'
export default function ResearchSec({data}) {
    return (

        data.map((item, i)=>{

          console.log(item);

            return <ResearchWrap key={i} className="container py-2 mb-5 ">
            
            <Row gutter={25}  style={{ borderRadius:"0.5rem"}}>
{/* 
                <Col span={8}>
                
                <Image
          
                className="shadow "
        width={"100%"}
        src={item.image}
        placeholder={
          <Image
            preview={false}
            src={loading}
            width={400}
          />
        }
      />
                
                
                </Col> */}
                <Col span={24}>
                
                <div className="mt-4 ml-2" >
           
           <small> by {item.author_name} &nbsp; on &nbsp; {moment(item.createdAt).format('MMM Do YYYY')}</small>
           <h2 style={{color:"var(--acentColor)",  fontFamily:"var(--headingFont)"}}>{item.research_title}</h2>

         <InterWeave  content={ Limit(item.description, 400)}/>

            <Button className="shadow-sm" style={{backgroundColor:"var(--acentColor)", border:"none", borderRadius:"5px"}} type="primary"><a href={`${item.extra_link}`}>Read More</a></Button>

       </div>
                
                
                
                
                </Col>

            </Row>
            
            </ResearchWrap>
            
      
        })
      
    )
}



const ResearchWrap = styled.div`

span > p {

font-size:14px;
text-align:justify;
font-weight:400 !important;
color:grey;

strong {

font-weight:400 !important;

    
}
}
`