import React from 'react'
import { PayPalButton } from "react-paypal-button-v2";
import {donate} from '../../Api/eventSlice'
import {useDispatch} from 'react-redux'
import { message } from 'antd'
export default function PaymentSec({amt, email, id}) {

  const dispatch = useDispatch()
    return (
        <div disabled="true">
             <PayPalButton
        amount={20.0}
        email={email}
        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        onSuccess={(details, data) => {


          const key = 'donate';
          
          try {
            
            details.status === "COMPLETED" && message.success({ content:`Transaction completed by ${details.payer.name.given_name}`, key, duration: 2 })

                 

          } catch (error) {

                        message.error({ content:`Somthing Went Wrong !`, key, duration: 2 });

            
          }
       
          
       

         

          // message.success("Transaction completed by " + details.payer.name.given_name);

          let info = {
            
            name:details.payer.name.given_name,
            donar_amt:amt,
            email:email,
            id
          }

          // OPTIONAL: Call your server to save the transaction
          return dispatch(donate(info))
        }}
        options={{
            // clientId: "AfTaDgUbSRiHLtHEQNIPryz9jZOz3tTPYnjzWKegAEXVRNhiPmHWsZ4DWw4Rz0HZktk31oMqWIcLBQzS",
            currency:"INR",
       
          }}
      />
        </div>
        
    )
}
