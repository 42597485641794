import React from 'react'
import { Form, Input, Button, Select } from 'antd';
import styled from 'styled-components'
import {VolunteerSubmit, volunteerSelector, isSuccessOK} from '../../Api/volunteerSlice'
import {useDispatch, useSelector} from 'react-redux'
import logo from '../../../images/logo icon-06.png'
import { Modal } from 'antd';
const { Option } = Select;


const layout = {
    labelCol: {
      span: 0,
    },
    wrapperCol: {
      span: 24,
    },
  };


export default function VolunteerForm() {

const dispatch = useDispatch()
    const {isSuccess, loading} = useSelector(volunteerSelector)

    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log('Success:', values);
        dispatch(VolunteerSubmit(values))
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

  const cancel = () =>{

dispatch(isSuccessOK())
form.resetFields();
    
  }
   

      const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
            style={{
              width: 70,
            }}
          >
             <Option value="1">+1</Option>

            <Option value="91">+91</Option>
            <Option value="81">+81</Option>
            <Option value="45">+45</Option>
            <Option value="87">+87</Option>
            <Option value="86">+86</Option>
            <Option value="55">+55</Option>
            <Option value="7">+7</Option>
            <Option value="268">+268</Option>
            <Option value="44">+44</Option>
            <Option value="263">+263</Option>
            <Option value="54">+54</Option>
            <Option value="93">+93</Option>
            <Option value="86">+86</Option>
            <Option value="82">+82</Option>
            <Option value="61">+61</Option>

          </Select>
        </Form.Item>
      );


    return (
        <ValunteerWrap className="container my-5" >

<h5 className="text-center" style={{color:"var(--brandColor)"}}>Volunteer With Us</h5>
<p className="text-center" style={{color:"var(--acentColor)"}}>By personally engaging in Twin-epidemic activities, you will be touched in many ways that will rekindle your inner peace and happiness.</p>
            <br/>
                <Form
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
        prefix: '1'
      }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
      
        name="firstname"
        rules={[
          {
            required: true,
            message: 'required!',
          },
        ]}
      >
        <Input  placeholder="Enter First Name"/>
      </Form.Item>

      <Form.Item
     
        name="lastname"
        rules={[
          {
            required: true,
            message: 'required!',
          },
        ]}
      >
        <Input placeholder="Enter Last Name" />
      </Form.Item>


      <Form.Item
     
        name="email"
        rules={[
            {
              type: 'email',
            },
          ]}
      >
        <Input email="Enter Email" placeholder = " Enter Email" />

      </Form.Item>


      <Form.Item
        name="contact_number"
    
        rules={[
          {
            required: true,
            message: 'Please input your phone number!',
          },
        ]}
      >
        <Input
        placeholder="Enter Phone Number"
          addonBefore={prefixSelector}
          style={{
            width: '100%',
          }}
        />
      </Form.Item>

      <Form.Item

        name="state"
        rules={[
          {
            required: true,
            message: 'required!',
          },
        ]}
      >
        <Input   placeholder="Enter State"/>
      </Form.Item>


      
      <Form.Item
   
        name="country"
        rules={[
          {
            required: true,
            message: 'required!',
          },
        ]}
      >
        <Input placeholder="Enter country" />
      </Form.Item>

      <Form.Item  name="description" >
        <Input.TextArea rows={4}  placeholder="short note"/>
      </Form.Item>


      <Form.Item >
        <Button type="primary" className="cstbtn" style={{backgroundColor:"var(--brandColor)"}} htmlType="submit">
        {loading? "Loading..." : "Submit"}  
        </Button>
      </Form.Item>
    </Form>

      <Modal className="p-4"  width={800} footer={false} onCancel = {cancel} visible={isSuccess}>
      <img src={logo} width="120px" className="d-block mx-auto"/>
       <h2 className="text-center" style={{color:"var(--brandColor)"}}>Thanks For Being <br/> Awesome!</h2>
        <p className="text-center text-secondary" style={{fontSize:"20px"}}>We have received your message and would like to thank you for writing to us.
        We will get back to you as quick as possible.</p>
 <hr   style={{height:"5px", width:"20%", backgroundColor:"var(--acentColor)" }}/>
      </Modal>
        </ValunteerWrap>
    )
}


const ValunteerWrap = styled.div`


.ant-input {

    font-size: 20px;
    box-shadow: 2px 2px 3px 1px #dedede;
    border: 0.3px solid #fbfbfb;
    border-radius: 0 0 10px 10px;
    padding:0.5rem 1rem;
}

.ant-input-group-addon{

   font-size: 20px;
    box-shadow: 2px 2px 3px 1px #dedede;
    border: 0.3px solid #fbfbfb;
    border-radius: 10px 0 0 10px;
    background-color:white;

}

.cstbtn{

    padding: 0.5rem 3rem;
    border: none;
    height: 100%;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    float:right;
    box-shadow: 1px 1px 5px 0px #5a5858;
}

.ant-select-single .ant-select-selector .ant-select-selection-item{

    line-height: 40px;
    color: grey;
    font-weight: 500;

}


@media(max-width:760px) {


    .ant-input {

font-size: 14px;

}

.ant-input-group-addon{

font-size: 14px;


}

h1{

    font-size:1.8rem;
}

}
`