import React from 'react'
import { Table,  Space } from 'antd';
import {deleteUser} from '../../../Api/userSlice'
import {useDispatch} from 'react-redux'
import DeleteConfirm from '../../../Shared/deleteConfirm'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import Fade from 'react-reveal/Fade'
import {Link} from 'react-router-dom'
export default function Datatable({users}) {

        const dispatch = useDispatch()


        const confirm = (e, id) => {
            dispatch(deleteUser(id._id))
           
          }
          
          const cancel = (e) =>{
            return null
          }



    const columns = [
        {
          title: 'name',
          dataIndex: 'name',
          key: 'name',
          render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Super Admin',
          dataIndex: 'super_admin',
          key: 'super_admin',
          render: text => text? <p>Super Admin</p> : <p>Admin</p>
        },
       
        {
          title: 'Action',
          key: 'action',
          render: (id) => {


          return  <Space size="middle">
              
              <h5 className="text-secondary">
               <Link to={`/admin/user/update/${id._id}`}>
                <FaRegEdit/> 
                 </Link> 
                
                
                </h5>
            <h5 className="text-danger">
                <DeleteConfirm confirm={(e)=>confirm(e, id)} title="user" cancel={cancel} >
                    <FaRegTrashAlt style={{cursor:"pointer"}} />
                </DeleteConfirm>
            </h5>

            </Space>
        },
        },
      ];


    

    return (
        <Fade>
            <Table   rowKey={record => record._id} columns={columns} dataSource={users} />
        </Fade>
    )
}
