import { Button, InputNumber, Radio } from 'antd';

import React, { useEffect, useState } from 'react'
import image from '../../../images/donateImg/donateImage.jpg'
import styled from 'styled-components'
import DonateSec from './donSec';
export default function Intro() {

    const [amount, setAmount] = useState(60)

    const handleDonate = (e) =>{

        setAmount(e.target.value)

    }
    useEffect(()=>{

// console.log('update');

    }, [amount])



    return (
        <IntroWrap className="container mb-5" >
        <div className="row">

            <div className="col-sm-8 p-3" >
                <h3 style={{color:"var(--acentColor)"}}>Your help can save lives</h3>
                <p style={{fontSize:"1rem",color:"var(--acentColor)"}}>
                    
                The mission of TwinEpidemic, a 501 &#10088;c&#10089; 3 charitable non-profit, is to put an end to the devastating impact of heart disease and diabetes in underserved communities. We do this through direct patient aid, patient education, free community health screenings, professional education and research. Your donations directly impact the health of those most in need. Any amount is helpful. A sustaining monthly donation will ensure ongoing community assistance.                
                                </p>
         
           <hr style={{height:"1px", border:"none", backgroundColor:"var(--acentColor)"}}/>


           <div>
 <Radio.Group value={amount} onChange={handleDonate}>
          <Radio.Button value={40}>$40</Radio.Button>
          <Radio.Button value={60}>$60</Radio.Button>
          <Radio.Button value={100}>$100</Radio.Button>
        </Radio.Group>

        <InputNumber onChange={(value)=>setAmount(value)} className="ml-2" placeholder="$ other"/>
</div>    

 <div className="d-flex justify-content-start">

<DonateSec amount={amount}/>
 </div>

       
           {/* <Button type="primary">Donate Now</Button> */}
            </div>

            <div className="col-sm-4 rounded imged " >

<img src={image} className="mt-sm-4" alt="imag" width="100%"/>
            </div>
            
        </div>
        </IntroWrap>
    )
}


const IntroWrap = styled.div`

.imged{

    background-repeat:no-repeat;
}
`