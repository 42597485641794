import React, {useState} from 'react'
import { Table, Form, Button, Space } from 'antd';
import {fetchAllQtn, createAnswer,deleteAns,  deleteQtn} from '../../../Api/expertSlice'
import {useDispatch} from 'react-redux'
import DateFrmate from '../../../Shared/timeformate'
import DeleteConfirm from '../../../Shared/deleteConfirm'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import ModalSec from '../../../Shared/modal'
import ReactQuill from 'react-quill';
import Fade from 'react-reveal/Fade'
import Interweave from 'interweave'

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
 

export default function Datatable({questions}) {


    const dispatch = useDispatch()
    const [answer, setAnswer] = useState('');

    const [form] = Form.useForm();

    const confirm = (e, id) => {
        dispatch(deleteQtn(id._id))
        dispatch(deleteAns(id))
       dispatch(fetchAllQtn()) 
      }
      
      const cancel = (e) =>{
        return null

      }

  
    const columns = [
        {
          title: 'Question',
          dataIndex: 'question',
          key: 'question',
          render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
        },
        {
          title: 'Date',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          render:(riseddate) =>{

            return <small ><b className="text-success">Raised on</b>&nbsp;  {DateFrmate(riseddate)}</small> 
      
          }
          
        },
        {
          title: 'Ansewers',
          dataIndex: 'answers',
          key: 'answers',
          render:(answers) =>{

            return <small style={{color:"var(--acentColor)"}}>Answered &nbsp;({answers.length})</small> 
      
          }
          
        },
        
        {
          title: 'Action',
          key: 'action',
          render: (id) => {

            const onFinish = (values) => {

              values.answer = answer

            //   console.log(values, id._id);

              dispatch(createAnswer(values, id._id))
              setAnswer('')
              
              };


              const onFinishFailed = (errorInfo) => {
                console.log('Failed:', errorInfo);
              };


          return  <Space size="middle">
              
              <div>
                  
                  <ModalSec   mtitle="Submit your Answer here!">

                    <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >  
           <Form.Item wrapperCol={{ span: 23, offset: 1}}>

              <h6 style={{color:"var(--acentColor)"}} className="my-3">{id.question}</h6>
              <ReactQuill theme="snow" value={answer} onChange={setAnswer}/>
             </Form.Item>

              <Form.Item wrapperCol={{ span: 23, offset: 1}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>

</Form>
                  
                  </ModalSec></div>
            <h5 className="text-danger">
                <DeleteConfirm title="question" confirm={(e)=>confirm(e, id)} cancel={cancel} >
                    <FaRegTrashAlt style={{cursor:"pointer"}} />
                </DeleteConfirm>
            </h5>

            </Space>
        },
        },
      ];


// console.log(questions);


    return (
        <Fade>

       <Table pagination={false}  rowKey={record => record._id}
        columns={columns}
        
        expandable={{
          expandedRowRender: record =><div>

{

record.answers.map((answer, i)=>{

  return <div key={i} className=" pl-5 shadow-sm my-2">

<div className=" ">
  
 <Interweave content={answer.answer}/>
  <div className="d-flex my-3 pl-4  align-items-center justify-content-satrt">
                    
                    <h6 className="mx-2 text-danger">
                    <DeleteConfirm title="question" confirm={(e)=>confirm(e,answer._id)} cancel={cancel} >
                      <FaRegTrashAlt style={{cursor:"pointer"}} />
                      </DeleteConfirm>
                      </h6> 
                    
                <h6 className="mx-2 text-secondary">
                  
                  
                  <FaRegEdit/></h6> 
  
          </div>
  </div>
  
    </div>


})
}
          </div>,
          rowExpandable: record => record.name !== 'Not Expandable',
        }}
        dataSource={questions}
        expandRowByClick={false}
        
        />
     
        
        </Fade>       
                    
    )
}


