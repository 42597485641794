import React from 'react'
import Header from '../../Shared/staticlayout/header'

import headerImage from '../../../images/moredetails.jpeg'

export default function Moredetails() {
    return (
        <div className="pb-5 d-flex  align-items-center justify-content-center"  >
            
            <img src={headerImage}  />
   

        </div>
    )
}

