import React from 'react'
import StaticLayout from '../../Shared/staticlayout'
import imge from '../../../images/introimages/hand-holding-red-heart-wood-background (1) (1) (1).jpg'
import heartimge from '../../../images/bannerimages/close-up-man-is-heart-attack (1).jpg'
import Header from '../../Shared/staticlayout/header'
import AcPanel from './accordian'
export default function Heart() {

  
let introData = {

    introtitle:"RISK FACTORS FOR HEART DISEASE",
    text:"Coronary artery disease, also called heart disease, causes roughly 735,000 heart attacks each year in the U.S. and kills more than 630,000 Americans each year. According to the American Heart Association, over 7 million Americans have suffered a heart attack in their lifetime. Because heart disease is so common and often is silent until it strikes, it is important to recognize the factors that put you at risk.",
    imge: imge

}





    return (
        <div className="mb-5">
            <Header title="HEART DISEASE" headerImg={heartimge} />

           <StaticLayout
            data = {introData}
   
            >



               <AcPanel/>
                </StaticLayout>

        </div>
    )
}
