import React from 'react'
import { Table, Space } from 'antd';
import {deleteJournal} from '../../../Api/journalSlice'
import {useDispatch} from 'react-redux'
import DeleteConfirm from '../../../Shared/deleteConfirm'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';

import Fade from 'react-reveal/Fade'
import Interweave from 'interweave';
import {Link} from 'react-router-dom'

export default function Datatable({data}) {

        const dispatch = useDispatch()
        console.log(data);


        const confirm = (e, id) => {
            dispatch(deleteJournal(id._id))
           
          }
          
          const cancel = (e) =>{
            return null
          }



    const columns = [
        {
          title: 'author name',
          dataIndex: 'author_name',
          key: 'author_name',
          render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
        },
        
        {
            title: 'title',
            dataIndex: 'title',
            key: 'title',
            render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
          },
        
        {
          title: 'image',
          key: 'image',
          dataIndex: 'image',
          render:(url)=>(

            <img src={url} alt="image" width="50px"/>
          )
        },
        {
          title: 'Action',
          key: 'action',
          render: (id) => {


          return  <Space size="middle">
              
              <h5 className="text-secondary">
              <Link to={`/admin/Journal/update/${id._id}`}>
                <FaRegEdit/>
                </Link>
                
                </h5>
            <h5 className="text-danger">
                <DeleteConfirm title="Journal" confirm={(e)=>confirm(e, id)} cancel={cancel} >
                    <FaRegTrashAlt style={{cursor:"pointer"}} />
                </DeleteConfirm>
            </h5>

            </Space>
        },
        },
      ];


    

    return (
        <Fade>
            <Table pagination={false}  rowKey={record => record._id} columns={columns}
            
            expandable={{
                expandedRowRender: record =><div className="px-5">
      
     <Interweave content={record.description} />
      
                </div>,
                rowExpandable: record => record.description !== 'Not Expandable',
              }}

            dataSource={data}
            expandRowByClick={false}
            
            />
        </Fade>
    )
}
