import React from 'react'
import styled from 'styled-components'
import readImage from '../../../images/business-teamwork-join-hands-together-business-teamwork-concept (1).jpg'
import { GiCaduceus } from "react-icons/gi";
import { Link } from 'react-router-dom';

export default function Readmore() {
    return (
        <ReadmoreWrap className="container-fluid" style={{transform:"translateY(-2.5rem)"}}>
            
            {/* <div className="row">
                <div className="col-sm-6 p-0">
                    <img style={{objectFit:"cover"}} height="430px" src={readImage} alt="read" width="100%" />
                </div>
                <div className="col-sm-6">
                    <div className="content text-white">
                        <h1><GiCaduceus/></h1>
                        <h3>Join hands with us</h3>
                        <p>
                        We are fighting against not one but two diseases, we care about your health and that of the underserved community. Your support is critical to funding innovative research that helps health care professionals identify, treat and even prevent the Twin epidemic.
                        </p>
                        <button className="btn btn-light">
                            <Link to="/donate" >Donate</Link></button>
                    </div>
                </div>

            </div> */}

            <div className="row">
                <div className="col-sm-6 p-0">

                    <img style={{objectFit:"cover"}} height="600px" src={readImage} alt="read" width="100%" />
                </div>
                <div className="col-sm-6">
                    <div className="content text-white">
                        <h1><GiCaduceus/></h1>
                        <h3>Galvanize Healthy Living Pre-Registration</h3>
                        <p>
                        The primary objectives of GALVANIZE Healthy Living (GHL) is to perform community screenings and 
preventive education programs designed to improve health outcomes of people who are at risk of 
diabetes, heart disease, kidney disease and their co-morbid conditions.  
<br/>Our next screening will be March 19, 2022 at the Indo-American Community Center, 2809 W Maryland 
Ave., Phoenix, AZ 85017. 
Appointments are strongly recommended.   

                        </p>
                        <button className="btn btn-light " style={{fontSize:"20px"}}>
                            <Link to="/pre-registration" >Click here to register</Link></button>

                            <button className="btn btn-light " style={{fontSize:"20px",marginLeft:"15px"}}>
                            <Link to="/more-details" >Get more details </Link></button>
                    </div>
                </div>

            </div> 

        </ReadmoreWrap>
    )
}


const ReadmoreWrap = styled.div`

background-color:var(--brandColor);

height:600px;
overflow:hidden;

.content{
padding:1rem 0.5rem;
p{
    font-size:1.2rem;
    line-height:1.8;
}
    h1{

svg{

    font-size:6.5rem;
    color:white;

}
}

h3{

color:white;
font-size:2.2rem;
}

}

@media(max-width:960px){

    height:100%;

    .content{
padding:1rem 0.5rem;
text-align:center;
p{
    font-size:1rem;
    line-height:1.6;
}
    h1{

svg{

    font-size:4.5rem;
    color:white;

}
}

h3{

color:white;
font-size:1.7rem;
}

}

}

`