import React from 'react'
import Slider from "react-slick";
import img1 from '../../../images/donateImg/2275.jpg'
import img2 from '../../../images/donateImg/2276.jpg'
import img3 from '../../../images/donateImg/2279.jpg'
import img4 from '../../../images/donateImg/2641.jpg'
import img5 from '../../../images/donateImg/2633.jpg'
import img6 from '../../../images/donateImg/2632.jpg'

export default function Dimg() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows:false,
        autoplay:true,
        autoplaySpeed: 3000,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };

      let data = [
img1, img2, img3, img4, img5, img6
      ]
    return (
        <div className="container my-5">
            <Slider  {...settings}>

                {
                    data.map((item, i)=>{

                  return    <div key={i} className="px-2 ">
                 <img className="rounded"  src={item} alt="gal" width="100%"/>
                        </div>   
                    })
                }
           
       
            </Slider>
        </div>
    )
}
