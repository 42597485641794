import React from 'react'
import styled from 'styled-components'
import aduImg from '../../../images/cpr/Adult_OHCA_COS_600x415_72dpi_optimized.jpg'
export default function BorderDesc() {
    return (
        <BorderDescWrap className="px-sm-5 px-3 py-4 mb-5">

            <div className="row">

                <div className="col-sm-6">

<h4>The 6 links in the adult out-of-hospital Chain of Survival are:</h4>
<ul>
    <li>Recognition of cardiac arrest and activation of the emergency response system (calling 9-1-1 in the US)</li>
    <li>Early CPR with an emphasis on chest compressions </li>
    <li>Rapid defibrillation</li>
    <li>Advanced resuscitation by Emergency Medical Services and other health care providers Post-cardiac arrest care</li>
    <li>Recovery (including additional treatment, observation, rehabilitation, and psychological support)</li>

</ul>

<p>A strong Chain of Survival can improve chances of survival and recovery for victims of cardiac arrest.</p>
                </div>
                <div className="col-sm-6">
<img src={aduImg} width="100%" alt="imge" />

                </div>

            </div>
            
        </BorderDescWrap>
    )
}


const BorderDescWrap = styled.div`

border:5px solid var(--acentColor2);
border-radius:1rem;
color:var(--acentColor);

h4{

color:var(--acentColor);
}

p{
color:var(--acentColor);
font-size:1rem;
line-height:1.6;
font-weight:400;

}

ul {

    li{
color:var(--acentColor);
font-size:1rem;
line-height:1.6;
font-weight:400;
        
    }
}
@media(max-width:960px)
{
    h4{
        font-size:1.2rem;
    }
}

`