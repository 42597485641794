import React from 'react'
import styled from 'styled-components'
export default function GenderDeff() {
    return (
        <GenderWrap className="container">
            <h4>The Gender Difference:Men vs Women</h4>
            <p>According to a study released by the Resuscitation Science Symposium, men are more likely to receive bystander CPR in public locations compared to women.</p>
      
      <ul>

           <li>Women and men receive similar CPR assistance within the home, but in public 45 percent of men received assistance compared to only 39 percent of women</li>
           <li>Men were 1.23 times more likely to receive bystander CPR in public, and their chance of survival was 23 percent higher compared to women.</li>

      </ul>

      <p>So why the discrepancy? It could come down to anatomy and a bystander being comfortable enough to perform CPR on a woman</p>
        </GenderWrap>
    )
}


const GenderWrap = styled.div`

h4{

color:var(--brandColor);

}

p{

color:var(--acentColor);
font-size:1rem;
line-height:1.6;
font-weight:400;

}

ul {

li {


color:var(--acentColor);
font-size:1rem;
line-height:1.7;
font-weight:300;


}

}

`