import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { message } from 'antd';
import keyUri from '../../uri'

export const initialState = {

    loading:false,
    hasErrors:false,
    volunteer:[],
    currentVolunteer:null,
    isSuccess:false, total:0

}

export const volunteerSlice = createSlice({

    name:"volunteer",
    initialState,
    reducers:{

        getVolunteer:state =>{

            state.loading = true;

        },
        getVolunteerSuccess:(state, {payload}) =>{

            state.loading = false;
            state.volunteer = payload.volunteer;
            state.isSuccess = true
            state.total = payload.total
        },

        getCurrentVolunteer:(state, {payload}) =>{

            state.loading = false;
            state.currentVolunteer = payload
            
        },
        getSuccesOk: state =>{

            state.loading = false
            state.isSuccess = false
        },

        
        getvolunteerError:state =>{

            state.loading = false;
            
        }
    }
})

export const {getVolunteer, getVolunteerSuccess, getCurrentVolunteer, getSuccesOk, getvolunteerError} = volunteerSlice.actions;

export const volunteerSelector = state => state.volunteer

export default volunteerSlice.reducer


const config = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
  };


  export const fetchVolunteer = (skip=0, limit=10, searchword='') => async dispatch =>{

    dispatch(getVolunteer())


try {

    const {data} = await axios.get(keyUri + `/api/volunteer?skip=${skip}&limit=${limit}&search=${searchword}`);
 

    data && dispatch(getVolunteerSuccess(data))

} catch (error) {
    
    dispatch(getvolunteerError())


}


}


export const VolunteerSubmit = (values) => async dispatch =>{

    dispatch(getVolunteer())


try {

    const { data } = await axios.post(keyUri +'/api/volunteer', values, config);
 

    data && dispatch(getVolunteerSuccess(data))

} catch (error) {
    
    dispatch(getvolunteerError())


}


}

export const  isSuccessOK = () =>async dispatch =>{

    dispatch(getSuccesOk())
}



export const deleteVolunteer = (id) => async dispatch =>{

    const key = 'delete';

    dispatch(getVolunteer());
    message.loading({ content: 'Deleting...', key })
    try {
        
             const {data} = await axios.delete(keyUri +`/api/volunteer/${id}`)
        
             dispatch(fetchVolunteer())

           data && message.success({ content: data.msg, key, duration: 2 });
           

    } catch (error) {

        dispatch(getvolunteerError());
    }
}


// export const updateJournal = (id, values) => async dispatch =>{
//   console.log(id)
//     const key = 'update';

//     dispatch(getJournal());
//     message.loading({ content: 'updating...', key })
//     try {
        
//              const {data} = await axios.put(`/api/journal/${id}`, values, config)
//          dispatch(getUpdate()) 
           
//          data &&  message.success({ content: data.msg, key, duration: 2 });
           

//     } catch (error) {

//         dispatch(getJournal());
//     }


// }

  