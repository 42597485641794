import React from 'react'
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom'
import styled from 'styled-components'
export default function about() {
    return (
        <Fade bottom duration={300} distance={"50px"}>
        <div style={{width:"600px"}} className="dropdown-menu" aria-labelledby="navbarDropdown">
    
    <div className="row no-gutter" style={{position:"relative"}}>
        <div className="col-sm-6">
        <ul className="text-dark text-capitalize">
        <li className="nav-item ">
        <Link className="nav-link" to="/covid19-complition-comorbidities">Covid19:Complition & Comorbidities </Link>
      </li>
      <li className="nav-item ">
        <a className="nav-link" href="/heart-disease ">Heart Disease </a>
      </li>
     <li className="nav-item ">
        <Link className="nav-link" to="/the-twinepidemic">The Twinepidemic </Link>
      </li>
      <li className="nav-item ">
        <Link className="nav-link" to="/kidney-disease">Kidney Disease</Link>
      </li>
      <li className="nav-item ">
        <Link className="nav-link" to="/recipes-for-a-healthy-life">Recipes for a Healthy Life </Link>
      </li>
    
            </ul>
        </div>
        <div className="col-sm-6">
    
        <ul className="text-dark text-capitalize">
        <li className="nav-item ">
        <Link className="nav-link" to="/free-community-health-screenings">Free Community Health Screenings </Link>
      </li>
      {/* <li className="nav-item ">
        <Link className="nav-link" to="/prepare-for-your-visit-with-the-doctor">Prepare for your Visit with the Doctor </Link>
      </li> */}
     <li className="nav-item ">
        <Link className="nav-link" to="/cpr-training">CPR Training </Link>
      </li>
      {/* <li className="nav-item ">
        <Link className="nav-link" to="/patient-advisory-council">Patient Advisory Council</Link>
      </li> */}
   
            </ul>
        </div>
    
    </div>
    
    
        </div>
        </Fade>
    )
}


const  AboutMenuWrap = styled.div`




`