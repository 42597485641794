import React from 'react'
import styled from 'styled-components'

export default function AcPanel() {

let data = [
{

    text:`blood glucose is too high`
},
{

    text:`blood pressure is too high African Americans, American Indians, and Hispanics/Latinos develop diabetes, kidney disease, and kidney failure at a higher rate than Caucasians.
    You are also more likely to develop kidney disease if you have diabetes and`
},
{

    text:`smoke `
},
{

    text:`don’t follow your diabetes eating plan`
},
{

    text:`eat foods high in salt`
},
{

    text:`are not active`
},
{

    text:`have heart disease`
},
{

    text:`have a family history of kidney failure`
}]

    return (
        <ACWrap className="ml-sm-5 ml-1">
            <p>
            Having diabetes for a longer time increases the chances that you will have kidney damage. If you have diabetes, you are more likely to develop kidney disease if your
            </p>
            <div>
               {

                   data.map((item, i)=>{
            

                    return <ul>

                       <li className="ml-sm-3 ml-0" >{item.text}</li>
                    
                    </ul>



                   })
               }

            </div>
        </ACWrap>
    )
}


const ACWrap = styled.div`



`