import React from 'react'
import Header from '../../Shared/staticlayout/header'
import himg from '../../../images/bannerimages/young-business-man-pumps-chest-first-aid-emergency-cpr.jpg'
import Intro from './intro'
import Desc from './desc'
import BorderSec from './borderDesc'
import CprPerform from './cprPerform'
import GenderDeff from './genderDeff'
import HvC from './HvC'
import TheScience from './theScience'
export default function Cpr() {

      


    return (
        <div >
            <Header headerImg={himg} title="CPR TRAINING"/>
            <div className="container mb-5">
        <Intro/> 
<Desc/>
<BorderSec/>
<CprPerform/>
{/* <GenderDeff/> */}
<HvC/>
<TheScience/>
</div>
        </div>
    )
}
