import React from 'react'
import imge1 from '../../../images/CHS/TE (1).jpg'
import imge2 from '../../../images/CHS/TE (2).jpg'
import imge3 from '../../../images/CHS/TE (3).jpg'
import imge4 from '../../../images/CHS/TE (4).jpg'
import imge5 from '../../../images/CHS/TE (5).jpg'


import imge6 from '../../../images/CHS/TE (6).jpg'

import { Row, Col,  Button  } from 'antd';
// import Interweave from 'interweave'
import styled from 'styled-components'
// import limitDesc from '../../Shared/limitDes'
import { Card } from 'antd';

import { Link } from 'react-router-dom'


const { Meta } = Card;

export default function CommunityCard() {

    let commData = [
        {
            title:`The Himalayan Cardiorenal Screening Project
            `,
            location:`Leh, Ladakh, Kashmir, India
            `,
            year:`2013`,
            link:`#`,
            imge:imge4
        },
        {
            title:` Screening Project
            `,
            location:`Jaipur, India`,
            year:`2016 January
            `,
            link:`#`,
            imge:imge2
        },
        {
            title:` Collaboration with Project C.U.R.E. `,
            location:`GHANA, Africa·       
            `,
            year:` 2016 November
            `,
            link:`#`,
            imge:imge3
        },
        {
            title:` Screening Project in
            `,
            location:`Jaipur, India headed by Dr. Deedwania·  
            `,
            year:`Jan 2017
            `,
            link:`#`,
            imge:imge1
        },
        {
            title:`Ladakh Medical Project
            `,
            location:`Ladakh India`,
            year:`2017`,
            link:`#`,
            imge:imge5
        },
        
        {
            title:` Health Wellness Program 8-10-19

            `,
            location:`USA`,
            year:`2019`,
            link:`#`,
            imge:imge6
        },
    ]



    return (
        <CommWrap className="container mb-5">
            <Row gutter={20}>

                {


commData.map((item, i)=>{

return <Col key={i} xl={6} sm={24} className="my-3 px-0 shadow-sm mx-3">

<Card
   
    style={{ width: "100%", border:"none" }}
    cover={<img alt="example"  src={item.imge} />}
  >
      <h6 style={{lineHeight:"1.7"}} className="text-secondary">{item.title}</h6>
    <Meta  description={item.location} />
  </Card>

</Col>

})

                }
            </Row>
        </CommWrap>
    )
}


const CommWrap = styled.div`

h2{

text-transform:capitalize;
    font-family:var(--HeadingFont);
    line-height:1.7;

}

img{

    height:100%;
    border-radius:7px;

}

p{

    color:#000000;
}

`