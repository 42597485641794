import React from 'react'
import Header from '../../Shared/staticlayout/header'
import imge from '../../../images/bannerimages/doctor-man-consulting-patient-while-filling-up-application-form-desk-hospital (1).jpg'


export default function DoctorConsult() {
    return (
        <div>
         <Header headerImg={imge} title="DOCTOR CONSULTING"/>  
         <h2 className="text-center text-danger my-5">Coming Soon !</h2>

        </div>
    )
}
