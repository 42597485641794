import React from 'react'
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom'
import styled from 'styled-components'
export default function about() {
    return (
        <Fade bottom duration={300} distance={"50px"}>
        <div  className="dropdown-menu" aria-labelledby="navbarDropdown">
    
    <div className="row" style={{position:"relative"}}>
        <div className="col">
        <ul className="text-dark text-capitalize">
        <li className="nav-item ">
        <Link className="nav-link" to="/history">History </Link>
      </li>
      <li className="nav-item ">
        <Link className="nav-link" to="/news">News & Events </Link>
      </li>
     <li className="nav-item ">
        <Link className="nav-link" to="/board-of-directors">Board of Directors </Link>
      </li>
      {/* <li className="nav-item">
        <Link className="nav-link" to="/advisory-board">Advisory board</Link>
      </li>
      <li className="nav-item ">
        <Link className="nav-link" to="/staff">staff </Link>
      </li>
    
      <li className="nav-item ">
        <Link className="nav-link" to="/partners">partners </Link>
      </li> */}
            </ul>
        </div>
    
    
    </div>
    
    
        </div>
        </Fade>
    )
}


const  AboutMenuWrap = styled.div`




`