import React from 'react'
import {Row, Col} from 'antd'
import img1 from '../../../../images/colobraters/CR.png'
import img2 from '../../../../images/colobraters/mendedhearts-03.png'
import img3 from '../../../../images/colobraters/NLA.png'
import img4 from '../../../../images/colobraters/unnamed.png'


export default function OurColobration() {


    let ourColobration = [{

      
        link:"https://www.cardiorenalsociety.org/",
        imge:img1
    },
    {
    
     
        link:" https://mendedhearts.org/",
        imge:img2
    },
    {
    
  
        link:"https://www.lipid.org/",
        imge:img3
    },
    ,
    {
    
        link:"https://projectcure.org/",
        imge:img4
    }]


    return (
        <div className="my-3">
              <h3 className="text-center my-4 " style={{color:"var(--acentColor)"}}>Collaborating organizations</h3>
<Row>

    {
        ourColobration.map((item, i)=>{

           return <Col span={6} key={i}>
               <a target="_tab" href={item.link}>
<img   className="d-block mx-auto" src={item.imge} alt="dfs" width="50%"/>
</a>
           </Col>

        })
    }
</Row>
            
        </div>
    )
}
