import React, {useEffect} from 'react';
// import { Counter } from './components/counter/Counter';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AppWrap from './components/global'
import Home from './components/Clientview/home'
import News from './components/Clientview/news_events'
import Library from './components/Clientview/librarys'
import CurrentEvent from './components/Clientview/donate'
import Blogs from './components/Clientview/blogs'
import CurrentBlogs from './components/Clientview/blogs/currentBlog'
import Experts from './components/Clientview/AskExperts'
import Books from './components/Clientview/OurBooks'
import Daily from './components/Clientview/dailyBreifing'
import Reaearch from './components/Clientview/research'
import Voluteer from './components/Clientview/volunteer'
import Preregistration from './components/Clientview/preRegistartion'

import {ToastContainer} from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import Dashboard from './components/dashboard'
import { useSelector } from 'react-redux'
import {layoutSelector} from './components/Api/layoutSlice'
import Login from './components/Auth/login'
import PrivateRoute from './components/Auth/privateRoute'
import Story from './components/Clientview/Story';
import About from './components/Clientview/About/boardDirectors'
import Events from './components/Clientview/events'
import HeartDisease from './components/Clientview/HeartDisease'
import KidneyDisease from './components/Clientview/KidneyDisease'
import TheTwinEpidemic from './components/Clientview/Diabites';
import History from './components/Clientview/About/history';
import Donate from './components/Clientview/sap_danate';
import Medical from './components/Clientview/news_events';
import Community from './components/Clientview/community'
import CPR from './components/Clientview/cprTraining';
import Covid from './components/Clientview/covid19';
import Healthy from './components/Clientview/healthyRecipe';
import FreeComm from './components/Clientview/freeComm';
import DoctorConsult from './components/Clientview/DoctorConsulting';
import Moredetails from './components/Clientview/moredetails/index'
import './App.less';

function App() {


  const { isPrivate } = useSelector(layoutSelector)


  return (
    <Router>
    <main className="App">
<PrivateRoute path="/admin" component={Dashboard} />


{!isPrivate && <AppWrap>
 

 <Route path="/" component={Home} exact/>
 <Route path="/news" component={News} exact/>
 <Route path="/library" component={Library} exact/>
 <Route path="/events/:id" component={CurrentEvent} exact/>
 <Route path="/blogs" component={Blogs} exact/>
 <Route path="/blogs/:id" component={CurrentBlogs} exact/>
 <Route path="/ask-an-expert" component={Experts} exact/>

 <Route path="/our-books" component={Books} exact/>
 <Route path="/daily-briefing" component={Daily} exact/>
 <Route path="/research" component={Reaearch} exact/>

 <Route path="/volunteer" component={Voluteer} exact/>
 <Route path="/pre-registration" component={Preregistration} exact/>
 <Route path="/share-your-story" component={Story} exact/>
 <Route path="/board-of-directors" component={About} exact/>
 <Route path="/special-events" component={Events} exact/>
 <Route path="/heart-disease" component={HeartDisease} exact/>
 <Route path="/kidney-disease" component={KidneyDisease} exact/>
 <Route path="/the-twinepidemic" component={TheTwinEpidemic} exact/>
 <Route path="/history" component={History} exact/>
 <Route path="/donate" component={Donate} exact/>
 <Route path="/cpr-training" component={CPR} exact/>
 <Route path="/medical-conference" component={Medical} exact/>
 <Route path="/community-health-screening" component={Community} exact/>
 <Route path="/covid19-complition-comorbidities" component={Covid} exact/>
 <Route path="/recipes-for-a-healthy-life" component={Healthy} exact/>
 <Route path="/free-community-health-screenings" component={Community} exact/>
 <Route path="/prepare-for-your-visit-with-the-doctor" component={DoctorConsult} exact/>
 <Route path="/more-details" component={Moredetails} exact/>

<Route path="/login" component={Login} exact/>

       
    </AppWrap>}

 

       <ToastContainer
position="top-left"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
    </main>
  
    </Router> 
  
  );
}

export default App;
