import React, {useState, useEffect} from 'react'
import { Layout, Affix } from 'antd';
import styled from 'styled-components'
import SideBar from './sidebar'
import Header from './header'
import {  Route, Switch, useRouteMatch } from 'react-router-dom';
import Blogs from './pages/blogs'
import Events from './pages/events'
import EventCreate from './pages/events/createEvent'
import EventUpdate from './pages/events/editEvent'

import News from './pages/news'
import Home from './pages/home'
import Expert from './pages/experts'
import Users from './pages/users'
import BlogCreate from './pages/blogs/createBlog'
import BlogUpdate from './pages/blogs/editBlog'
import {useDispatch, useSelector} from 'react-redux'
import NewsCreate from './pages/news/createNews'
import NewsUpdate from './pages/news/editeNews'
import {isPrivateP} from '../Api/layoutSlice'
import {authenticateSelector} from '../Api/authSlice'
import UsersCreate from './pages/users/createUser'
import UsersUpdate from './pages/users/editUser'
import DailyBreifing from './pages/dailyBreifing'
import DailyBreifingCreate from './pages/dailyBreifing/createDYC'
import DailyBriefingUpdate from './pages/dailyBreifing/editDYB'
import Research from './pages/research'
import CreateResearch from './pages/research/createResearch'
import ResearchUpdate from './pages/research/editResearch'
import Journal from './pages/journal'
import CreateJournal from './pages/journal/createJournal'
import JournalUpdate from './pages/journal/editJournal'
import Volunteer from './pages/volunteer'
import Story from './pages/story'
import StoryUpdate from './pages/story/editStory'
import Donor from './pages/donor'


const {  Content } = Layout;


export default function Admin({history, location}) {

  let { path } = useRouteMatch();

    const [collapsed, setCollapsed] = useState(false)
    const [theme, setTheme] = useState(true)


const dispatch = useDispatch()
  const {isAuthenticate} = useSelector(authenticateSelector)
  
useEffect(()=>{

dispatch(isPrivateP())


if (!isAuthenticate) {
  history.push('/login');
}
else{

  history.push(location.pathname);
}
  
}, [dispatch, isAuthenticate, history ])

    const  toggle = () => {

        setCollapsed(!collapsed)

      
      };

      const changeTheme = () =>{

        setTheme(!theme)
    
    }

    return (
        <AdminWrap color={theme}>
        <Layout>
        <Affix offsetTop={0} onChange={affixed => console.log(affixed)}>
     <SideBar collapsed={collapsed} color={theme}  click={changeTheme}/>
     </Affix>

        <Layout className="site-layout">
        
        <Header click={toggle} collapsed={collapsed}/>
          <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
        
        <Switch>

<Route  exact path={`${path}/`}  component={Home} />
<Route  exact path={`${path}/blogs`}  component={Blogs} />
<Route  exact path={`${path}/blog/create`}  component={BlogCreate} />
<Route  exact path={`${path}/news/create`}  component={NewsCreate} />
<Route  exact path={`${path}/blog/update/:id`}  component={BlogUpdate} />
{/* <Route  exact path={`${path}/event/update/:id`}  component={EventCreate} /> */}
<Route  exact path={`${path}/event/create`}  component={EventCreate} />
<Route  exact path={`${path}/event/update/:id`}  component={EventUpdate} />

<Route  exact path={`${path}/events`}  component={Events} />
<Route  exact path={`${path}/news`}  component={News} />
<Route  exact path={`${path}/news/update/:id`}  component={NewsUpdate} />

<Route  exact path={`${path}/experts`}  component={Expert} />
<Route  exact path={`${path}/users`}  component={Users} />
<Route  exact path={`${path}/user/create`}  component={UsersCreate} />
<Route  exact path={`${path}/user/update/:id`}  component={UsersUpdate} />


<Route  exact path={`${path}/daily-breifing`}  component={DailyBreifing} />
<Route  exact path={`${path}/daily-breifing/create`}  component={DailyBreifingCreate} />
<Route  exact path={`${path}/daily-breifing/update/:id`}  component={DailyBriefingUpdate} />

<Route  exact path={`${path}/research`}  component={Research} />
<Route  exact path={`${path}/research/create`}  component={CreateResearch} />
<Route  exact path={`${path}/research/update/:id`}  component={ResearchUpdate} />

<Route  exact path={`${path}/journal`}  component={Journal} />
<Route  exact path={`${path}/journal/create`}  component={CreateJournal} />
<Route  exact path={`${path}/journal/update/:id`}  component={JournalUpdate} />

<Route  exact path={`${path}/volunteer`}  component={Volunteer} />
<Route  exact path={`${path}/share-your-story`}  component={Story} />
<Route  exact path={`${path}/share-your-story/update/:id`}  component={StoryUpdate} />

<Route  exact path={`${path}/donor`}  component={Donor} />

</Switch>
          </Content>
        </Layout>
      </Layout>
      </AdminWrap>
    )
}


const AdminWrap = styled.div`


.ant-layout {

.ant-layout-sider{
    height: 100%;
    overflow-y: auto;

    background:${props => props.color ? "#001529" : "#ffffff"};
}
}



.logo {
height: 32px;
background: rgba(255, 255, 255, 0.2);
margin: 16px;
}



#components-layout-demo-custom-trigger .logo {
height: 32px;
background: rgba(255, 255, 255, 0.2);
margin: 16px;
}

.site-layout .site-layout-background {
background: #fff;
}

`