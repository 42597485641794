import React from 'react'
import Header from '../../Shared/staticlayout/header'
import headerImage from '../../../images/bannerimages/eco-concept-with-group-volunteers (1).jpg'
import Form from './form'

export default function Volunteer() {
    return (
        <div >
            <Header headerImg={headerImage} title="PRE REGISTRATION"/>
            <Form/>
        </div>
    )
}
