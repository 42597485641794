import React from 'react'
import Intro from '../../../Shared/staticlayout/introduction'
import imge from '../../../../images/readImage.jpg'
import Header from '../../../Shared/staticlayout/header'
import historyImage from '../../../../images/cHSCR/IMG_1293.jpg'
import styled from 'styled-components'
export default function History() {

  
    let introData = {

        introtitle:"Twinepidemic Story",
        text:"It was a hot summer in 2008. In August that year, a thought had occurred to do something impactful for the community. This led to a few meetings with my CPA and she recommended an Attorney. One thing transitioned to anoth-er and soon with a Mission and Vision , a few goals were penned down. An application sent to the IRS that year was well worth it. It took about 10 months before IRS approved a 501 (c ) (3) status on August 20, 2009. ",
        imge: historyImage
    
    }

    return (
        <HistoryWrap >
            <Header title="ABOUT US"
            
            headerImg={historyImage}/>
<div  style={{transform:"translateY(-2rem)"}}>
           <Intro  data = {introData}/> 

           <div className="container ">

           <p style={{color:"var(--acentColor)", lineHeight:"1.7", fontSize:"1rem"}}>

In January 1977, my father had just come back home
 from Harkisandas Hospital n Bombay, India, 
  after being discharged from congestive heart
   failure.
    He was treated by one of the finest cardiologist at the time,
     Dr. J. K Mehta. I left without and concerns to get back to my
      medical school which was about a 1000 miles away.
       My final exam was coming up in May and a week prior 
       to my exam I had a hunch that my father was not well 
       and had fallen through the cracks. He has passed away
        a week prior to my final exam. As I walked in the house my
         brothers were looking at me with grief and I broke down in tears.
          My father had been ill so many times over the last 4 years of his
           life that every time he would be hospitalized, there was a fear
            that he may not come back alive., So it was not a major surprise when he died, but , for sure it was
             tough to handle his absence.<br/><br/>This thought was haunting me for a long time and had some frequent visual
             episodes at a subconscious level to start at an organizational level, programs and projects, to educate
             nd reduce the disease burden. With much thought and action, a few of us got together to make this
              nonprofit organization a reality. The First Task Force on Twin Epidemics of Diabetes and Heart 
              disease in South Asians was convened on June 2010 to address the comorbid condition of diabetes
               and heart disease and examine the growing body of evidence from diverse sources 
                   <br/><br/>

                  <b style={{color:"var(--brandColor)"}}><i>The task force was formed to include  several
                   endocrinologists, cardiologists and diabetologists nephrologists and epidemiologists from South 
                   Asian countries, UK, Australia  and United States .</i></b><br/><br/>
               
               They were selected
                based on their expertise in this combined field. Several meetings were conducted along with the
                 prominent meetings in multiple countries at their annual conventions. A major need was felt to
                  publish a State of The Science Consensus paper to focus on certain key questions and increase 
                  awareness regarding the epidemic of heart disease, diabetes and its complications and advocate 
                  early interventions at multiple levels.<br/>
              
                        
                        The rationale for this movement was to continue the
                         momentum gained from the awareness of the disease state and create an opportunity  to  develop 
                          educational  materials  that  would    articulate  a  consistent  and  reliable  message  to 
                           be  disseminated  in  multiple urban and rural areas to the healthcare community 
                           and non-medical communities across the World.<br/><br/><br/>


           </p>
           </div>
           </div>
        </HistoryWrap>
    )
}


const HistoryWrap = styled.div`




`