import React from 'react'
import styled from 'styled-components'
export default function BookDesc() {
    return (
        <DescWrap className="container   mb-5"
         style={{transform:"translateY(-80px)"}}>


            <p className="text-secondary px-5">
       
            Maya Angelou once wrote, “All great artists draw from the same resource:<b><i>the human heart,</i></b>  which tells us that we are all more alike than we are unalike.” We have all heard the saying, <b><i>“Have a heart!”</i></b>  Indeed, the pivotal organ that governs life in every aspect from physical to spiritual and everything in between is the heart.  <b>Yet how much do you truly know about it?</b> <br/>
            <br/>
            As you open the pages of this book, you will find it to be unlike the multitude of other books written about the very organ that beats for us day after day, moment after moment since conception. First, you will be captivated by the incredible heart of the man that wrote it. With great wisdom, Kris Vijay has crafted a book that showcases his knowledge of the inner workings of the heart. <b>Kris takes a holistic view of the functions of the heart… </b>including what makes the heart fail. He uses a variety of treatment options along with motivating, inspiring, and coaching to encourage patients to modify their lifestyles.
            <br/>
            <br/>
            His mission has been in the <b>prevention of risk factors of heart disease and diabetes</b> at primordial, primary, secondary, and tertiary care levels. Prevention of heart failure has been his main goal.
           
            “What impressed me most is the way he describes each chapter, with highlighted information to capture the attention of the readers. The very style of writing a medical book in such a simple language, with a unique approach of storytelling, makes this monograph extremely useful.
         
            Furthermore, including patient stories, explaining the underlying causes, and presenting management strategies makes this book a unique reference book for students, researchers, clinicians, and to some extent even to the patients who want to know more about their heart ailments.
           
            I have never had the experience of reviewing a medical monograph, which includes <b>emotions, passion, compassion, empathies</b> to illustrate a case in point. Just reading this monograph will make you feel that every doctor you see for your consultation, should have the qualities that Kris expresses in this work of art.”
<br/><br/>
         
            </p>
            
            <div className="mt-4 pb-4 text-center">
  <h4 className=" mb-0 " style={{color:'var(--acentColor)'}}>Gundu H.R. Rao</h4>
  <small style={{color:"var(--brandColor)", fontWeight:"500"}}>Lillehei Heart Institute, University of Minnesota</small>
   </div>

        </DescWrap>
    )
}


const DescWrap = styled.div`

p{

font-size:1rem;
line-height:1.8;
text-align:center;

}


`